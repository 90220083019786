import { actions } from './reducer/actions';

const AuthServices = (dispatch) => {
  function getToken(login, password, httpClient) {
    if (window.location.hostname === "goskluch.pro") {
      httpClient
        .get(`/admin/token?username=${login}&password=${password}`)
        .then(response => {
          localStorage.setItem("token", response.data);
          dispatch({
            type: actions.token,
            payload: response.data
          })
          window.location.href = "/demo";
        })
    }
    else {
      httpClient
        .get(`/auth/token?username=${login}&password=${password}`)
        .then(response => {
          localStorage.setItem("token", response.data.token);
          dispatch({
            type: actions.token,
            payload: response.data.token
          })
          window.location.href = "/request/list"
        })
    }
  }

  dispatch({
    type: actions.getToken,
    payload: getToken
  })
}

export default AuthServices;
