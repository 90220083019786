import SmallCard from "../SmallCard/SmallCard"
import pdfIcon from "../../icons/PdfIcon.svg"
import documentStatuses from "../../Services/Document/documentStatuses.json"
import Badge from "../Badge/Badge"
import signatureTypes from "./signatureTypes"


export default function DocumentCard({ document, isDisabled, removeDocument,onClick }) {
    const title = document.FileName ? document.FileName : document.name
    const subtitle = document.Type ? `Тип документа: ${document.Type.name}` : `${(document.File.size / 1024 / 1024).toFixed(1)} мб`
    const status = documentStatuses.find(t => t.code === document.Status?.code);
    const signatureType = signatureTypes[0];
    return (<SmallCard
        badge={status ? <Badge margin text={`• ${status?.text} (${signatureType.name})`} type={status?.style} ></Badge> : ""}
        title={title}
        subtitle={subtitle}
        avatar={pdfIcon}
        onClick={onClick}
        // signature={
        //     <Tooltip title={`Подписано: ${signatureType.name} (Нефёдов Сергей Викторович)`}>
        //         <img className={styles.signatureIcon} src={signatureType.icon}/>
        //     </Tooltip>
        // }
        // archive={
        //     <Tooltip title="Скачать архив">
        //         <img src={downloadIcon} className={styles.archiveIcon}></img>
        //     </Tooltip>
        // }
        onRemove={isDisabled ? null : () => removeDocument(document.Id)} >
    </SmallCard >)
}