import React from "react";
import InnerCell from "./InnerCell";

import styles from "./table.module.css";

export default function TableCell({ th, title, subtitle, badge }) {
  return (
    <React.Fragment>
      {th
        ?
        <th className={styles.th}>
          {th}
        </th>
        :
        <InnerCell>
          {title && <span className={styles.title}>{title}</span>}
          {badge && badge}
          {subtitle && <span className={styles.subtitle}>{subtitle}</span>}
        </InnerCell>
      }
    </React.Fragment>
  )
}