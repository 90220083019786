import { useEffect, useState } from 'react';
import { Skeleton } from "@mui/material";

import SmallCard from "../../../Components/SmallCard/SmallCard";
import { useUlService } from '../../../Services/UlSubjectService';

import avatar from "../../../images/Avatar_2.svg";
import styles from "./customers.list.module.css";
import UlSubjectCard from '../../../Components/UlSubjectCard/UlSubjectCard';

export default function UlSubjectList({ onClick, customers }) {


  return (
    <div className={styles.container}>
      {customers.length === 0 &&
        [...Array(10)].map((e) =>
          <Skeleton animation="wave" height={60} />
        )
      }
      {customers.map((x) =>   
      <UlSubjectCard ulSubject={x} onClick={()=>onClick(x)}>

      </UlSubjectCard>
        // <SmallCard
        //   onClick={() => onClick(x)}
        //   key={x.id}
        //   title={x.Name}
        //   subtitle={x.Agents.filter(x => x.IsMain).length > 0 ? `Представитель: ${x.Agents.filter(x => x.IsMain)[0]?.FioGenitive}` :`ИНН: ${x.Inn}`}
        //   avatar={avatar}
        // />
      )}
    </div>
  )
}