import React, { useRef } from 'react';

import uploadIcon from "../../icons/upload-icon.svg";
import styles from "./file.input.module.css";

export default function FileInput({ onClick, value, accept, onChange, multiple, label,isDisabled, clearValue }) {
  const types = accept.map(obj => obj.type).join(',');
  const names = accept.map(obj => obj.title).join(', ');

  const fileInputRef = useRef(null);

  const handleUpload = () => {
    fileInputRef.current.value = '';
  };

  return (
    <React.Fragment>
      {label && <label className={styles.label}>{label}</label>}
      <div className={styles.container} onClick={onClick}>
        {!isDisabled && <input
          multiple={multiple ? true : false}
          accept={types}
          ref={fileInputRef}
          value={value}
          type="file"
          className={styles.input}
          onChange={(e) => {
            onChange(multiple ? e.target.files : e.target.files[0])
            if (clearValue) {
              handleUpload()
            }
          }}
        />
        }

        <div className={styles.icon}>
          <img src={uploadIcon}/>
        </div>
        <div className={styles.text_wrapper}>
          <span className={styles.title}>
            Нажмите, чтобы загрузить
            <span className={styles.subtitle}> или перетяните файлы в это поле</span>
          </span>
          <span className={`${styles.title} ${styles.subtitle}`}>{names}</span>
        </div>
      </div>
    </React.Fragment>
  )
}