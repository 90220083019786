import React, { useContext, useEffect, useState, useTransition } from "react";
import { Outlet, useNavigate, useOutletContext } from "react-router-dom";

import Button from "../../../Components/Button/Button";
import CardHeader from "../../../Components/Card/CardHeader";
import Input from "../../../Components/Input/Input";
import RequestFooter from "../../../Components/RequestFooter/RequestFooter";
import Select from "../../../Components/Select/Select";
import TextArea from "../../../Components/TextArea/TextArea";
import Modal from "../../../Components/Modal/Modal";
import SmallCard from "../../../Components/SmallCard/SmallCard";
import Check from "../../../Components/Check/Check";
import CustomerCreate from "../../Customers/Edit/CustomerEdit";
import EmptyBlock from "../../../Components/EmptyBlock/EmptyBlock";
import CardContainer from "../../../Components/Card/CardContainer";
import CardBody from "../../../Components/Card/CardBody";
import { HttpContext } from "../../../HttpContext";

import plus from "../../../icons/plus-violet.svg";
import avatar from "../../../images/Avatar_2.svg";
import avatarBuilding from "../../../images/Avatar_building.svg";
import checkEmpty from "../../../images/Check_Empty.svg";
import checkFilled from "../../../images/Check.svg";
import styles from "./request.create.module.css";
import useRequestContext from "../useRequestContext";

export default function EquityContract() {
  const [request, setRequest] = useRequestContext();
  const [ulSubjects, setUlSubjects] = useState([]);
  const [flSubjects, setFlSubjects] = useState([]);
  const [ulSubject, setUlSubject] = useState();
  const [flSubject, setFlSubject] = useState();
  const { httpClient } = useContext(HttpContext);
  const [isMemberModal, setMemberModal] = useState(false);
  const [isCompanyModal, setCompanyModal] = useState(false);

  const selectFlSubject = (id) => {
    const fl = flSubjects.find(x => x.Id == id)
    setFlSubject(fl)
  }

  const selectUlSubject = (id) => {
    const fl = ulSubjects.find(x => x.Id == id)
    setUlSubject(fl)
  }


  const getFlSubjects = async () => {
    await httpClient.get("/flsubject/all").then(res => setFlSubjects(res.data.Data));
  }

  const getUlSubjects = async () => {
    await httpClient.get("/ulsubject/all").then(res => setUlSubjects(res.data.Data));
  }

  useEffect(() => {
    getFlSubjects()
    getUlSubjects()
  }, [])

  const addFlParticipant = async (typeId) => {
    const body = {
      "FlSubjectId": flSubject.Id,
      "Privileged": false,
      "RequestId": request.Id,
      "TypeId": typeId,
      "Numerator": 0,
      "Denominator": 1,
      "Note": "string"
    }
    await httpClient.post("/request/participant/add/flsubject", body);
  }

  const addUlParticipant = async (typeId) => {
    const body = {
      "FlSubjectId": flSubject.Id,
      "Privileged": false,
      "RequestId": request.Id,
      "TypeId": typeId,
      "Numerator": 0,
      "Denominator": 1,
      "Note": "string"
    }
    await httpClient.post("/request/participant/add/flsubject", body);
  }

  return (
    <React.Fragment>
      <CardContainer>
        <CardHeader title="Участники сделки (ДДУ)" />
        <CardBody>
          <div className={styles.membersBlock}>
            {request?.Participants?.filter(x => x.UlAgent != null).length === 0 &&
              <EmptyBlock title="Текущий застройщик (макс 1)" />
            }
            {request?.Participants?.filter(x => x.UlAgent != null).length > 0 &&
              <div className={styles.activeMembers}>
                {request?.Participants.filter(x => x.UlAgent != null).map((x) =>
                  <SmallCard
                    type="primary"
                    key={x.id}
                    title={x.UlAgent.name}
                    subtitle={`Тип документа: ${x.Document.Type.name}`}
                    avatar={avatar}
                  />
                )}
              </div>
            }
            {request?.Participants?.filter(x => x.FlSubject != null).length === 0 &&
              <EmptyBlock title="Текущий дольщик" />
            }
            {request?.Participants?.filter(x => x.FlSubject != null).length > 0 &&
              <div className={styles.activeMembers}>
                {request?.Participants.filter(x => x.FlSubject != null).map((x) =>
                  <SmallCard
                    type="primary"
                    key={x.id}
                    title={x.FlSubject.name}
                    subtitle={new Date(x.DateCreate).toLocaleDateString()}
                    avatar={avatar}
                  />
                )}
              </div>
            }
          </div>
          <div className={styles.add_buttons}>
            <Button
              width="100%"
              text="Добавить застройщика"
              onClick={() => setCompanyModal(true)}
              w100
              icon={plus}
              type="primary"
            />
            <Button
              width="100%"
              text="Добавить дольщика"
              onClick={() => setMemberModal(true)}
              w100
              icon={plus}
              type="primary"
            />
          </div>
        </CardBody>
      </CardContainer>
      <Modal
        onSave={() => addUlParticipant(2)}
        title="Добавление застройщика"
        isOpen={isCompanyModal}
        setOpen={setCompanyModal}
      >
        <Select
          label="Застройщик"
          placeholder="Не выбрано"
          value={ulSubject?.Id}
          onChange={(id) => selectUlSubject(id)}
        >
          {ulSubjects.map((x) =>
            <option value={x.Id}>{x.Name}</option>
          )}
        </Select>
      </Modal>
      <Modal
        onSave={() => addFlParticipant(1)}
        title="Добавление дольщика"
        isOpen={isMemberModal}
        setOpen={setMemberModal}
      >
        <Select
          label="Дольщик"
          placeholder="Не выбрано"
          value={flSubject?.Id}
          onChange={(id) => selectFlSubject(id)}
        >
          {flSubjects.map((x) =>
            <option value={x.Id}>{`${x.Lastname} ${x.Firstname} ${x.Middlename}`}</option>
          )}
        </Select>
      </Modal>
    </React.Fragment>
  )
}


