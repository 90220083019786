import { useContext, useEffect, useState } from 'react';
import { HttpContext } from "../HttpContext";

export function useCountryService() {
  const { httpClient } = useContext(HttpContext);
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    getCountries().then(response => setCountries(response))
  }, [])

  const getCountries = async () => {
    return httpClient.get("/country/all").then(promise => promise.data)
  }

  return {countries}
}
