import { useEffect, useState } from 'react';

import styles from "./customers.list.module.css";
import FlSubjectCard from '../../../Components/FlSubjectCard/FlSubjectCard';
import Input from '../../../Components/Input/Input';
import { useFlService } from '../../../Services/FlSubjectService';
import EmptyBlock from '../../../Components/EmptyBlock/EmptyBlock';

export default function FlSubjectList({ onClick,customers, isOpen }) {
  const {searchFlSubjectsAll} = useFlService();
  const [subjects, setSubjects] = useState();
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    if (searchValue?.length > 0) {
      searchFlSubjectsAll(searchValue).then((value) => {
        setSubjects(value?.Data);
      })
    } else {
      setSubjects(customers)
    }
  }, [searchValue])

  useEffect(() => {
    if (!isOpen) {
      setSearchValue('')
    }
  }, [isOpen])

  useEffect(() => {
    setSubjects(customers)
  }, [customers])

  return (
    <div className={styles.container}>
      <div className={styles.search}>
        <Input value={searchValue} onChange={(value) => setSearchValue(value)} placeholder='Поиск'/>
      </div>
      {subjects?.length === 0 &&
        <EmptyBlock title='Не найдено'/>
      }
      {subjects?.map((x) =>
      <FlSubjectCard onClick={()=>onClick(x)} flSubject={x}>
      </FlSubjectCard>
      )}
    </div>
  )
}