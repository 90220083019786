import { useEffect, useState } from "react";
import { IMaskInput } from "react-imask";
import { Calendar } from '../Icon/Icon';

import styles from "./input.module.css";
import { Skeleton } from '@mui/material';

export default function Input({ style, onChange, value, label, name, mask, options, placeholder, isError, error, subLabel, type, onSelect, disabled, isTextarea, max, skeleton }) {
  const [isOptions, setIsOptions] = useState(false)
  useEffect(()=>{
    setIsOptions(true)
  },[options])
  return (
      <div className={styles.container} style={style ? style : { display: "flex" }}>
        {label && (skeleton ? <Skeleton variant='rounded' width='100px' height='17px'/> : <label className={styles.label}>{label}</label>)}
        {skeleton ?
          <Skeleton variant='rounded' width='100%' height='40.5px'/>
          :
          ( isTextarea ? (
              <textarea
                disabled={disabled}
                name={name}
                className={`${styles.input} ${styles.textarea} ${isError ? styles.inputError : styles.okay}`}
                value={value}
                onChange={(e) => onChange(e.target.value)}
                placeholder={placeholder}
              />
            ) : (
              mask ? (
                <IMaskInput
                  disabled={disabled}
                  mask={mask}
                  name={name}
                  className={`${styles.input} ${isError && styles.inputError}`}
                  value={value}
                  type={type}
                  onAccept={(value) => onChange(value)}
                  placeholder={placeholder}
                />
              ) : (
                <>
                  <input
                    disabled={disabled}
                    name={name}
                    className={`${styles.input} ${isError ? styles.inputError : styles.okay} ${type === 'date' && !value && styles.placeholder}`}
                    value={value}
                    type={type}
                    max={max}
                    onChange={(e) => {
                      setIsOptions(true);
                      onChange(e.target.value);
                    }}
                    placeholder={placeholder}
                  />
                  {type === 'date' &&
                    <Calendar className={styles.calendar}/>
                  }
                </>
              )
            )
          )
        }
        {(isOptions && options &&
          <div className={styles.options}>
            {options.map((x) =>
              <span
                onClick={(e) => {
                  onSelect(x);
                  setIsOptions(false);
                  e.preventDefault()
                }}
              >
                {x.Note}
              </span>
            )}
            {options.length === 0 &&
              <span>Не найдено</span>
            }
          </div>
        )}
        {isError && error && <span className={styles.error_text}>{error}</span>}
        {subLabel && <label className={subLabel}>{subLabel}</label>}
      </div>
  )
}