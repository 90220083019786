import step1 from "../images/Step=1.svg"
import step2 from "../images/Step=2.svg"
import step3 from "../images/Step=3.svg"
import step4 from "../images/Step=4.svg"
const titles = [

    {
        "path": "/request/edit",
        "title": "Новое заявление",
        "subtitle": "Детали заявления",
        "option": "Заполните сведения по объекту недвижимости и собственникам, прикрепите все необходимые документы",
        "step": step1
    },
    {
        "path": "/request/edit/sales-contract",
        "title": "Новое заявление",
        "subtitle": "Детали заявления",
        "option": "Заполните сведения по объекту недвижимости и собственникам, прикрепите все необходимые документы",
        "step": step1
    },
    {
        "path": "/request/edit/equity",
        "title": "Новое заявление",
        "subtitle": "Детали заявления",
        "option": "Заполните сведения по объекту недвижимости и собственникам, прикрепите все необходимые документы",
        "step": step1
    },
    {
        "path": "/request/edit/property-registration",
        "title": "Новое заявление",
        "subtitle": "Детали заявления",
        "option": "Заполните сведения по объекту недвижимости и собственникам, прикрепите все необходимые документы",
        "step": step1
    },
    {
        "path": "/request/signature",
        "title": "Заявка #ID",
        "subtitle": "Подписание документов в заявке",
        "option": "Подпишите документы мобильной электронной подписью или с помощью сертификата на токене",
        "step": step2
    },
    {
        "path": "/request/rosreestr",
        "title": "Заявка #ID",
        "subtitle": "Заявка отправлена в Росреестр",
        "option": "Отслеживайте статус заявки",
        "step": step3
    },
    {
        "path": "/request/result",
        "title": "Заявка #ID",
        "subtitle": "Просмотрите результат заявки",
        "option": "Отслеживайте статус заявки",
        "step": step4
    },

]
export default titles;
export { step1, step2, step3, step4 };