import { useContext } from 'react';
import { HttpContext } from '../../HttpContext';

export function usePostNotificationsService() {
  const { httpClient } = useContext(HttpContext);

  const uploadNotification = async (body) => {
    return httpClient.post("/post-notifications/file-upload", body).then(promise => promise.data)
  }

  const updateNotification = async (mailId) => {
    return httpClient.get(`/post-notifications/notice-update/${mailId}`).then(promise => promise.data)
  }

  const checkNotification = async (mailId) => {
    return httpClient.get(`/post-notifications/notice-check/${mailId}`).then(promise => promise.data)
  }

  const sendFlOwner = async (body) => {
    return httpClient.post("/post-notifications/send-flowner", body).then(promise => promise.data)
  }

  const sendUlOwner = async (body) => {
    return httpClient.post("/post-notifications/send-ulowner", body).then(promise => promise.data)
  }

  const getLetterStatus = async (requestCode) => {
    return httpClient.get(`/post-notifications/letter-status/${requestCode}`, {
      hideLoader: true
    }).then(promise => promise.data)
  }

  const getLetterHistory = async (noticeId) => {
    return httpClient.get(`/post-notifications/letter-history/${noticeId}`, {
      hideLoader: true
    }).then(promise => promise.data)
  }

  const getNoticeFile = async (noticeId) => {
    return httpClient.get(`/post-notifications/notice-file/${noticeId}`, { responseType: 'blob' }).then(promise => promise.data)
  }

  const getAccessHistory = async (id) => {
    return httpClient.get(`/post-notifications/access-history/${id}`).then(promise => promise.data)
  }

  return {
    uploadNotification,
    sendFlOwner,
    sendUlOwner,
    getLetterStatus,
    getLetterHistory,
    getNoticeFile,
    getAccessHistory,
    updateNotification,
    checkNotification
  }
}
