import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from 'react-router-dom';

import Button from "../../../Components/Button/Button";
import CardHeader from "../../../Components/Card/CardHeader";
import Input from "../../../Components/Input/Input";
import Select from "../../../Components/Select/Select";
import { useEstateObjectService } from '../../../Services/EstateObject/EstateObject';
import Menu from '../../../Components/Menu/Menu';

import objectTypes from '../../../Services/Request/objectTypes.json'
import styles from "./object.edit.module.css";
import AddressInput from '../../../Components/Input/AddressInput';

export default function ObjectEdit() {
  const [object, setObject] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const {getEstateObject, createEstateObject, deleteEstateObject, updateEstateObject} = useEstateObjectService();
  const navigate = useNavigate();
  const { id } = useParams();

  const handleSave = () => {
    if (id) {
      updateEstateObject(object).then(() => {
        getObject(object?.Id)
      })
    } else {
      createEstateObject({ EstateObject: object }).then((data) => {
        setObject(data);
        navigate(`/object/edit/${data.Id}`)
      })
    }
  }

  useEffect(() => {
    if (id) {
      getObject(id)
    }
    setIsEdit(!!id)
  }, [id])

  async function getObject(id) {
    getEstateObject(id).then((object) => {
      setObject(object)
    })
  }

  return (
    <div className={styles.container}>
      <div className={styles.card}>
        <CardHeader title="Редактирование/Создание объекта" menu={id ?
          <Menu>
            {isEdit ?
              <Button large type="default" text="Изменить" onClick={() => setIsEdit(!isEdit)}/>
              :
              null
            }
            <Button onClick={() => {
              deleteEstateObject(id).then(() => navigate(`/pages/objects`))
            }} large type="error" text="Удалить" />
          </Menu>
          :
          null
        } />
        <div className={styles.card_body}>
          <label>Кадастровый номер</label>
          <div className={styles.buttons}>
            <div style={{width: id ? '100%' : '400px'}}>
              <Input
                disabled={isEdit}
                value={object?.CadNumber}
                placeholder="Введите номер для поиска"
                onChange={(value) => setObject({
                  ...object, CadNumber: value
                })}
                mask="00:00:000000:000000000"
              />
            </div>
            {!id ?
              <Button text="Поиск"/>
              :
              null
            }
          </div>
          <Select
            disabled={isEdit}
            value={object?.Type}
            onChange={(value) => setObject({
              ...object, Type: value
            })}
            label="Вид объекта"
          >
            {objectTypes?.map((item) =>
              <option key={item.code} value={item.code}>
                { item.name }
              </option>
            )}
          </Select>
          <AddressInput
            value={object?.Address}
            setValue={(value) => setObject({
              ...object, Address: value.Note
            })}
            disabled={isEdit}
            label="Адрес по ФИАС"
            error='Адрес не найден по ФИАС'
          />
          <Input
            value={object?.FullAddress}
            disabled={isEdit}
            label="Полный адрес (если не нашелся адрес по ФИАС)"
            onChange={(value) => setObject({
              ...object, FullAddress: value
            })}
          />
          <div className={styles.separated}>
            <Input
              disabled={isEdit}
              value={object?.Area}
              label="Площадь обьекта"
              onChange={(value) => setObject({
                ...object, Area: value
              })}
            />
            <Input
              disabled={isEdit}
              value={object?.Unit}
              label="Единицы измерения"
              onChange={(value) => setObject({
                ...object, Unit: value
              })}
            />
          </div>
          <Input
            disabled={isEdit}
            value={object?.Description}
            label="Описание"
            subLabel="Название ЖК, описание объекта"
            onChange={(value) => setObject({
              ...object, Description: value
            })}
          />
          {!isEdit ?
            <div className={styles.buttons}>
              <Button onClick={handleSave} text="Сохранить"/>
              {id ?
                <Button type="default" text="Отменить" onClick={() => {
                  getObject(id);
                  setIsEdit(!isEdit);
                }}/>
                :
                null
              }
            </div>
            :
            null
          }
        </div>
      </div>
    </div>
  )
}