import Button from "../Button/Button";
import CardHeader from "../Card/CardHeader";

import styles from "./modal.center.module.css";
import close from '../../icons/XCircle.svg';
import cn from 'classnames';

export default function ModalCenter({ isOpen, children, setIsOpen, closeButton, fullHeight }) {
  const closeModal = () => {
    setIsOpen(false);
  };

    return (
      <div className={styles.modal_wrapper} style={{ display: isOpen ? "flex" : "none" }}>
        <div className={cn(styles.modal, {[styles.fullHeight]: fullHeight})}>
          {closeButton && <img className={styles.close_button} onClick={closeModal} src={close}/>}
          {children}
        </div>
      </div>
    );
}