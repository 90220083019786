import React, { useContext, useEffect, useState } from 'react';
import cn from 'classnames';
import { Tooltip, GlobalStyles } from '@mui/material';
import { HttpContext } from '../../../../HttpContext';
import getStatus from '../../../../Services/PostNotifications/PostNotificationStatuses';
import styles from './homes.button.module.css';
import color from '../../../../styles/color.module.css';

const HomesButton = ({ text, type = 'gray', onClick, apartment, grabbing }) => {
  const [isSent, setIsSent] = useState(false);
  const [openTooltip, setOpenTooltip] = useState(false);
  const { state } = useContext(HttpContext);
  const { flSubjects } = state;

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const statusTexts = {
    3: "Записано на приемку",
    4: "Объект принят",
    5: "Объект с замечаниями",
    6: "Акт приема-передачи загружен",
    7: "Находится на регистрации",
    8: "Приостановлено",
    9: "Возвращено без рассмотрения",
    10: "Права зарегистрированы",
  };

  useEffect(() => {
    if (apartment?.Owners && apartment?.Owners.length > 0) {
      const hasSent = apartment?.Owners?.some(item =>
        item.State === "ONLINE_DELIVERED" ||
        item.State === "DELIVERED_TO_PRINT" ||
        item.State === "HANDED_EMAIL" ||
        item.State === "HANDED_MAIL"
      );
      setIsSent(hasSent);
    } else {
      setIsSent(false);
    }
  }, [apartment?.Owners]);

  return (
    <>
      <GlobalStyles
        styles={{
          '.MuiTooltip-tooltip': {
            whiteSpace: 'normal !important',
            maxWidth: 'none !important',
            minWidth: '300px !important',
          },
        }}
      />
      <Tooltip
        arrow
        title={
          apartment ? (
            apartment?.ApartmentStatus === 0 ? (
              apartment?.Description && (
                <label className={styles.description_text}>
                  Комментарий: {apartment?.Description}
                </label>
              )
            ) : (
              (apartment?.ApartmentStatus !== 2 || apartment?.Owners?.every(item => item.State !== null)) && (
                <div className={styles.wrapper}>
                  {apartment?.Owners.map((item) => (
                    <div className={styles.title} key={item.FlOwnerId}>
                      <label className={styles.title_text}>
                        {`${flSubjects?.find((x) => x.Id === Number(item.FlOwnerId))?.Lastname}
                  ${flSubjects?.find((x) => x.Id === Number(item.FlOwnerId))?.Firstname}
                  ${flSubjects?.find((x) => x.Id === Number(item.FlOwnerId))?.Middlename}`
                        }
                      </label>
                      {apartment?.ApartmentStatus === 2 && (
                        <>
                          <label className={styles.title_text}>Статус: {getStatus(item?.State).text}</label>
                          {item?.SentDate && (
                            <label className={styles.title_text}>Дата отправки: {formatDate(item?.SentDate)}</label>
                          )}
                        </>
                      )}
                    </div>
                  ))}
                  {statusTexts[apartment?.ApartmentStatus] && (
                    <div className={styles.title}>
                      <label className={styles.title_text}>
                        Статус: {statusTexts[apartment.ApartmentStatus]}
                      </label>
                    </div>
                  )}
                  {apartment?.Description && (
                    <label className={styles.description_text}>
                      Комментарий: {apartment?.Description}
                    </label>
                  )}
                </div>
              )
            )
          ) : null
        }
      className={cn(styles.container, {
          [color[type]]: type,
          [styles.yellow]: apartment?.ApartmentStatus === 2 && apartment?.Owners?.every(item => item.State !== null),
          [styles.red]: apartment?.ApartmentStatus === 3,
          [styles.info]: apartment?.ApartmentStatus === 4,
          [styles.danger]: apartment?.ApartmentStatus === 5,
          [styles.warning]: apartment?.ApartmentStatus === 6,
          [styles.blue]: apartment?.ApartmentStatus === 7,
          [styles.error]: apartment?.ApartmentStatus === 8,
          [styles.error]: apartment?.ApartmentStatus === 9,
          [styles.success]: apartment?.ApartmentStatus === 10,
          [styles.grabbing]: grabbing
        })}
        onClick={onClick}
        onMouseEnter={() => setOpenTooltip(true)}
        onMouseLeave={() => setOpenTooltip(false)}
        open={openTooltip}
      >
        {apartment?.Description &&
          <span className={styles.description}/>
        }
        {isSent && <span className={styles.comments} />}
        {text}
      </Tooltip>
    </>
  );
};

export default HomesButton;
