import styles from "./request.signature.module.css"

import Button from "../../../Components/Button/Button"
import goskey from "../../../images/Goskey.svg"
import CardHeader from "../../../Components/Card/CardHeader"
import RequestFooter from "../../../Components/RequestFooter/RequestFooter"
import SmallCard from "../../../Components/SmallCard/SmallCard"
import { useEffect, useState } from "react"
import React from "react"
import usb from "../../../icons/usb.svg"
import requestIcon from "../../../icons/requestIcon.svg"
import CardContainer from "../../../Components/Card/CardContainer"
import CardBody from "../../../Components/Card/CardBody"
import EmptyBlock from "../../../Components/EmptyBlock/EmptyBlock"
import useRequestContext from "../useRequestContext"
import Badge from "../../../Components/Badge/Badge"
import documentStatuses from "../../../Services/Document/documentStatuses.json"
import DocumentCard from "../../../Components/DocumentCard/DocumentCard"
import pdfIcon from '../../../icons/PdfIcon.svg';
import SignatureModalCenter from './SignatureModalCenter';

export default function RequestSignature() {
  const { request, deleteDocument, isDisabled, generateStatement, addSignature, sendStatement, setRequest, addDocumentParticipant, deleteDocumentParticipant, getRequest } = useRequestContext()
  const [isOpen, setOpen] = useState(false)
  const [documents, setDocuments] = useState([])
  const [participants, setParticipants] = useState()
  const [provider, setProvider] = useState("token")
  const [allDocumentsSigned, setAllDocumentsSigned] = useState(false);
  // const onUlRepresentingSave = () => {
  //     const newState = request.Participants.filter(x => x.isActive);
  //     newState.forEach((x) => {
  //         addDocumentParticipant(requestFile.Id, x.Id).then()
  //     })
  // }
  const [nextButton, setNextButton] = useState(<Button text="Статус Росреестра" href={`/request/rosreestr/${request?.Id}`}/>)

  useEffect(() => {
    switch (request?.Status?.code) {
      case 1:
        setNextButton(<Button text="Сформировать заявления" onClick={() => generateStatement(request?.Id).then(() => getRequest(request.Id))} />)
        break;
      case 2:
        setNextButton(<Button text="Отправить в Росреестр" isDisabled={!allDocumentsSigned} type="error" onClick={() => sendStatement(request?.Id).then(x => window.location.reload())} />)
        break;
      case 5:
        setNextButton(<Button text="Посмотреть результаты" href={`/request/rosreestr/${request?.Id}`}/>)
        break;

    }
  }, [request?.Status?.code, allDocumentsSigned])

  const removeDocument = (id) => {
    deleteDocument(id).then(x => window.location.reload())
  }

  useEffect(() => {
    const filteredDocuments = [
      ...(request?.Documents ? request.Documents.filter(document => document?.Category?.code !== '3') : []),
      ...(request?.Statements ? request.Statements.filter(statement => statement?.Category?.code !== '3') : [])
    ];

    const areAllSigned = filteredDocuments?.every(item =>
      item?.Signatures?.some(signature => signature?.Id)
    );

    setAllDocumentsSigned(areAllSigned);
  }, [request?.Documents, request?.Statements]);

  return (
    <div className={styles.container}>
      {request?.Participants.length === 0 &&
        <CardContainer>
          <CardHeader title="Документы"/>
          <CardBody>
            <EmptyBlock title="Документы на подпись отсуствуют"/>
          </CardBody>
        </CardContainer>
      }
      {request?.Participants?.map((member) =>
        <CardContainer key={member.Id} >
          {member?.UlAgent &&
            <CardHeader title={`${member?.UlAgent?.UlSubjectName} (${member?.UlAgent?.Lastname} ${member?.UlAgent?.Firstname} ${member?.UlAgent?.Middlename})`} label={member.type} />
          }
          {member?.FlSubject &&
            <CardHeader title={member?.FlSubject?.name} label={member.type} />
          }
          {member?.UlAgentFlSubject &&
            <CardHeader title={`${member?.UlAgentFlSubject?.Lastname} ${member?.UlAgentFlSubject?.Firstname} ${member?.UlAgentFlSubject?.Middlename}`} />
          }
          <CardBody>
            {member.Documents?.length > 0 &&
              <React.Fragment>
                {member.Documents.map((x) =>
                  <SmallCard
                    badge={<Badge margin text={x?.Signature ? '• Подписано' :  '• Требует подписания'} type={x?.Signature ? 'success' :  'error'}/>}
                    title={x?.FileName}
                    subtitle={`Тип документа: ${x.Type.name}`}
                    avatar={pdfIcon}
                    onRemove={isDisabled ? null : () => removeDocument(x?.Id)}
                  />
                )}
                {member.Documents.filter(x => x?.Status?.code === 1).length > 0 &&
                  <React.Fragment>
                    <span className={styles.subtitle}>Выберите сервис для подписания</span>
                    <div className={styles.personFooter} >
                      <Button
                        large
                        onClick={() => {
                          setProvider("token")
                          setOpen(true);
                          setDocuments(member.Documents.filter(x => x?.Status?.code == 1).map((x) => {
                            return { ...x, isActive: true }
                          }))
                          setParticipants(request?.Participants)
                        }}
                        text="Токен"
                        type="primary"
                        icon={usb}
                      />
                      <Button
                        large
                        onClick={() => {
                          setProvider("signme")
                          setOpen(true);
                          setDocuments(member.Documents.filter(x => x?.Status?.code == 1).map((x) => {
                            return { ...x, isActive: true }
                          }))
                          setParticipants(request?.Participants)
                        }}
                        text="SignMe"
                        type="primary"
                        icon={usb}
                      />
                    </div>
                  </React.Fragment>
                }
              </React.Fragment>
            }
            {member.Documents?.length === 0 &&
              <EmptyBlock title="Нет документов на подпись"/>
            }
          </CardBody>
        </CardContainer >
      )}
      {request?.Status?.code == 2 &&
        <CardContainer >
          <CardHeader title="Заявления"/>
          <CardBody>
            {request?.Statements.filter(x => x?.Category?.code == 2).map((x) =>
              <SmallCard avatar={requestIcon} title={x.FileName} subtitle={x?.DocType?.name}/>
            )}
            <Button
              large
              onClick={() => {
                setOpen(true);
                setDocuments(request?.Documents.filter(x => x?.Signature === null).map((x) => {
                  return { ...x, isActive: true }
                }))
                setParticipants(request?.Participants)
              }}
              text="Токен"
              type="primary"
              icon={usb}
              isDisabled={allDocumentsSigned}
            />
            {request?.Statements.find(x => x?.Category?.code == 2) == null &&
              <React.Fragment>
                <EmptyBlock title="Нет сформированных заявлений"/>
              </React.Fragment>
            }
          </CardBody>
        </CardContainer>
      }
      <SignatureModalCenter
        isOpen={isOpen}
        onClose={setOpen}
        documents={documents}
        participants={participants}
        setParticipants={setParticipants}
        addSignature={addSignature}
      />
      <RequestFooter
        back={<Button text="Назад" href={`/request/edit/${request?.Id}`}/>}
        next={nextButton}
      />
    </div >
  )
}