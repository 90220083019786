import React, { useEffect, useState } from "react";

import TableCell from "../../../Components/Table/TableCell";
import TableContainer from "../../../Components/Table/TableContainer";
import TableHeader from "../../../Components/Table/TableHeader";
import TableSkeleton from '../../../Components/Table/TableSkeleton';

import styles from "./object.list.module.css";
import { useEstateObjectService } from '../../../Services/EstateObject/EstateObject';
import { Pagination } from '@mui/material';
import TableFooter from '../../../Components/Table/TableFooter';
import EmptyBlock from '../../../Components/EmptyBlock/EmptyBlock';
import TooltipSmallText from '../../../Components/Tooltip/TooltipSmallText';
import { useNavigate } from 'react-router-dom';
import Input from '../../../Components/Input/Input';

export default function ObjectList() {
  const {getEstateObjects} = useEstateObjectService();

  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [objects, setObjects] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const navigate = useNavigate();

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();

    return `${day}.${month}.${year}`;
  }

  const handleChange = (e, number) => {
    setPage(number);
  };

  function getObjectType(type) {
    switch (type) {
      case 1:
        return {
          text: 'Жилое помещение',
        };
      case 2:
        return {
          text: 'Машиноместо',
        };
      case 3:
        return {
          text: 'Нежилое помещение',
        };
      default:
        return {
          text: 'Жилое помещение',
        };
    }
  }

  function getObjects() {
    setIsLoading(true)
    getEstateObjects(page, searchValue).then((request) => {
      setObjects(request)
      setIsLoading(false)
    })
  }

  useEffect(() => {
    getObjects()
  },[page, searchValue])

  return (
    <div className={styles.container}>
      <label>{ `Всего объектов: ${objects?.TotalCount ? objects?.TotalCount : 0}`}</label>
      <div className={styles.input}>
        <Input
          value={searchValue}
          label='Поиск по объектам'
          placeholder='Введите кадастровый номер или адрес'
          onChange={(value) => {
            setSearchValue(value);
            setPage(1);
          }}
        />
      </div>
      <TableContainer>
        <TableHeader>
          <TableCell th="Кадастровый номер"/>
          <TableCell th="Вид объекта"/>
          <TableCell th="Адрес"/>
          <TableCell th="Площадь" />
          <TableCell th="Описание" />
          <TableCell th="Дата создания" />
        </TableHeader>
        {isLoading ?
          <TableSkeleton row={6} col={10}/>
          :
          <tbody className={styles.tbody}>
          {objects?.Items?.map((x) =>
            <tr className={styles.tr} key={x.Id} onClick={() => navigate(`/object/edit/${x.Id}`)}>
              <TableCell title={x.CadNumber}/>
              <TableCell title={getObjectType(x.Type).text}/>
              <TableCell title={<TooltipSmallText text={x.Address} maxLength='30'/>}/>
              <TableCell title={x.Area}/>
              <TableCell title={x.Description}/>
              <TableCell title={formatDate(x.DateCreate)}/>
            </tr>
          )}
          </tbody>
        }
      </TableContainer>
      {objects?.Items?.length === 0 &&
        <EmptyBlock title='Не найдено'/>
      }
      <TableFooter>
        <Pagination
          count={Math.ceil(objects?.TotalCount / 10)}
          page={page}
          onChange={handleChange}
          variant="outlined"
          color="secondary"
        />
      </TableFooter>
    </div>
  )
}