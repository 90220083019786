export default function getStatus(status) {
  switch (status) {
    case 'NEW':
      return {
        text: 'Уведомление не направлялось',
        badgeType: 'info',
        status: status,
      };
    case 'PROCESS':
      return {
        text: 'Опись сформирована',
        badgeType: 'success',
        status: status,
      };
    case 'UNKNOWN':
      return {
        text: 'Статус запроса неизвестен',
        badgeType: 'warning',
        status: status,
      };
    case 'ACCEPTED':
      return {
        text: 'Запрос принят в обработку',
        badgeType: 'warning',
        status: status,
      };
    case 'IN_PROGRESS':
      return {
        text: 'В процессе обработки',
        badgeType: 'warning',
        status: status,
      };
    case 'ONLINE_DELIVERED':
      return {
        text: 'Доставлено в электронном виде',
        badgeType: 'success',
        status: status,
      };
    case 'DELIVERED_TO_PRINT':
      return {
        text: 'Направлено на печать в бумажном виде',
        badgeType: 'success',
        status: status,
      };
    case 'FAILED':
      return {
        text: 'Произошла ошибка',
        badgeType: 'error',
        status: status,
      };
    case 'HANDED_EMAIL':
      return {
        text: 'Вручено в электронном виде',
        badgeType: 'success',
        status: status,
      };
    case 'HANDED_MAIL':
      return {
        text: 'Вручено в бумажном виде',
        badgeType: 'success',
        status: status,
      };
    default:
      return {
        text: 'Уведомление не направлялось',
        badgeType: 'info',
        status: status,
      };
  }
}
