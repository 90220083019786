import { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import Navlink from '../../Components/Navlink/Navlink';
import links from "./links.js";
import { jwtDecode } from "jwt-decode";
import Badge from '../../Components/Badge/Badge.jsx';
import { useBalanceService } from '../../Services/Balance/BalanceService';

import logo from "../../images/logo.svg";
import styles from './navbar.module.css';
import exit from "../../icons/Exit.svg"
import wallet from "../../icons/Wallet.svg"
import handBag from "../../icons/HandbagSimple.svg"
import cornerDownRight from "../../icons/cornerDownRight.svg"
import { useCustomerService } from '../../Services/Cusomer/Customer';

export default function NavBar() {
  const decoded = jwtDecode(localStorage.getItem("token"));
  const {getBalance} = useBalanceService();
  const {getPayBalance} = useCustomerService();

  const location = useLocation();
  const [url, setUrl] = useState(location.pathname)
  const [balance, setBalance] = useState(0);
  const [balanceAutopay, setBalanceAutopay] = useState(0);

  useEffect(() => {
    getBalance().then(x => setBalance(x))
    getPayBalance().then(x => setBalanceAutopay(x.Value)).catch(() => null)
    setUrl(location.pathname)
  }, [location]);

  return (
    <div className={styles.container}>
      <div className={styles.navbar}>
        <img src={logo} width="230px" height="32px" style={{ marginBottom: 24 }} />

        {links.map((x, index) =>
          <Navlink key={index} href={x.path} active={url.includes(x.page)} text={x.title} icon={x.iconUrl} />
        )}
        {url.includes('object') &&
          <div>
            <Navlink activeSave={url.includes('save')} icon={cornerDownRight} href="/object/save" text='Сохраненные' />
          </div>
        }
        <div className={styles.customer}>
          <span>{decoded.customer_name}</span>
        </div>
        <div className={styles.links}>
          <Navlink text="Баланс" icon={handBag} href="/balance" active={url.includes('balance')} badge={<Badge type='success' text={`${balance} пакетов`}/>}/>
          <Navlink text='Баланс автооплаты' href="/autopay" icon={wallet} active={url.includes('autopay')}/>
          <div className={styles.autopay}>
            <span>{`${balanceAutopay / 100} р`}</span>
          </div>
          <Navlink text="Выход" href="/login" icon={exit}/>
        </div>
      </div>

      <div className={styles.outlet}>
        <Outlet />
      </div>
    </div>
  );
}
