import React from 'react';
import InnerCell from '../Table/InnerCell';
import { Skeleton } from "@mui/material";

export default function TableSkeleton({ col, row }) {
  return (
    <React.Fragment>
      {[...Array(col)].map((_, colIndex) => (
        <tr key={colIndex}>
          {[...Array(row)].map((_, rowIndex) => (
            <InnerCell key={rowIndex}>
              <Skeleton />
            </InnerCell>
          ))}
        </tr>
      ))}
    </React.Fragment>
  );
}
