import SmallCard from "../SmallCard/SmallCard";
import avatar from "../../images/Avatar_2.svg";

export default function FlSubjectCard({ flSubject,onRemove,onClick }) {
    const flAgentName = flSubject.FlAgent ? `(Представитель ${flSubject.FlAgent.Lastname} ${flSubject.FlAgent.Firstname} ${flSubject.FlAgent.Middlename})` :"";
    const flSubjectName = flSubject?.FlSubject ? `${flSubject.FlSubject.Lastname} ${flSubject.FlSubject.Firstname} ${flSubject.FlSubject.Middlename}` : `${flSubject.Lastname} ${flSubject.Firstname} ${flSubject.Middlename}`
    const subtitle = flSubject.FlSubject ? `Создан: ${flSubject.DateCreate}` : `СНИЛС: ${flSubject.Snils}`
    return (
        <SmallCard onClick={onClick} avatar={avatar} onRemove={onRemove} title={`${flSubjectName} ${flAgentName}`} subtitle={subtitle}>
        </SmallCard>
    )
}