import React, { useState, useEffect, useRef } from 'react';
import styles from './menu.module.css';
import dotsThreeOutlineVertical from '../../icons/dotsThreeOutlineVertical.svg';

const Menu = ({ children }) => {
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const menuRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [menuRef]);

  const handleMenuClick = () => {
    setMenuIsOpen(!menuIsOpen);
  };

  return (
    <div>
      <button
        className={styles.button}
        onClick={handleMenuClick}
      >
        <img src={dotsThreeOutlineVertical} width="15px" height="15px" />
        {menuIsOpen && (
          <div ref={menuRef} className={styles.menu}>
            {children}
          </div>
        )}
      </button>
    </div>
  );
};

export default Menu;
