import Button from "../Button/Button";

import styles from "./request.footer.module.css";

export default function RequestFooter({ back, next }) {
  return (
    <div className={styles.container}>
      {back &&
        back
      }
      {next &&
        next
      }
    </div>)
}