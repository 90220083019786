import Button from "../Button/Button";
import TableCell from "./TableCell";
import styles from "./table.module.css";

export default function TableFooter({ children }) {
  return (
    <div className={styles.tfoot}>
      {children}
      {/* <Button text="Предыдущая"></Button>
          <span className={styles.paginationTitle}>Страница 1 из 10</span>
          <Button text="Следующая"></Button> */}
    </div>
  )
}