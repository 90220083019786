import { useEffect, useRef, useState } from "react";
import cn from 'classnames';

import { useAddressService } from "../../Services/AddressService";
import { Skeleton } from '@mui/material';

import styles from "./input.module.css";

export default function AddressInput({ value, setValue, disabled, name, label, placeholder, error, skeleton }) {
    const {getAddress} = useAddressService();
    const [addresses, setAddresses] = useState([]);
    const [isOptions, setIsOptions] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [isSearch, setIsSearch] = useState(false);
    const inputRef = useRef(null);
    const [addressObject, setAddressObject] = useState({});
    const [isError, setIsError] = useState(false)

  useEffect(() => {
    setIsError(!!error)
  }, [error])

    const handleClickOutside = (e) => {
      if (inputRef.current && !inputRef.current.contains(e.target)) {
        setIsOptions(false);
        setSearchValue('');
        setIsSearch(false);
      }
    };

    useEffect(() => {
      if(typeof value === 'string') {
        getAddress(value)
          .then((x) => x?.find((obj) => obj.Note === value))
          .then(obj => {
            if (!obj) {
              setIsError(true);
            } else {
              setIsError(false);
              setAddressObject(obj);
            }
          })
      } else {
        setAddressObject(value)
      }
    }, [value])

    useEffect(() => {
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, []);
    return (
        <div className={styles.container} style={{ display: "flex" }} ref={inputRef}>
            {skeleton ? <Skeleton variant='rounded' width='100px' height='17px'/> : <label>{label ? label : 'Адрес'}</label>}
            {skeleton
              ?
              <Skeleton variant='rounded' width='100%' height='40.5px'/>
              :
              <input
                disabled={disabled && disabled}
                className={cn(styles.input, {[styles.inputError]: isError})}
                value={isSearch ? searchValue : isError ? value : addressObject?.Note || ''}
                type='text'
                onChange={(e) => {
                  setSearchValue(e.target.value);
                  getAddress(e.target.value).then(x=>setAddresses(x))
                }}
                onClick={(e) => {
                  getAddress(e.target.value).then(x=>setAddresses(x))
                  setIsOptions(true);
                  setIsSearch(true);
                  setSearchValue(e.target.value)
                }}
                placeholder={placeholder ? placeholder : 'Введите адрес объекта' }
                name={name && name}
              />
            }
            {isError && !skeleton && <label className={styles.error_text}>{error ? error : 'Введите корректный адрес'}</label>}
            {(isOptions &&
                <div className={styles.options} style={{ flexDirection: 'column' }}>
                    {addresses?.map((x,index) =>
                        <span
                            key={index}
                            onClick={(e) => {
                                setValue(x);
                                setIsOptions(false);
                                e.preventDefault();
                                setSearchValue('');
                                setIsSearch(false);
                                setIsError(false);
                            }}
                        >
                            {x.Note}
                        </span>
                    )}
                    {addresses?.length === 0 &&
                        <span>Не найдено</span>
                    }
                </div>
            )}
        </div>
    )
}