import { useState } from "react"
import CardHeader from "../../../Components/Card/CardHeader"
import Dropdown from "../../../Components/Dropdown/Dropdown"
import RequestFooter from "../../../Components/RequestFooter/RequestFooter"
import styles from "./request.result.module.css"
import SmallCard from "../../../Components/SmallCard/SmallCard"
import folderIcon from "../../../icons/folderIcon.svg"
import Modal from "../../../Components/Modal/Modal"
import fileIcon from "../../../icons/PdfIcon.svg"
import { useOutletContext } from "react-router-dom"
import useRequestContext from "../useRequestContext"
import Badge from "../../../Components/Badge/Badge"
import Button from "../../../Components/Button/Button"
import CardContainer from "../../../Components/Card/CardContainer"
import CardBody from "../../../Components/Card/CardBody"
import cn from 'classnames';

export default function RequestResult() {
  const [isShow, setShow] = useState(true)
  const [isModal, setModal] = useState(false)
  const { request, id, status } = useRequestContext()

  const formatDate = (dateString) => {
    const date = new Date(dateString);

    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();

    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    return `${day}.${month}.${year} ${hours}:${minutes}`;
  };

  return (
    <div className={styles.container}>
      <CardContainer className={styles.info_container}>
        <CardHeader title="Общая информация"/>
        <CardBody>

          <div className={styles.info_body}>
            <div className={styles.info_item}>
              <span className={styles.info_key}>Статус заявки</span>
              <div className={styles.info_value}>
                <Badge type={status.style} text={status.name}/>
              </div>
            </div>
            <div className={styles.info_item}>
              <span className={styles.info_key}>Номер заявки в Росреестре</span>
              <div className={styles.info_value}>
                {
                  status?.code >= 5 &&
                  <span>{request?.Rosreestr?.RegistryNumber}</span>
                }

              </div>
            </div>
            <div className={styles.info_item}>
              <span className={styles.info_key}>Номер записи КУВД</span>
              <div className={styles.info_value}>

                {request?.Rosreestr?.Kuvd &&
                  <span>{request?.Rosreestr?.Kuvd}</span>
                }
              </div>
            </div>
            <div className={styles.info_item}>
              <span className={styles.info_key}>История статусов Росреестра</span>
              <div className={styles.info_value}>
                <Dropdown
                  status
                  title={request?.Rosreestr?.RegistryMessage}
                  button={
                    request?.Rosreestr?.RRStatuses?.some(status => status.Code === "processed") && request?.Rosreestr?.RRStatuses?.map(status =>
                      status.Code === "processed" && (
                        <Button
                          blank
                          href={status.FileLink}
                          text='Скачать выписку из ЕГРН'
                          onClick={(e) => e.stopPropagation()}
                        />
                      )
                  )}
                >
                  <div className={styles.status_wrapper}>
                    {request?.Rosreestr?.RRStatuses.map((status) =>
                      <div className={styles.status_items}>
                        <span className={styles.status_title}>{status?.Description}</span>
                        <span>{status?.Time}</span>
                        {status?.FileLink &&
                          <a className={styles.link} target="_blank" href={status?.FileLink}>
                            Скачать файл
                          </a>
                        }
                      </div>
                    )}
                  </div>
                </Dropdown>
              </div>
            </div>
            <div className={cn(styles.info_item, styles.info_center)}>
              <span className={styles.info_key}>УИН для оплаты</span>
              <div className={styles.info_wrapper}>
                {request?.Rosreestr?.Uins?.length > 0 &&
                  request?.Rosreestr?.Uins?.map((uin, index) =>
                    <div className={styles.info_value}>
                    <span>
                      {uin}
                    </span>
                      {request?.FrontPayerModel?.length > 0 ?
                        <div className={styles.uin}>
                          <span className={styles.pay}>{`(${request?.FrontPayerModel[index]?.PayerFullName}, ${request?.FrontPayerModel[index]?.Amount / 100} р)`}</span>
                          <Badge type='success' text='Оплачено'/>
                          <span>{formatDate(request?.FrontPayerModel[index]?.PaymentDate)}</span>
                        </div>
                        :
                        <div className={styles.uin}>
                          {!request?.Rosreestr?.RRStatuses?.some(status => status.Code === "paid") && request?.Rosreestr?.RRStatuses?.map(status =>
                              (status.Code === "awaitingPayment" && request?.Status?.code !== 5) && (
                                <Button
                                  blank
                                  href={status.FileLink}
                                  text='Скачать платежное поручение'
                                />
                              )
                          )}
                          {request?.Rosreestr?.RRStatuses?.some(status => status.Code === "paid") || (request?.Status?.code === 5) ? (
                            <Badge type='success' text='Оплачено'/>
                          ) : (
                            <Button
                              blank
                              href={`https://payment.gosuslugi.ru/?billNumber=${uin}`}
                              text='Оплатить'
                            />
                          )
                          }
                        </div>
                      }
                    </div>
                  )
                }
              </div>
            </div>

            <div className={styles.info_item}>
              <span className={styles.info_key}>Документы</span>
              <div className={styles.info_value}>
                <span>—</span>
              </div>
            </div>
            {/* {request?.Rosreestr.Documents.map((x) =>
                        <div className={styles.info_item}>
                            <span className={styles.info_key}></span>
                            <div className={styles.info_value}>
                                <span>{x.FileName}</span>
                            </div>
                        </div>
                    )
                    } */}

            <Dropdown title="Информация по заявке" >

              <div className={styles.info_item}>
                <span className={styles.info_key}>Название заявки</span>
                <div className={styles.info_value}>
                  <span>{`${request?.RegAction.name} #${request?.Number}`}</span>
                </div>
              </div>
              <div className={styles.info_item}>
                <span className={styles.info_key}>Дата создания заявки</span>
                <div className={styles.info_value}>
                  <span>{request?.DateCreate}</span>
                </div>
              </div>
              <div className={styles.info_item}>
                <span className={styles.info_key}>Автор заявки</span>
                <div className={styles.info_value}>
                  <span>{request?.User.name}</span>
                </div>
              </div>
              <div className={styles.info_item}>
                <span className={styles.info_key}>Объект</span>
                <div className={styles.info_value}>
                  <span>{request?.Object.Address.Note}</span>
                </div>
              </div>
              <div className={styles.info_item}>
                <span className={styles.info_key}>Участники</span>
                <div className={cn(styles.info_value, styles.participant)}>
                  {request?.Participants?.map((participant) => {
                    const subject = participant?.UlAgentFlSubject || participant?.FlSubject || participant?.UlAgent;

                    return (
                      <span>
                        {`${subject?.Lastname || ''} ${subject?.Firstname || ''} ${subject?.Middlename || ''}`}
                        {subject?.UlSubjectName ? ` (${subject?.UlSubjectName})` : ''}
                      </span>
                    );
                  })}
                </div>
              </div>
            </Dropdown>
          </div>
        </CardBody>
      </CardContainer>
      {/* {status.code === 5 &&
                <CardContainer>
                    <CardHeader title="Результат регистрации в Росреестре"></CardHeader>
                    <CardBody >

                        <SmallCard avatar={folderIcon} onSelect={() => setModal(true)} type="default" title="Результаты регистрации" subtitle="Папка со всеми результатами из Росреестра" />
                        <SmallCard avatar={folderIcon} onSelect={() => setModal(true)} type="default" title="Папка со всеми документами, отправленными в Росреестр" subtitle="Папка со всеми документами, отправленными в Росреестр" />
                        <SmallCard avatar={folderIcon} onSelect={() => setModal(true)} type="default" title="Сгенерированное заявление на регистрацию №С20210514.pdf" subtitle="Заявление о государственной регистрации права, ограничения (обременения) права на недвижимое имущество, сделки с недвижимым имуществом, государственной регистрации законного владельца закладной" />

                    </CardBody>
                </CardContainer>
            } */}

      <RequestFooter back={<Button href={`/request/signature/${request?.Id}`} text="Назад"/>}/>
      <Modal title="Файлы" isOpen={isModal} setOpen={setModal}>
        <SmallCard avatar={fileIcon} type="default" title="Право собственности.pdf" subtitle=""/>
      </Modal>
    </div>)
}