import React, { useEffect, useState, useTransition } from "react";
import { Outlet, useNavigate, useOutletContext } from "react-router-dom";

import Button from "../../../Components/Button/Button";
import CardHeader from "../../../Components/Card/CardHeader";
import Input from "../../../Components/Input/Input";
import RequestFooter from "../../../Components/RequestFooter/RequestFooter";
import Select from "../../../Components/Select/Select";
import TextArea from "../../../Components/TextArea/TextArea";
import Modal from "../../../Components/Modal/Modal";
import SmallCard from "../../../Components/SmallCard/SmallCard";
import Check from "../../../Components/Check/Check";
import EmptyBlock from "../../../Components/EmptyBlock/EmptyBlock";
import CardContainer from "../../../Components/Card/CardContainer";
import CardBody from "../../../Components/Card/CardBody";
import ModalNav from "../../../Components/Modal/ModalNav";

import plus from "../../../icons/plus-violet.svg";
import avatar from "../../../images/Avatar_2.svg";
import useRequestContext from "../useRequestContext";
import CustomersModal from "../../Customers/Modal/CustomersModal";
import AddFlSubjectModal from "../FlSubject/AddFlSubjectModal";
import { useFlService } from "../../../Services/FlSubjectService";
import { useUlService } from "../../../Services/UlSubjectService";
import AddUlSubjectModal from "../UlSubject/AddUlSubjectModal";
import AddUlRepresentingFlModal from "../UlRepresentingFl/AddUlRepresentingFlModal";
import UlSubjectCard from "../../../Components/UlSubjectCard/UlSubjectCard";
import FlSubjectCard from "../../../Components/FlSubjectCard/FlSubjectCard";
import styles from './request.create.module.css'

export default function PropertyRegistration() {
  const [isOpen, setOpen] = useState(false);
  const [flSubjectModal, setFlSubjectModal] = useState(false)
  const [ulSubjectModal, setUlSubjectModal] = useState(false)
  const [selected, setSelected] = useState("fl")
  const [flSubject, setFlSubject] = useState()
  const [ulSubject, setUlSubject] = useState()
  const [ulSubjectId, setUlSubjectId] = useState()
  const [ulSubjects, setUlSubjects] = useState([]);
  const [flSubjects, setFlSubjects] = useState([]);
  const { getUlSubjects } = useUlService();
  const { getFlSubjects } = useFlService();
  const [isRepresenting, setRepresenting] = useState(false)
  const [participant, setParticipant] = useState()

  const { request, isDisabled, deleteParticipant, getRequest } = useRequestContext()
  const { getUlAgent } = useUlService()
  useEffect(() => {
    getFlSubjects().then(result => setFlSubjects(result))
    getUlSubjects().then(result => setUlSubjects(result))
  }, [])

  const onDeleteParticipant = (id) => {
    deleteParticipant(id).then(() => {
      getRequest(request.Id)
    })
  }

  const getUlAgentName = (ulId, agentId) => {
    return (ulSubjects?.find(subject => subject.Id === ulId)?.Agents?.find((agent) => agent?.Id === agentId))?.FlSubjectFIO
  }

  return (
    <React.Fragment>
      <CardContainer>
        <CardHeader title="Участники" />
        <CardBody>
          {request?.Participants?.length > 0 &&
            <React.Fragment>
              {request?.Participants.filter(x => x.UlAgent != null).map((x) =>
                <UlSubjectCard
                  onClick={() => {
                    setUlSubjectModal(true);
                    setUlSubject(x)
                  }}
                  ulSubject={x}
                  onRemove={isDisabled ? null : () => onDeleteParticipant(x.Id)}
                />
              )}
              {request?.Participants.filter(x => x.FlSubject != null).map((x) =>
                <FlSubjectCard
                  onClick={() => {
                    setFlSubjectModal(true);
                    setFlSubject(x);
                  }}
                  flSubject={x}
                  onRemove={isDisabled ? null : () => onDeleteParticipant(x.Id)}
                />
              )}
              {request?.Participants.filter(x => x.UlAgentFlSubject != null).map((x) =>
                <SmallCard
                  onClick={() => {
                    setRepresenting(true);
                    setFlSubject(x.UlAgentFlSubject)
                    setParticipant(x)
                  }}
                  onRemove={isDisabled ? null : () => onDeleteParticipant(x.Id)}
                  type="default"
                  key={x.Id}
                  title={`${x?.UlAgentFlSubject?.Lastname} ${x?.UlAgentFlSubject?.Firstname} ${x?.UlAgentFlSubject?.Middlename}`}
                  subtitle={`Представитель: ${ulSubjects.find(subject => subject.Id === x?.UlAgentFlSubject?.UlSubjectId)?.Name}`}
                  avatar={avatar}
                >
                  <label className={styles.subtitle}>
                    <span>Подписант: </span>
                    {getUlAgentName(x?.UlAgentFlSubject?.UlSubjectId, x?.UlAgentFlSubject?.UlAgentId)}
                  </label>
                </SmallCard>
              )}
            </React.Fragment>
          }
          {request?.Participants?.length === 0 &&
            <EmptyBlock title="Текущие участники" />
          }
          {!isDisabled &&
            < Button
              width="100%"
              text="Добавить участника"
              onClick={() => setOpen(true)}
              large
              icon={plus}
              type="primary"
            />
          }
        </CardBody>
      </CardContainer>
      <CustomersModal
        flSubjects={flSubjects}
        ulSubjects={ulSubjects}
        selected={selected}
        setSelected={setSelected}
        title="Добавить участника"
        onClick={(x) => {
          setOpen(false);
          if (selected == "fl") {
            setFlSubject(x)
            setFlSubjectModal(true)
          }
          if (selected == "ul") {
            setUlSubject(x)
            setUlSubjectModal(true)
          }
          if (selected == "representing") {
            setFlSubject(x)
            setRepresenting(true)
            setUlSubjectId(ulSubjects[0].Id)
          }
        }}
        setOpen={setOpen}
        isOpen={isOpen}
      >
      </CustomersModal>
      <AddUlRepresentingFlModal
        setUlSubjectId={setUlSubjectId}
        flSubject={flSubject}
        ulSubjectId={ulSubjectId}
        isModal={isRepresenting}
        setModal={setRepresenting}
        ulSubjectList={ulSubjects}
        participant={participant}
        ulSubjects={ulSubjects}
      />
      <AddUlSubjectModal
        ulSubject={ulSubject}
        isOpen={ulSubjectModal}
        setOpen={setUlSubjectModal}
      />
      <AddFlSubjectModal
        flSubject={flSubject}
        flSubjects={flSubjects}
        isOpen={flSubjectModal}
        setOpen={setFlSubjectModal}
      />
    </React.Fragment>
  )
}

