import { actions } from '../actions';

export function getBuilding(dispatch) {
  async function updateBuilder(value) {
    dispatch({
      type: actions.builder,
      payload: value
    })
  }

  async function updateParticipant(value) {
    dispatch({
      type: actions.participant,
      payload: value
    })
  }

  async function updateComplex(value) {
    dispatch({
      type: actions.complex,
      payload: value
    })
  }

  async function updateBuilding(value) {
    dispatch({
      type: actions.building,
      payload: value
    })
  }

  async function updateApartment(value) {
    dispatch({
      type: actions.apartment,
      payload: value
    })
  }

  async function updateOwner(value) {
    dispatch({
      type: actions.owner,
      payload: value
    })
  }

  return {
    updateBuilder,
    updateParticipant,
    updateComplex,
    updateBuilding,
    updateApartment,
    updateOwner
  }
}
