import React, { useEffect, useState } from 'react';
import { Skeleton } from '@mui/material';

import CardContainer from '../../../Components/Card/CardContainer';
import CardHeader from '../../../Components/Card/CardHeader';
import CardBody from '../../../Components/Card/CardBody';
import SmallCard from '../../../Components/SmallCard/SmallCard';
import EmptyBlock from '../../../Components/EmptyBlock/EmptyBlock';
import Button from '../../../Components/Button/Button';
import CustomersModal from '../Modal/CustomersModal';
import Badge from '../../../Components/Badge/Badge';
import { useUlService } from '../../../Services/UlSubjectService';
import { useFlService } from '../../../Services/FlSubjectService';

import avatar from '../../../images/Avatar_2.svg';
import plus from '../../../icons/plus-violet.svg';
import plusCircle from '../../../icons/PlusCircle.svg';

export default function UlMembers({ ulSubject, setActiveAgent, setAgentModal }) {
  const [customersModal, setCustomerModal] = useState(false);
  const [selected, setSelected] = useState('fl');
  const [ulSubjects, setUlSubjects] = useState([]);
  const [flSubjects, setFlSubjects] = useState([]);

  const {assignMainUl, getUlSubjectsPage, deleteUlAgent} = useUlService();
  const {getFlSubjectsPage} = useFlService();

  useEffect(() => {
    getFlSubjectsPage(1, 1000).then(res => setFlSubjects(res.Data));
    getUlSubjectsPage(1, 1000).then(res => setUlSubjects(res.Data))
  }, [])


  async function assignMain(id) {
    const response = await assignMainUl(id)
    window.location.reload()
  }

  async function editAgent(x) {
    setActiveAgent(x)
    setAgentModal(true)
  }

  return (
    <React.Fragment>
      <CardContainer>
        <CardHeader title="Представители"/>
        <CardBody>
          {ulSubject?.Agents == null &&
            <Skeleton height={100}/>
          }
          {ulSubject?.Agents?.sort((a, b) => (b.IsMain ? 1 : -1))
            .map((x) => {
              const subtitle = `Должность: ${x.Position?.name}; Тип документа: ${x.Document?.Type?.name}`;
              return (
                <SmallCard
                  onClick={() => editAgent(x)}
                  onRemove={() => {
                    deleteUlAgent(x.Id).then(res => {
                      if (res.status === 200) {
                        setAgentModal(false);
                        window.location.reload();
                      }
                    })
                  }}
                  title={x.FioGenitive}
                  subtitle={subtitle}
                  avatar={avatar}
                  badge={
                    x.IsMain ? (
                      <Badge type="success" text="Основной представитель" />
                    ) : (
                      <Badge
                        type="primary"
                        text="Назначить основным"
                        icon={plusCircle}
                        onClick={() => assignMain(x.Id)}
                      />
                    )
                  }
                />
              );
            })}

          {ulSubject?.Agents?.length === 0 &&
            <EmptyBlock title="Текущие представители" />
          }
          <Button
            text="Добавить представителя"
            large
            onClick={() => setCustomerModal(true)}
            icon={plus}
            type='primary'
          />
        </CardBody>
      </CardContainer>

      <CustomersModal
        onClick={(x) => editAgent({
          Id: null,
          FlSubjectId: x.Id,
          UlSubjectId: ulSubject?.Id,
          FioGenitive: `${x.Lastname} ${x.Firstname} ${x.Middlename}`,
        })}
        title="Добавление представителя"
        isOpen={customersModal}
        setOpen={setCustomerModal}
        ulSubjects={ulSubjects}
        flSubjects={flSubjects}
        selected={selected}
        setSelected={setSelected}
      >
        {/* <Button text="Создать представителя" width="100%" onClick={() => setAgentModal(true)} icon={plus} color="#6941C6" bgColor="#F9F5FF"></Button> */}
      </CustomersModal>
    </React.Fragment>
  )
}
