import React, { useState } from 'react';

import CardHeader from '../../../Components/Card/CardHeader';
import Input from '../../../Components/Input/Input';
import Button from '../../../Components/Button/Button';
import AddressInput from '../../../Components/Input/AddressInput';
import Cadastral from './Cadastral/Cadastral';
import { CircularProgress } from '@mui/material';
import TableCell from '../../../Components/Table/TableCell';
import TableHeader from '../../../Components/Table/TableHeader';
import EmptyBlock from '../../../Components/EmptyBlock/EmptyBlock';
import ModalCenterFull from '../../../Components/ModalCenter/ModalCenterFull';
import Check from '../../../Components/Check/Check';
import { useEgrnService } from '../../../Services/Egrn/EgrnService';

import styles from './object.search.module.css';

const ObjectSearch = () => {
  const [buttonType, setButtonType] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingCadastral, setIsLoadingCadastral] = useState(false);
  const [searchValue, setSearchValue] = useState(null);
  const [cadastralValue, setSearchValueCadastral] = useState(null);
  const [searchData, setSearchData] = useState(null);
  const [cadastralData, setCadastralData] = useState(null);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [checked, setChecked] = useState(false)

  const {getObjects, getAllObjects, getObjectsInfo, createObject} = useEgrnService();

  function getObjectType(type) {
    switch (type) {
      case 'PARCEL':
        return {
          text: 'Земельный участок',
        };
      case 'FLAT':
        return {
          text: 'Помещение',
        };
      case 'OKS':
        return {
          text: 'Здание',
        };
      default:
        return {
          text: 'Неизвестно',
        };
    }
  }

  const getData = (address) => {
    setIsLoading(true);
    const queryFunction = checked ? getAllObjects : getObjects;

    queryFunction(address)
      .then(data => {
        setSearchData(data);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  }


  const getCadastralData = (cadastralNumber) => {
    setIsLoadingCadastral(true);
    getObjectsInfo(cadastralNumber)
      .then(response => {
        setCadastralData(response);
        setIsLoadingCadastral(false);
      })
      .catch(() => {
        setIsLoadingCadastral(false);
        setIsOpenModal(false)
      });
  }

  return (
    <div>
      <div className={styles.card}>
        <CardHeader title="Поиск объекта" />
        <div className={styles.card_body}>
          <div className={styles.search}>
            <Button isDisabled={buttonType === 1} onClick={() => setButtonType(1)} text='Поиск по адресу'/>
            <Button isDisabled={buttonType === 2} onClick={() => {
              setCadastralData(null)
              setButtonType(2);
            }} text='Поиск по кадастровому номеру'/>
          </div>
          <div className={styles.search}>
            {buttonType === 1 &&
              <AddressInput value={searchValue} setValue={(value) => setSearchValue(value)} label='Поиск объекта по адресу' placeholder="Введите адрес"/>
            }
            {buttonType === 2 &&
              <Input
                onChange={(value) => setSearchValueCadastral(value)}
                label='Поиск объекта по кадастровому номеру'
                placeholder="Введите кадастровый номер"
                mask="00:00:000000:000000000"
                value={cadastralValue}
              />
            }
            <div className={styles.button}>
              <Button onClick={() => {
                if (buttonType === 1) {
                  getData(searchValue?.Value)
                } else if (buttonType === 2) {
                  getCadastralData(cadastralValue)
                }
              }} large text='НАЙТИ'/>
            </div>
          </div>
          {buttonType === 1 &&
            <Check selected={checked} onClick={() => setChecked(!checked)} label='Поиск по всем объектам многоквартирного дома'/>
          }
          {buttonType === 1 &&
            <div>
              {isLoading ?
                <div className={styles.loader}>
                  <CircularProgress color="inherit"/>
                  {checked &&
                    <p>Поиск может занять до 3-х минут. Пожалуйста, подождите.</p>
                  }
                </div>
                :
                <div>
                  {searchData?.length ?
                    <div className={styles.card_wrapper}>
                      <div className={styles.save_wrapper}>
                        <p>Всего объектов: <span>{searchData?.length}</span></p>
                        {checked &&
                          <Button
                            onClick={() => createObject({
                              name: searchValue?.Note,
                              realObjects: searchData
                            })}
                            type='info'
                            text='Сохранить объект'
                          />
                        }
                      </div>
                      <tbody className={styles.card_container}>
                      <TableHeader>
                        <TableCell th="Кадастровый номер"/>
                        <TableCell th="Адрес"/>
                        <TableCell th="Вид объекта недвижимости"/>
                      </TableHeader>
                      {searchData?.map((item, index) =>
                          <tr key={index} className={styles.card_item} onClick={() => {
                            setIsOpenModal(true)
                            getCadastralData(item?.cadnum)
                          }}>
                            <TableCell title={item?.cadnum}/>
                            <TableCell subtitle={item?.full_name}/>
                            <TableCell subtitle={getObjectType(item?.type).text}/>
                          </tr>
                      )}
                      </tbody>
                    </div>
                    :
                    <div>
                      <EmptyBlock title='Не найдено'/>
                    </div>
                  }
                </div>
              }
            </div>
          }
          {buttonType === 2 &&
            <div>
              {isLoadingCadastral ?
                <div className={styles.loader}>
                  <CircularProgress color="inherit"/>
                  <p>Загружаем данные, пожалуйста, подождите...</p>
                </div>
                :
                <div>
                  {cadastralData?.length > 0 ?
                    <Cadastral data={cadastralData}/>
                    :
                    <div>
                      <EmptyBlock title='Не найдено'/>
                    </div>
                  }
                </div>
              }
            </div>
          }
        </div>
      </div>
      <ModalCenterFull
        isOpen={isOpenModal}
        setIsOpen={setIsOpenModal}
        title={
          <div>
            <h2>Информация по объекту</h2>
          </div>
        }
      >
        {isLoadingCadastral ?
          <div className={styles.loader_wrapper}>
            <CircularProgress color="inherit"/>
            <p>Загружаем данные, пожалуйста, подождите...</p>
          </div>
          :
          <div className={styles.modal}>
            <Cadastral data={cadastralData}/>
          </div>
        }
      </ModalCenterFull>
    </div>
  );
};

export default ObjectSearch;
