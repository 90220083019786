import React, { useRef } from 'react';
import styles from './button.module.css';
import cn from 'classnames';
import DotLoader from '../Loader/DotLoader';

export default function FileButton({ onUpload, label, isDisabled, isLoading, accept }) {
  const fileInputRef = useRef(null);

  const handleClick = () => {
    if (!isDisabled) {
      fileInputRef.current.click();
    }
  };

  const handleChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      onUpload(file);
    }
  };

  return (
    <div>
      <button onClick={handleClick} disabled={isDisabled} className={cn(styles.container, styles.button)}>
        {label || 'Загрузить ZIP-архив'}{isLoading && <DotLoader/>}
      </button>
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: 'none' }}
        accept={accept ? accept : '.zip'}
        onChange={handleChange}
      />
    </div>
  );
}
