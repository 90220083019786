import React, { useState, useEffect } from "react";

import Select from '../../../../Components/Select/Select';
import { Datepicker } from '../../../../Components/Datepicker/Datepicker';
import moment from 'moment';
import { Skeleton } from '@mui/material';

import style from './Datepicker.module.css'

const DatePicker = ({ value, onChange, skeleton }) => {
  const [date, setDate] = useState('');
  const [time, setTime] = useState('09:00');

  useEffect(() => {
    if (value) {
      const [datePart, timePart] = value.split("T");
      setDate(datePart);
      setTime(timePart.slice(0, 5));
    }
  }, [value]);

  const generateTimeIntervals = () => {
    const intervals = [];
    const startHour = 9;
    const endHour = 17;

    for (let hour = startHour; hour <= endHour; hour++) {
      intervals.push(`${hour}:00`);
    }

    return intervals
  }

  const handleDateChange = (newDate) => {
    const parsedDate = moment(newDate).format('YYYY-MM-DD');
    setDate(parsedDate);
    onChange(`${parsedDate}T${time}:00`);
  };

  const handleTimeChange = (e) => {
    setTime(e);
    onChange(`${date}T${e}:00`);
  };

  return (
    skeleton ?
      <Skeleton variant='rounded' width='100%' height='40.5px'/>
      :
      <div className={style.datepicker}>
        <Datepicker
          value={moment(date).format('YYYY-MM-DD')}
          onChange={handleDateChange}
        />
        <Select
          value={time}
          onChange={handleTimeChange}
          placeholder='Выберите время'
        >
          {generateTimeIntervals().map(interval => (
                <option key={interval} value={interval}>{interval}</option>
              ))}
        </Select>
      </div>
  );
};

export default DatePicker;
