import React from 'react';
import style from './tooltipText.module.css';

const TooltipLink = ({ link, text, tooltipText }) => {
  return (
    <div className={style.container}>
      <a className={style.text} href={link} target="_blank" rel="noopener noreferrer">
        {text}
      </a>
      <div className={ style.fullText }>{tooltipText && tooltipText}</div>
    </div>
  );
};

export default TooltipLink;
