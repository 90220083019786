import { useState } from "react";

import ModalNav from "../../../Components/Modal/ModalNav";
import ModalTab from "../../../Components/Modal/ModalTab";
import UlSubjectList from "../List/UlSubjectList";
import FlSubjectList from "../List/FlSubjectList";
import Modal from "../../../Components/Modal/Modal";

export default function CustomersModal({ title, isOpen, setOpen, onClick, children, selected, setSelected, flSubjects, ulSubjects, onSave }) {

  return (
    <Modal title={title} isOpen={isOpen} setOpen={setOpen} onSave={onSave}>
      <ModalNav>
        <ModalTab
          text="Физ лицо"
          onClick={() => setSelected("fl")}
          isActive={selected === "fl"}
        />
        <ModalTab
          text="Юр лицо"
          onClick={() => setSelected("ul")}
          isActive={selected === "ul"}
        />
        <ModalTab
          text="ЮЛ как представитель ФЛ"
          onClick={() => setSelected("representing")}
          isActive={selected === "representing"}
        >
        </ModalTab>
      </ModalNav>
      {(selected === "fl" || selected === "representing") && <FlSubjectList customers={flSubjects} onClick={onClick} isOpen={isOpen} />}
      {selected === "ul" && <UlSubjectList customers={ulSubjects} onClick={onClick} />}

      {children}
    </Modal>
  );
}