import { useContext } from "react";
import { HttpContext } from "../HttpContext";
import { Buffer } from 'buffer';

export default function useFileService() {
    const { httpClient } = useContext(HttpContext)
    const downloadFile = async (guid) => {
        const arrayBuffer = await httpClient.get(`/file/download/${guid}`, {
            responseType: 'arraybuffer'
        });
        let buffer = Buffer.from(arrayBuffer.data, 'binary').toString("base64");
        return buffer;
    }
    function base64ToFile(base64, filename) {
        const pureBase64 = base64.split(',').pop();
        const byteString = atob(pureBase64);
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        return new File([ab], filename, { type: 'application/octet-stream' });
    }

    const downloadStorageFile = async (storageId, name) => {
        try {
            const response = await httpClient.get(`/file/storage-download/${storageId}`, {
                responseType: 'arraybuffer'
            });

            if (response.status !== 200) {
                throw new Error('Failed to download file');
            }

            const fileBlob = new Blob([response.data], { type: 'application/octet-stream' });
            const fileUrl = URL.createObjectURL(fileBlob);

            const downloadLink = document.createElement('a');
            downloadLink.href = fileUrl;
            downloadLink.download = name;
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
            URL.revokeObjectURL(fileUrl);
        } catch (error) {
            console.error('Download error:', error);
        }

    };

    const getStorageFile = async (storageId) => {
        const arrayBuffer = await httpClient.get(`/file/storage-download/${storageId}`, {
            responseType: 'arraybuffer'
        });
        let buffer = Buffer.from(arrayBuffer.data, 'binary').toString("base64");
        return buffer;
    }

    const uploadFile = async (body) => {
        return  httpClient.post('/file/storage-chess-upload', body).then(promise => promise.data)
    }

    const uploadSignature = async (body) => {
        return  httpClient.post('/file/signature-upload', body).then(promise => promise.data)
    }

    const downloadLocalFile = async (file, name) => {
        try {
            const fileBlob = new Blob([file], { type: 'application/octet-stream' });
            const fileUrl = URL.createObjectURL(fileBlob);

            const downloadLink = document.createElement('a');
            downloadLink.href = fileUrl;
            downloadLink.download = name;
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
            URL.revokeObjectURL(fileUrl);
        } catch (error) {
            console.error('Download error:', error);
        }
    }

    return { downloadFile, base64ToFile, downloadStorageFile, uploadFile, getStorageFile, downloadLocalFile, uploadSignature }
}