import React, { useState } from "react";

import Input from "../../../Components/Input/Input";
import Select from "../../../Components/Select/Select";
import { useCountryService } from '../../../Services/CountryService';
import { useAddressService } from '../../../Services/AddressService';

import styles from "./customer.edit.module.css";
import AddressInput from '../../../Components/Input/AddressInput';

export default function UlSubjectEdit({ customer, setCustomer, wrap }) {
  const {countries} = useCountryService()
  const {getAddress} = useAddressService()

  const [addresses, setAddresses] = useState([]);

  return (
    <React.Fragment>
      <Input
        label="Полное наименование"
        name="name"
        value={customer?.Name}
        onChange={(value) => setCustomer({
          ...customer,
          Name: value
        })}
        placeholder="ООО 'Ромашка'"
      />
      <div className={styles.separated}>
        <Input
          label="ИНН"
          name="inn"
          onChange={(value) => setCustomer({
            ...customer,
            Inn: value
          })}
          value={customer.Inn}
          mask="0000000000"
          placeholder="Введите ИНН для поиска"
        />
        <Input
          label="КПП"
          name="kpp"
          onChange={(value) => setCustomer({
            ...customer,
            Kpp: value
          })}
          value={customer.Kpp}
          mask="000 000 000"
          placeholder="533 221 351"
        />
      </div>
      <div className={styles.separated}>
        <Input
          value={customer?.Ogrn}
          onChange={(value) => setCustomer({
            ...customer,
            Ogrn: value
          })}
          name="ogrn"
          label="ОГРН"
          mask="000 000 000 000 0"
          placeholder="356 225 134 742 0"
        />
        <Input
          value={customer?.RegDate}
          onChange={(value) => setCustomer({
            ...customer,
            RegDate: value
          })}
          name="regDate"
          mask={Date}
          placeholder="дд.мм.гггг"
          label="Дата регистрации компании"
        />
      </div>
      <AddressInput
        value={customer.Address?.Note}
        setValue={(value) => {
          setCustomer({
            ...customer,
            Address: value
          });
        }}
        label="Юридический адрес компании"
      />
      <div className={styles.separated} style={{ flexWrap: wrap != null ? "wrap" : "nowrap" }}>
        <Input
          value={customer.Phone}
          mask="+{7}(000)000-00-00"
          onChange={(value) => setCustomer({
            ...customer,
            Phone: value
          })}
          name="phone"
          type="phone"
          label="Телефон"
          placeholder="+7 (456) 894 22 11"
        />
        <Input
          value={customer.Email}
          onChange={(value) => setCustomer({
            ...customer,
            Email: value
          })}
          name="email"
          type="email"
          label="Электронная почта"
          placeholder="example@yandex.ru"
        />
      </div>
      <Select
        value={customer.Country?.code}
        onChange={(value) => setCustomer({
          ...customer,
          Country: { code: value }
        })}
        label="Страна"
        placeholder="Выберите из списка..."
      >
        {countries?.map((x) =>
          <option value={x.code}>{x.name}</option>
        )}
      </Select>
    </React.Fragment>
  )
}