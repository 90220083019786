import { useContext, useRef, useState } from 'react';
import { HttpContext } from "../HttpContext";
import { actions } from './reducer/actions';

export function useResidentialComplexService() {
  const { dispatch, httpClient } = useContext(HttpContext);
  const [residentialComplexes, setResidentialComplexes] = useState(null);
  const abortControllerRef = useRef(null);

  const getResidentialComplexes = async (page) => {
    const result = await httpClient.get(`/residential-complex/?PageNumber=${page}&PageSize=10`, { hideLoader: true })
    dispatch({
      type: actions.complexList,
      payload: result.data
    })
  }

  const getResidentialComplex = async (id) => {
    return httpClient.get(`/residential-complex/${id}`, { hideLoader: true })
  }

  const updateResidentialComplex = async (body) => {
    const result = await httpClient.put("/residential-complex", body, { hideLoader: true })
    dispatch({
      type: actions.complexData,
      payload: result.data
    })
    return result.data;
  }

  const createResidentialComplex = async (body) => {
    const result = await httpClient.post("/residential-complex", body, { hideLoader: true })
    dispatch({
      type: actions.complexData,
      payload: result.data
    })
    return result.data;
  }

  const deleteResidentialComplex = async (id) => {
    const result = await httpClient.delete(`/residential-complex/${id}`, { hideLoader: true })
    dispatch({
      type: actions.complexData,
      payload: result.data
    })
    return result.data;
  }

  const getResidentialBuilding = async (id) => {
    return httpClient.get(`/residential-complex/building/${id}`, { hideLoader: true }).then(promise => promise.data)
  }

  const getResidentialBuildingLight = async (id) => {
    return httpClient.get(`/residential-complex/building-light/${id}`, { hideLoader: true }).then(promise => promise.data)
  }

  const updateResidentialBuilding = async (body) => {
    return httpClient.put("/residential-complex/building", body, { hideLoader: true }).then(promise => promise.data)
  }

  const createResidentialBuilding = async (body) => {
    return httpClient.post("/residential-complex/building", body, { hideLoader: true }).then(promise => promise.data)
  }

  const deleteResidentialBuilding = async (id) => {
    return httpClient.delete(`/residential-complex/building/${id}`, { hideLoader: true }).then(promise => promise)
  }

  const uploadResidentialComplexFile = async (body) => {
    return httpClient.post("/residential-complex/building-excel-parser", body, { hideLoader: true }).then(promise => promise.data)
  }

  const getResidentialApartment = async (id) => {
    return httpClient.get(`/residential-complex/building/apartment/${id}`, { hideLoader: true }).then(promise => promise.data)
  }

  const updateResidentialApartment = async (body) => {
    return httpClient.put("/residential-complex/building/apartment", body, { hideLoader: true }).then(promise => promise.data)
  }

  const getBuildingStatistics = async (id) => {
    return httpClient.get(`/residential-complex/building-statistics/${id}`, { hideLoader: true }).then(promise => promise.data)
  }

  const getBuildingStatus = async (buildingId, status, filter) => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    const abortController = new AbortController();
    abortControllerRef.current = abortController;

    try {
      const response = await httpClient.get(`/residential-complex/apartment-status-statistics/?buildingId=${buildingId}&status=${status}&filter=${filter}`, {
        signal: abortController.signal
      });
      return response.data;
    } catch (error) {
    }
  }

  const createOwner = async (body) => {
    return httpClient.post('/residential-complex/building/apartment/owner', body, { hideLoader: true }).then(promise => promise.data)
  }

  const updateOwner = async (body) => {
    return httpClient.put(`/residential-complex/building/apartment/owner`, body, { hideLoader: true }).then(promise => promise.data)
  }

  const deleteOwner = async (id) => {
    return httpClient.delete(`/residential-complex/building/apartment/owner/${id}`, { hideLoader: true }).then(promise => promise.data)
  }

  const getNotifications = async (id) => {
    return httpClient.get(`/residential-complex/building/apartment/owner-notific-file/${id}`, { responseType: 'blob', hideLoader: true }).then(promise => (promise.data))
  }

  const uploadZip = async (body) => {
    return httpClient.post('/residential-complex/get-chess-by-zip', body, { responseType: 'blob', hideLoader: true }).then(promise => (promise.data))
  }

  const regNewRight = async (id) => {
    return httpClient.get(`/residential-complex/reg-new-right/${id}`).then(promise => (promise.data))
  }

  return {
    residentialComplexes,
    setResidentialComplexes,
    getResidentialComplexes,
    getResidentialComplex,
    updateResidentialComplex,
    createResidentialComplex,
    getResidentialBuilding,
    getResidentialBuildingLight,
    deleteResidentialBuilding,
    updateResidentialBuilding,
    updateResidentialApartment,
    uploadResidentialComplexFile,
    deleteResidentialComplex,
    createResidentialBuilding,
    getResidentialApartment,
    getBuildingStatistics,
    getBuildingStatus,
    createOwner,
    updateOwner,
    deleteOwner,
    getNotifications,
    uploadZip,
    regNewRight
  }
}
