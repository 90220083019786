import { useState } from "react";
import chevronDown from "../../icons/chevron-down.svg";
import styles from "./dropdown.module.css";
import cn from 'classnames';

export default function Dropdown({ children, title, status, reverse, button }) {
  const [isShown, setIsShown] = useState(false);

  return (
    <div className={styles.container}>
      <div
        className={cn(styles.header, { [styles.status]: status, [styles.reverse]: reverse })}
        onClick={() => setIsShown(!isShown)}
      >
        {button && button}
        <img
          src={chevronDown}
          style={{ transform: isShown ? "rotate(180deg)" : "none", transition: "transform 0.3s ease" }}
        />
        <span className={styles.title}>{title}</span>
      </div>
      <div
        className={cn(styles.body, { [styles.isShown]: isShown })}
      >
        {children}
      </div>
    </div>
  );
}
