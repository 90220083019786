import { useEffect, useRef, useState } from "react";
import cn from 'classnames';
import { Skeleton } from '@mui/material';

import { useFlService } from '../../Services/FlSubjectService';

import styles from "./input.module.css";

export default function FlSubjectInput({ value, setValue, disabled, label, placeholder, skeleton }) {
  const {searchFlSubjects, getFlSubject, getFlSubjectsPage} = useFlService();
  const [subjects, setSubjects] = useState([]);
  const [isOptions, setIsOptions] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [isSearch, setIsSearch] = useState(false);
  const [selectedSubject, setSelectedSubject] = useState(false);
  const inputRef = useRef(null);

  const handleClickOutside = (e) => {
    if (inputRef.current && !inputRef.current.contains(e.target)) {
      setIsOptions(false);
      setSearchValue('');
      setIsSearch(false);
    }
  };

  useEffect(() => {
    if (value) {
      getFlSubject(value).then((x) => setSelectedSubject(x))
    }
  }, [value])

  useEffect(() => {
    getFlSubjectsPage(1, 1000).then(res => setSubjects(res.Data));
  }, [])

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  return (
    <div className={styles.container} style={{ display: "flex" }} ref={inputRef}>
      <label>{label}</label>
      { skeleton ?
        <Skeleton variant='rounded' width='100%' height='40.5px'/>
        :
        <input
          disabled={ disabled && disabled }
          className={ styles.input }
          value={ isSearch ? searchValue : selectedSubject ? `${ selectedSubject?.Lastname } ${ selectedSubject?.Firstname } ${ selectedSubject?.Middlename }` : '' }
          type='text'
          onChange={ (e) => {
            if (e.target.value.length > 1) {
              setSearchValue(e.target.value);
              searchFlSubjects(e.target.value).then(x => {
                if (x?.Data) {
                  setSubjects(x.Data);
                } else {
                  setSubjects(null)
                }
              })
            } else {
              setSearchValue(e.target.value);
              getFlSubjectsPage(1, 1000).then(res => setSubjects(res.Data));
            }
          } }
          onClick={ (e) => {
            setIsOptions(true);
            setIsSearch(true);
            setSearchValue('')
          } }
          placeholder={ placeholder ? placeholder : 'Выберите участника долевого строительства' }
        />
      }
      {(isOptions &&
        <div className={cn(styles.options, styles.flSubject)} style={{ flexDirection: 'column' }}>
          {subjects?.map((x,index) =>
            <p
              key={index}
              onClick={(e) => {
                setValue(x);
                setIsOptions(false);
                e.preventDefault();
                setSearchValue('');
                setIsSearch(false);
              }}
              className={styles.option}
            >
              {`${x.Lastname} ${x.Firstname} ${x.Middlename}`}
              <span className={styles.snils}>{`(СНИЛС: ${x.Snils})`}</span>
            </p>
          )}
          {subjects?.length === 0 &&
            <span>Не найдено</span>
          }
        </div>
      )}
    </div>
  )
}
