import SmallCard from "../SmallCard/SmallCard";
import avatar from "../../images/Avatar_2.svg";
import check from "../../images/Check.svg"
import checkEmpty from "../../images/Check_Empty.svg"
import React from "react";
export default function UlSubjectCard({ ulSubject, onSelect, onClick,onRemove }) {
  const mainAgent = ulSubject?.Agents?.find(x => x.IsMain);
  return (
    <React.Fragment>
      {ulSubject &&
        <SmallCard
          onRemove={onRemove}
          onClick={onClick}
          onSelect={onSelect}
          avatar={onSelect ? (ulSubject?.isSelected ? check : checkEmpty) : avatar}
          type={onSelect ?(ulSubject?.isSelected ? "primary" : "default") : "default"}
          title={ulSubject?.UlAgent?.UlSubjectName
            ?
            `${ulSubject?.UlAgent?.UlSubjectName} (${ulSubject?.UlAgent?.Lastname} ${ulSubject?.UlAgent?.Firstname} ${ulSubject?.UlAgent?.Middlename})`
            :
            ulSubject.Name
        }
          subtitle={ulSubject?.UlAgent ? `Тип документа: ${ulSubject?.Document?.Type?.name}` : `Представитель: ${mainAgent?.FlSubjectFIO}`}
        />
      }
    </React.Fragment>
  )
}