import React from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import { Skeleton } from '@mui/material';

import styles from "./button.module.css";
import colors from '../../styles/color.module.css';

export default function Button({ href, icon, onClick, text, isDisabled, isRevert, type = 'purple', large, skeleton, blank }) {
  const ContainerComponent = href ? (blank ? 'a' : Link) : 'a';

  return (skeleton
      ?
      <Skeleton variant='rounded' width='100%' height='35px'/>
      :
      <ContainerComponent
        className={cn(styles.container, colors[type], {
          [styles.disabled]: isDisabled,
          [styles.large]: large,
          [styles.revert]: isRevert
        })}
        to={!blank ? href : undefined}
        href={blank ? href : undefined}
        target={blank ? "_blank" : undefined}
        rel={blank ? "noopener noreferrer" : undefined}
        onClick={onClick}
      >
        {icon && <img width="18px" height="18px" src={icon} alt="Icon" />}
        <span>{text}</span>
      </ContainerComponent>
  );
}