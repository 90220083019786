import React, { useContext, useEffect, useState, useCallback, useMemo } from 'react';
import ccpa from 'crypto-pro-cadesplugin';

import ModalCenter from '../../../../../Components/ModalCenter/ModalCenter';
import Button from '../../../../../Components/Button/Button';
import SmallCard from '../../../../../Components/SmallCard/SmallCard';
import getCertsList from '../../../../Reguests/Signature/getCertsList';
import useFileService from '../../../../../Services/FileService';
import { HttpContext } from '../../../../../HttpContext';

import usb from '../../../../../icons/usb.svg';
import check from '../../../../../images/Check.svg';
import checkEmpty from '../../../../../images/Check_Empty.svg';
import pdf from '../../../../../icons/PdfIcon.svg';
import avatar from '../../../../../images/Avatar_2.svg';
import styles from '../homes.modal.module.css';
import Check from '../../../../../Components/Check/Check';
import { useSignmeService } from '../../../../../Services/SignMe/SignmeService';
import Badge from '../../../../../Components/Badge/Badge';

const Signature = ({ isOpen, onClose, fileName, fileId, onChange }) => {
  const { getStorageFile, base64ToFile } = useFileService();
  const { state } = useContext(HttpContext);
  const { participant } = state;

  const { status, signBuildingDocument } = useSignmeService(participant?.id);

  const [selectedCertificate, setSelectedCertificate] = useState(null);
  const [certs, setCerts] = useState([]);
  const [signatureType, setSignatureType] = useState(null);
  const [saveCertificate, setSaveCertificate] = useState(false);

  const saveToLocalStorage = (key, value) => {
    localStorage.setItem(key, value);
  };

  const removeFromLocalStorage = (key) => {
    localStorage.removeItem(key);
  };

  const getFromLocalStorage = (key) => {
    return localStorage.getItem(key);
  };

  useEffect(() => {
    const storedValue = getFromLocalStorage('signature');
    if (storedValue) {
      setSelectedCertificate(storedValue);
      setSaveCertificate(true);
    } else {
      setSelectedCertificate(null);
      setSaveCertificate(false);
    }
  }, []);

  const fetchCerts = useCallback(async () => {
    const certsList = await getCertsList();
    const formattedCerts = certsList.map((cert) => {
      const subjectInfo = cert.subjectInfo;
      let info = cert.friendlyIssuerInfo()[0].text;
      const valid = cert.friendlyValidPeriod().to.ddmmyy;

      if (info.startsWith('"') && info.endsWith('"')) {
        info = info.slice(1, -1);
      }

      let subject = '';
      const organizationMatch = subjectInfo.match(/O=([^,]+)/);
      if (organizationMatch) {
        subject = organizationMatch[1];
      } else {
        const cnMatch = subjectInfo.match(/CN=([^,]+)/);
        subject = cnMatch ? cnMatch[1] : subjectInfo;
      }

      return { ...cert, subject, valid, info };
    });
    setCerts(formattedCerts);
  }, []);

  useEffect(() => {
    if (isOpen && signatureType === 'token') {
      fetchCerts();
    } else {
      setSignatureType(signatureType ? signatureType : null);
    }
  }, [isOpen, signatureType, fetchCerts]);

  const handleSign = useCallback(async () => {
    try {
      const file = await getStorageFile(fileId);
      const cryptoPlugin = await ccpa();
      const sigFile = await cryptoPlugin.signBase64(selectedCertificate, file);
      const sig = base64ToFile(sigFile, `${fileName}.sig`);
      if (saveCertificate) {
        saveToLocalStorage('signature', selectedCertificate);
      } else {
        removeFromLocalStorage('signature');
      }
      const formData = new FormData();
      formData.append('File', sig);
      onChange && onChange(formData, sig, sigFile);
    } catch (error) {
      console.error('Error signing file:', error);
    }
  }, [fileId, fileName, selectedCertificate, saveCertificate, getStorageFile, base64ToFile, onChange]);

  const renderCerts = useMemo(() => {
    return certs.map((x) => (
      <SmallCard
        key={x.thumbprint}
        avatar={selectedCertificate === x.thumbprint ? check : checkEmpty}
        type={selectedCertificate === x.thumbprint ? "primary" : "default"}
        onClick={() => setSelectedCertificate(x.thumbprint)}
        title={x.info}
      >
        <label className={styles.signature_text}>{x.subject}</label>
        <label className={styles.signature_text}>{`Действует до: ${x.valid}`}</label>
      </SmallCard>
    ));
  }, [certs, selectedCertificate]);

  return (
    <ModalCenter isOpen={isOpen}>
      <div className={styles.signature}>
        {signatureType ? (
          <div>
            {signatureType === 'token' ? (
              <div className={styles.signature}>
                <h2>Подписание документов</h2>
                <label>Выберите сертификат</label>
                {renderCerts}
                {certs.length ? (
                  <Check
                    onClick={() => setSaveCertificate(!saveCertificate)}
                    value={saveCertificate}
                    selected={saveCertificate}
                    label='Запомнить выбранный сертификат'
                  />
                ) : null}
                <label>Подписант</label>
                <SmallCard
                  title={participant?.name}
                  subtitle={`Должность: ${participant?.position}; Тип документа: ${participant?.document}`}
                  avatar={avatar}
                />
                <label>Документы на подпись</label>
                <SmallCard avatar={pdf} title={fileName} />
                <div className={styles.sigButtons}>
                  <Button onClick={handleSign} text='Подписать' />
                  <Button type="gray" onClick={() => setSignatureType(null)} text="Отмена" />
                </div>
              </div>
            ) : null}
            {signatureType === 'signme' ? (
              <div className={styles.signature}>
                <h2>Подписание документов</h2>
                <div className={styles.status}>
                  <label>Статус сертификата:</label>
                  <Badge type={status?.type} text={status?.name} />
                </div>
                <label>Подписант</label>
                <SmallCard
                  title={participant?.name}
                  subtitle={`Должность: ${participant?.position}; Тип документа: ${participant?.document}`}
                  avatar={avatar}
                />
                <label>Документы на подпись</label>
                <SmallCard avatar={pdf} title={fileName} />
                <div className={styles.sigButtons}>
                  <Button
                    isDisabled={status?.code !== 2}
                    text='Подписать'
                  />
                  <Button type="gray" onClick={() => setSignatureType(null)} text="Отмена" />
                </div>
              </div>
            ) : null}
          </div>
        ) : (
          <div className={styles.signature}>
            <h2>Выберите сервис для подписания</h2>
            <div className={styles.delete}>
              <Button
                large
                text="Токен"
                type="primary"
                icon={usb}
                onClick={() => {
                  setSignatureType('token');
                }}
              />
              <Button
                large
                text="SignMe"
                type="primary"
                icon={usb}
                onClick={() => {
                  setSignatureType('signme');
                }}
              />
            </div>
            <Button type="gray" onClick={() => onClose(false)} large text="Отмена" />
          </div>
        )}
      </div>
    </ModalCenter>
  );
};

export default React.memo(Signature);
