import React, { useContext, useEffect, useState } from 'react';
import cn from 'classnames';
import { CircularProgress } from '@mui/material';

import { useResidentialComplexService } from '../../../../Services/ResidentialComplexService';
import { statisticTypes } from '../../../../constants/constants';
import { HttpContext } from '../../../../HttpContext';
import TableHeader from '../../../../Components/Table/TableHeader';
import TableCell from '../../../../Components/Table/TableCell';
import TableContainer from '../../../../Components/Table/TableContainer';
import TooltipSmallText from '../../../../Components/Tooltip/TooltipSmallText';
import Badge from '../../../../Components/Badge/Badge';
import Input from '../../../../Components/Input/Input';
import ModalCenterFull from '../../../../Components/ModalCenter/ModalCenterFull';
import getStatus from '../../../../Services/PostNotifications/PostNotificationStatuses';
import TooltipText from '../../../../Components/Tooltip/TooltipText';

import styles from './statistic.module.css';
import Dropdown from '../../../../Components/Dropdown/Dropdown';

const Statistic = ({isOpen, setIsOpen, type, buildingId}) => {
  const {getResidentialBuilding, getBuildingStatus} = useResidentialComplexService();
  const { state } = useContext(HttpContext);

  const formatDate = (dateString) => {
    if (!dateString) {
      return
    }
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const formatDateTime = (dateString) => {
    if (!dateString) {
      return;
    }

    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();

    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    return `${day}-${month}-${year} ${hours}:${minutes}`;
  };

  const getBadgeStatus = {
    3: { badge: 'warning', text: "Записано на приемку" },
    4: { badge: 'info', text: "Объект принят" },
    5: { badge: 'warning', text: "Объект с замечаниями" },
    6: { badge: 'warning', text: "Акт приема-передачи загружен" },
    7: { badge: 'blue', text: "Находится на регистрации" },
    8: { badge: 'error', text: "Приостановлено" },
    9: { badge: 'error', text: "Возвращено без рассмотрения" },
    10: { badge: 'success', text: "Права зарегистрированы" },
  };

  const sendingTypes = [
    {
      value: 1,
      text: "Электронная почта",
    },
    {
      value: 2,
      text: "Электр.заказн.письмо",
    },
    {
      value: 3,
      text: "Бумажн.заказн.письмо",
    },
  ];

  const [building, setBuilding] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      try {
        if (buildingId) {
          const build = await getResidentialBuilding(buildingId);
          const apartments = await getBuildingStatus(buildingId, type, search);

          setBuilding({
            ...build,
            Apartments: apartments,
          });
        } else {
          setBuilding(null);
        }
      } catch (error) {
        console.error("Error fetching building data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [buildingId, type, search]);

  useEffect(() => {
    if (isOpen) {
     setSearch('')
    }
  }, [isOpen])

  return (
    <ModalCenterFull isOpen={isOpen} setIsOpen={setIsOpen} title={
      <div className={styles.header_title}>
        <h2>{ statisticTypes.find(item => item.type === type)?.text }</h2>
        <div className={styles.search}>
          <Input
            placeholder='Поиск по участникам'
            value={search}
            onChange={(value) => setSearch(value)}
          />
        </div>
      </div>
    }>
      <TableContainer className={styles.container}>
        <TableHeader className={styles.head}>
          <TableCell th="№"/>
          {(type === 1 || type === 3 || type === 4 || type === 5 || type === 6 || type === 10) && <TableCell th="Информация о помещении"/>}
          {type === 10 && <TableCell th="Застройщик"/>}
          <TableCell th="Участник"/>
          {(type === 1 || type === 2) && <TableCell th="Адрес отправки"/>}
          {(type === 7 || type === 8 || type === 9 || type === 10) && <TableCell th="КУВД/ВС"/>}
          {type === 2 && <TableCell th="Тип отправки"/>}
          {type === 2 && <TableCell th="Трек номер"/>}
          {(type === 1 || type === 2 || type === 3 || type === 4 || type === 5 || type === 6) && <TableCell th="Статус"/>}
          {(type === 7 || type === 8 || type === 9) && <TableCell th="История статусов Росреестра"/>}
          {type === 10 && <TableCell th="Выписка из ЕГРН"/>}
        </TableHeader>
        {!isLoading &&
          <tbody>
          {building?.Apartments?.length > 0 && building.Apartments.map((item, index) =>
            <tr
              key={item?.Id}
              onClick={(e) => {
                if (type !== 2) {
                  e.preventDefault();
                  window.open(`/building/edit/${building?.ResidentialComplexId}?buildingId=${building?.Id}&apartment=${item?.Id}`, '_blank');
                } else {
                  window.open(`/building/edit/${building?.ResidentialComplexId}?buildingId=${building?.Id}&apartment=${item?.Id}&ownerId=${item?.Owners[0]?.Id}`, '_blank');
                }
              }}
              className={styles.link}
            >
              <TableCell
                title={item?.Number}
                subtitle={item?.ApartmentType === "Квартира"
                  ?
                  <span>под.{item?.Entrance}, эт.{item?.Floor}</span>
                  :
                  <span>{item?.ObjType}</span>
                }
              />
              {(type === 1 || type === 3 || type === 4 || type === 5 || type === 6 || type === 10) &&
                <TableCell
                  title={<span>{item?.ApartmentType}</span>}
                  subtitle={
                    <div className={styles.column}>
                      <span>{item?.CadNumber} ({item?.Area} кв.м)</span>
                    </div>
                  }
                />
              }
              {type === 10 &&
                <TableCell
                  th={item?.Owners?.length > 0 ?
                    <span>-</span>
                    :
                    <TooltipText text={state?.builder} maxLength='20'/>
                  }
                />
              }
              <TableCell th={item?.Owners?.length > 0 ?
                <div className={styles.column}>
                  {item?.Owners.map((owner, index) =>
                    <span key={index}>
                      {owner?.Name}
                    </span>
                  )
                  }
                </div>
                :
                <span>-</span>
              }/>
              {(type === 1 || type === 2) &&
                <TableCell th={item?.Owners?.length > 0 ?
                  <div className={styles.column}>
                    {item?.Owners.map((owner, index) =>
                      <TooltipSmallText key={index} text={owner?.PostalAdress ? owner?.PostalAdress : '-'} maxLength={type === 1 ? '20' : '30'}/>
                    )}
                  </div>
                  :
                  <span>-</span>
                }/>
              }
              {(type === 7 || type === 8 || type === 9 || type === 10) &&
                <TableCell th={
                  <div className={cn(styles.column, styles.kuvd)}>
                    {item?.Kuvd ?
                      <span>{item?.Kuvd}</span>
                      :
                      <span>-</span>
                    }
                    {item?.BC ?
                      <span>{item?.BC}</span>
                      :
                      <span>-</span>
                    }
                  </div>

                }/>
              }
              {type === 2 &&
                <TableCell th={item?.Owners?.length > 0 ?
                  <div className={styles.column}>
                    {item?.Owners.map((owner, index) =>
                      <span key={index}>
                        {owner?.SendingType && owner?.IsSent
                          ? sendingTypes.find(type => type.value === owner.SendingType)?.text || "-"
                          : "-"
                        }
                      </span>
                    )}
                  </div>
                  :
                  <span>-</span>
                }/>
              }
              {type === 2 &&
                <TableCell th={
                  item?.Owners?.length > 0 ? (
                    <div className={styles.column}>
                      {item?.Owners.map((owner) => (
                        owner?.MailId ?
                          <span key={owner.Id}>
                            <a
                              className={styles.track}
                              target='_blank'
                              rel="noopener noreferrer"
                              href={`https://www.pochta.ru/tracking?barcode=${owner?.MailId}`}
                              onClick={(e) => {
                                e.stopPropagation();
                              }}
                            >
                              {owner?.MailId}
                            </a>
                          </span>
                          :
                          <span>-</span>
                      ))}
                    </div>
                  ) : (
                    <span>-</span>
                  )
                }/>
              }
              {(type === 1 || type === 3 || type === 4 || type === 5 || type === 6) &&
                <TableCell th={item?.ApartmentStatus === 2 ?
                  <div className={styles.column}>
                    {item?.Owners.map((owner, index) =>
                      <Badge key={index} type={ getStatus(owner?.Status).badgeType } text={getStatus(owner?.Status).text}/>
                    )}
                  </div>
                  :
                  item?.ApartmentStatus === 0 ?
                    <Badge type={item?.Owners.length === 0 ? 'primary' : 'info'} text={item?.Owners.length === 0 ? 'Готово к регистрации' : 'Уведомление не направлялось'}/>
                    :
                  <Badge type={getBadgeStatus[item?.ApartmentStatus]?.badge} text={getBadgeStatus[item?.ApartmentStatus]?.text}/>
                }/>
              }
              {type === 2 &&
                <TableCell th={item?.Owners?.length > 0 &&
                  <div className={styles.column}>
                    {item?.Owners.map((owner, index) =>
                      <Badge key={index} type={ getStatus(owner?.Status).badgeType } text={getStatus(owner?.Status).text}/>
                    )}
                  </div>
                }/>
              }
              {(type === 7 || type === 8 || type === 9) &&
                <TableCell th={
                  <div onClick={(e) => e.stopPropagation()} className={styles.column}>
                    {item?.RequestStatuses?.length > 0
                      ?
                      <Dropdown reverse title={item?.RequestStatuses[0]?.Description}>
                        {[...item?.RequestStatuses]?.map((item) =>
                          <div className={styles.status}>
                            <span>{item?.Description}</span>
                            <span>{formatDateTime(item?.DateTime)}</span>
                          </div>
                        )}
                      </Dropdown>
                      :
                      <span>-</span>
                    }
                  </div>
                }/>
              }
              {type === 10 &&
                <TableCell th={
                  <div className={styles.column}>
                    {item?.EgrnLink
                      ?
                      <a
                        className={cn(styles.track, styles.link_button)}
                        onClick={(e) => e.stopPropagation()}
                        href={item?.EgrnLink}
                        target='_blank'
                      >
                        Скачать
                      </a>
                      :
                      <span>-</span>
                    }
                    {item?.RegistryDate
                      ?
                      <span>{formatDate(item?.RegistryDate)}</span>
                      :
                      <span>-</span>
                    }
                  </div>
                }/>
              }
            </tr>
          )}
          </tbody>
        }
      </TableContainer>
      {isLoading &&
        <div className={styles.circular}>
          <CircularProgress color="inherit"/>
        </div>
      }
    </ModalCenterFull>
  );
};

export default Statistic;