import React from 'react';

import { CheckCircle } from '../../Components/Icon/Icon';

import styles from './received.module.css';

const Received = () => {
  return (
    <div className={styles.container}>
      <div className={styles.card}>
        <h1 className={styles.title}>Спасибо!</h1>
        <p className={styles.text}>Ваши данные успешно получены.</p>
        <CheckCircle className={styles.icon} />
      </div>
    </div>
  );
};

export default Received;
