import React from 'react';
import styles from './loader.module.css';

export default function DotLoader({label}) {
  return (
    <div className={styles.loader}>
      {label && <p>{label}</p>}
      <span className={styles.dot}/>
      <span className={styles.dot}/>
      <span className={styles.dot}/>
    </div>
  );
}
