import React, { useEffect, useState } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers';
import 'moment/locale/ru';
import moment from 'moment';
import { Skeleton } from '@mui/material';

import style from './datepicker.module.css';
import styles from '../Input/input.module.css';


moment.locale('ru');

export function Datepicker({label, disabled, onChange, error, value, max, isError, skeleton}) {
  const initialDate = moment(value, moment.ISO_8601, true).isValid() ? moment(value) : moment();

  const [selectedDate, setSelectedDate] = useState(initialDate);
  const [focused, setFocused] = useState(false);

  const handleFocus = () => {
    setFocused(true);
  };

  const handleBlur = () => {
    setFocused(false);
  };

  const handleDateChange = (newDate) => {
    setSelectedDate(newDate);
    onChange(newDate);
  };

  useEffect(() => {
    if (value) {
      setSelectedDate(initialDate)
    } else {
      setSelectedDate(null)
    }
  }, [value])

  return (
    <LocalizationProvider dateAdapter={AdapterMoment} locale={moment.locale()}>
      <div className={style.container}>
        {label && (skeleton ? <Skeleton variant='rounded' width='100px' height='17px'/> : <label>{label}</label>)}
        {skeleton ?
          <Skeleton variant='rounded' width='100%' height='40.5px'/>
          :
          <DatePicker
            className={`${style.container} ${disabled ? style.disabled : ''}`}
            disabled={disabled}
            value={selectedDate}
            maxDate={max}
            onChange={handleDateChange}
            slotProps={{
              textField: {
                placeholder: 'дд.мм.гггг',
                onFocus: handleFocus,
                onBlur: handleBlur,
                sx: {
                  backgroundColor: 'white',
                  width: '100%',
                  borderRadius: '8px',
                  '& .MuiInputBase-input': {
                    color: '#000',
                    padding: '8px',
                  },
                  '& .MuiOutlinedInput-root': {
                    outline: isError && focused ? '4px solid #d956563b' : focused ? '4px solid #7f56d93b' : '4px solid transparent',
                    border: disabled ? '1px solid #f9f9f9' : isError ? '1px solid #d95656aa' : focused ? '1px solid #7f56d9aa' : '1px solid #D0D5DD',
                    backgroundColor: isError ? '#d956563b' : 'transparent',
                    borderRadius: '8px',
                    transition: 'border 500ms, outline 500ms, backgroundColor 500ms',
                    '& fieldset': {
                      border: 'none',
                    },
                    '&:hover fieldset': {
                      border: 'none'
                    },
                    '&.Mui-focused fieldset': {
                      border: 'none',
                    },
                    '& input:disabled': {
                      '-webkit-text-fill-color': '#000',
                    },
                  },
                },
              },
            }}
          />
        }
        {isError && error && <span className={styles.error_text}>{error}</span>}
      </div>
    </LocalizationProvider>
  );
}
