import React, { useContext, useEffect, useState } from "react";

import Input from "../../../Components/Input/Input";
import Select from "../../../Components/Select/Select";
import Modal from "../../../Components/Modal/Modal";
import { HttpContext } from "../../../HttpContext";
import { useAddressService } from "../../../Services/AddressService";
import AddressInput from "../../../Components/Input/AddressInput";

export default function ObjectModal({ request, isOpen, setRequest, setOpen, objectTypes }) {
  const { httpClient } = useContext(HttpContext);
  const [complexList, setComplexList] = useState([]);
  const [addresses, setAddresses] = useState([]);
  const { getAddress } = useAddressService();


  const updateObject = async () => {
    const body = {
      Id: request.Id,
      RegActionId: request.RegAction.code,
      ObjectTypeId: parseInt(request.Object.Type.code),
      ObjectNote: request.Note,
      AreaContract: parseInt(request.Object.DduAreaContract),
      CadNumber: request.Object.CadNum,
      Address: request.Object.Address.Note,
      Note: request.Note,
    }
    if (request?.Object.Type.code == 9) {
      body.IsFarming = request.Object.Farming
      body.IsPrivateFarming = request.Object.PrivateFarming

    }
    if (request.RegAction.code === 1) {
      body.ComplexId = parseInt(request.Object.Complex.code)
      body.HouseId = parseInt(request.Object.House.code)
    }
    if (request.RegAction.code === 4) {
      body.RightTypeId = request.RightType.code

    }
    if (request.RegAction.code === 5) {
      body.Address = request.Object.Address
      body.RightTypeId = request.RightType.code
    }
    await httpClient.put(`/request`, body);
  }

  const getComplex = async () => {
    await httpClient.get("/complex").then(res => setComplexList(res.data.Data))
  }


  useEffect(() => {
    getComplex()
  }, [])

  return (
    <Modal
      onSave={() => request?.Id ? updateObject() : ""}
      title="Добавление объекта недвижимости"
      isOpen={isOpen}
      setOpen={setOpen}
    >
      <Select
        value={request?.Object?.Type?.code}
        placeholder="Выберите вид объекта"
        onChange={(value) => setRequest({
          ...request,
          Object: {
            ...request?.Object,
            Type: objectTypes.find(x => x.code == value)
          }
        })}
        label="Вид объекта"
      >
        {objectTypes.map((x) =>
          <option value={x.code}>{x.name}</option>
        )}
      </Select>
      {request?.RegAction.code != 5 &&
        <React.Fragment>
          <Select
            label="Комплекс"
            placeholder="Выберите комплекс"
            value={request?.Object?.Complex?.code}
            onChange={(value) => setRequest({
              ...request,
              Object: {
                ...request.Object,
                Complex: {
                  ...request.Object.Complex,
                  code: value
                }
              }
            })}
          >
            {complexList.map((x) =>
              <option value={x.Id}>{x.Name}</option>
            )}
          </Select>
          <Select
            value={request?.Object?.House?.code}
            onChange={(value) => setRequest({
              ...request,
              House: {
                ...request.Object.House,
                code: value
              }
            })}
            label="Дом"
            placeholder="Выберите дом в комплексе"
          >
            {complexList.filter(x => x.Id == request?.Object?.Complex?.code)
              .flatMap(x => x.House)
              .map((x) =>
                <option value={x.Id}>{x.Number}</option>
              )}
          </Select>
        </React.Fragment>
      }


      {
        request?.RegAction.code === 1 &&
        <React.Fragment>
          <Input
            value={request?.DduAreaContract?.toString()}
            onChange={(value) => setRequest({
              ...request,
              DduAreaContract: value
            })}
            label="Площадь объекта по договору, м2"
            mask={Number}
          />
        </React.Fragment>
      }

      {
        request?.RegAction.code === 5 &&
        <React.Fragment>
          <Input
            value={request?.Object?.CadNum}
            onChange={(value) => setRequest({
              ...request,
              Object: {
                ...request.Object,
                CadNum: value
              }
            })}
            label="Кадастровый номер"
            mask="00:00:000000:000000000"
            placeholder="__:__:______:_"
          />
          {/* <Input
            options={addresses}
            value={request?.Object?.Address?.Note}
            onChange={(value) => {
              setRequest({
                ...request,
                Object: {
                  ...request?.Object,
                  Address: {
                    ...request.Object?.Address,
                    Note: value
                  }
                }
              });

              getAddress(value).then(response => setAddresses(response))
            }}
            onSelect={(value) => {
              setRequest({
                ...request,
                Object: {
                  ...request.Object,
                  Address: value
                }
              });
            }}
            name="address"
            label="Адрес"
            placeholder="Планерная 10, кв 254"
          /> */}
          <AddressInput value={request?.Object?.Address}setValue={(value)=>setRequest({
                ...request,
                Object: {
                  ...request?.Object,
                  Address: value
                }
              })}></AddressInput>
        </React.Fragment>
      }
      {request?.Object?.Type?.code == 9 &&
        <React.Fragment>
          <Select value={request.Object.Farming} onChange={(value) => setRequest({ ...request, Object: { ...request.Object, Farming: value } })} label="Объект относится к землям сельскохозяйственного назначения">
            <option value={false}>Нет</option>
            <option value={true}>Да</option>
          </Select>
          <Select value={request.Object.PrivateFarming} onChange={(value) => setRequest({ ...request, Object: { ...request.Object, PrivateFarming: value } })} label="Объект является земельным участком, предназначенным для ведения личного подсобного, дачного хозяйства, огородничества, садоводства, индивидуального гаражного или индивидуального жилищного строительства или расположен на таком участке">
            <option value={false}>Нет</option>
            <option value={true}>Да</option>
          </Select>
        </React.Fragment>
      }
      <Input
        value={request?.Note}
        onChange={(value) => setRequest({
          ...request,
          Note: value
        })}
        label="Описание объекта"
      />
    </Modal >
  )
}