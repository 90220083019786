import React, { useContext, useEffect, useState, useCallback, useMemo } from 'react';
import ccpa from 'crypto-pro-cadesplugin';

import usb from '../../../icons/usb.svg';
import check from '../../../images/Check.svg';
import checkEmpty from '../../../images/Check_Empty.svg';
import pdf from '../../../icons/PdfIcon.svg';
import avatar from '../../../images/Avatar_2.svg';
import styles from '../../../Pages/Building/Homes/Modal/homes.modal.module.css';
import useFileService from '../../../Services/FileService';
import { HttpContext } from '../../../HttpContext';
import { useSignmeService } from '../../../Services/SignMe/SignmeService';
import getCertsList from './getCertsList';
import SmallCard from '../../../Components/SmallCard/SmallCard';
import ModalCenter from '../../../Components/ModalCenter/ModalCenter';
import Check from '../../../Components/Check/Check';
import Button from '../../../Components/Button/Button';
import Badge from '../../../Components/Badge/Badge';
import { useUlService } from '../../../Services/UlSubjectService';
import useRequestContext from '../useRequestContext';

const SignatureModalCenter = ({ isOpen, onClose, documents, participants, setParticipants, onChange, addSignature }) => {
  const { downloadFile } = useFileService()
  const { getUlSubjects } = useUlService();
  const { request, getRequest } = useRequestContext()
  const { state } = useContext(HttpContext);
  const { participant } = state;

  const { status } = useSignmeService(participant?.id);

  const [selectedCertificate, setSelectedCertificate] = useState(null);
  const [certs, setCerts] = useState([]);
  const [signatureType, setSignatureType] = useState(null);
  const [saveCertificate, setSaveCertificate] = useState(false);
  const [selectedDocuments, setSelectedDocuments] = useState([]);
  const [selectedParticipant, setSelectedParticipant] = useState(null);
  const [ulSubjects, setUlSubjects] = useState([]);

  useEffect(() => {
    getUlSubjects().then(result => setUlSubjects(result))
  }, [])

  const saveToLocalStorage = (key, value) => {
    localStorage.setItem(key, value);
  };

  const removeFromLocalStorage = (key) => {
    localStorage.removeItem(key);
  };

  const getFromLocalStorage = (key) => {
    return localStorage.getItem(key);
  };

  useEffect(() => {
    const storedValue = getFromLocalStorage('signature');
    if (storedValue) {
      setSelectedCertificate({
        thumbprint: storedValue
      });
      setSaveCertificate(true);
    } else {
      setSelectedCertificate({
        thumbprint: null
      });
      setSaveCertificate(false);
    }
  }, []);

  const fetchCerts = useCallback(async () => {
    const certsList = await getCertsList();
    const formattedCerts = certsList.map((cert) => {
      const subjectInfo = cert.subjectInfo;
      let info = cert.friendlyIssuerInfo()[0].text;
      const valid = cert.friendlyValidPeriod().to.ddmmyy;

      if (info.startsWith('"') && info.endsWith('"')) {
        info = info.slice(1, -1);
      }

      let subject = '';
      const organizationMatch = subjectInfo.match(/O=([^,]+)/);
      if (organizationMatch) {
        subject = organizationMatch[1];
      } else {
        const cnMatch = subjectInfo.match(/CN=([^,]+)/);
        subject = cnMatch ? cnMatch[1] : subjectInfo;
      }

      return { ...cert, subject, valid, info };
    });
    setCerts(formattedCerts);
  }, []);

  useEffect(() => {
    if (isOpen && signatureType === 'token') {
      fetchCerts();
    } else {
      setSignatureType(signatureType ? signatureType : null);
    }
  }, [isOpen, signatureType, fetchCerts]);

  useEffect(() => {
    if (isOpen) {
      const autoSelectedDocuments = [
        ...request?.Documents?.filter((document) =>
          document?.Category?.code !== '3' &&
          (document?.Signatures?.length === 0 || !document?.Signatures?.some(signature => signature?.Id))
        ),
        ...request?.Statements?.filter((statement) =>
          statement?.Category?.code !== '3' &&
          (statement?.Signatures?.length === 0 || !statement?.Signatures?.some(signature => signature?.Id))
        )
      ];

      setSelectedDocuments(autoSelectedDocuments);
    }
  }, [isOpen, request?.Documents, request?.Statements]);


  const uniqueParticipants = [];
  const ulAgentMap = new Set();

  participants?.forEach(x => {
    const ulAgentId = x?.UlAgentFlSubject?.UlAgentId;

    if (x?.UlAgentFlSubject && !ulAgentMap.has(ulAgentId)) {
      ulAgentMap.add(ulAgentId);
      uniqueParticipants.push(x);
    } else if (!x?.UlAgentFlSubject) {
      uniqueParticipants.push(x);
    }
  });

  const getUlAgentName = (ulId, agentId) => {
    return (ulSubjects?.find(subject => subject.Id === ulId)?.Agents?.find((agent) => agent?.Id === agentId))?.FlSubjectFIO
  }

  const toggleDocumentSelection = (document) => {
    if (selectedDocuments?.some(selected => selected?.FileName === document?.FileName)) {
      setSelectedDocuments(selectedDocuments?.filter(selected => selected?.FileName !== document?.FileName));
    } else {
      setSelectedDocuments([...selectedDocuments, document]);
    }
  };

  const selectParticipant = (participantId) => {
    const newState = participants.map((participant) => {
      if (participant.Id === participantId) {
        return { ...participant, isActive: !participant.isActive }
      }
      return participant
    })
    setParticipants(newState)
  }

  const handleSign = async () => {
    const signPromises = selectedDocuments.map((document) =>
      downloadFile(document?.FileUuid).then(async (base64) => {
        const signed = await ccpa().then(x => {
          return x.signBase64(selectedCertificate.thumbprint, base64);
        });

        const relevantParticipants = request?.Participants
          ? request.Participants.filter(participant =>
            participant?.Documents?.some(doc => doc.Id === document.Id)
          )
          : [];

        const participantPromises = relevantParticipants.map(participant => {
          return addSignature(document.Id, participant.Id, null, signed, 2).then(() => getRequest(request.Id));
        });

        await Promise.all(participantPromises);
      })
    );

    await Promise.all(signPromises);

    if (saveCertificate) {
      saveToLocalStorage('signature', selectedCertificate?.thumbprint);
    } else {
      removeFromLocalStorage('signature');
    }

    onClose();
  };

  useEffect(() => {
    if (isOpen) {
      const autoSelectedParticipant = uniqueParticipants?.find((participant) => {
        const subject = participant?.FlSubject || participant?.UlAgent || participant?.UlAgentFlSubject;

        return participant?.UlAgentFlSubject
          ? getUlAgentName(participant?.UlAgentFlSubject?.UlSubjectId, participant?.UlAgentFlSubject?.UlAgentId)
          : `${subject?.Lastname} ${subject?.Firstname} ${subject?.Middlename}`;
      });

      setSelectedParticipant(autoSelectedParticipant || null);
    }
  }, [isOpen, uniqueParticipants, ulSubjects]);

  const renderCerts = useMemo(() => {
    return certs.map((x) => (
      <SmallCard
        key={x.thumbprint}
        avatar={selectedCertificate.thumbprint === x.thumbprint ? check : checkEmpty}
        type={selectedCertificate.thumbprint === x.thumbprint ? "primary" : "default"}
        onClick={() => setSelectedCertificate(x)}
        title={x.info}
      >
        <label className={styles.signature_text}>{x.subject}</label>
        <label className={styles.signature_text}>{`Действует до: ${x.valid}`}</label>
      </SmallCard>
    ));
  }, [certs, selectedCertificate]);

  return (
    <ModalCenter isOpen={isOpen}>
      <div className={styles.signature}>
        {signatureType ? (
          <div>
            {signatureType === 'token' ? (
              <div className={styles.signature}>
                <h2>Подписание документов</h2>
                <label>Подписант</label>
                {uniqueParticipants?.map((x) => {
                  const subject = x?.FlSubject ? x?.FlSubject : x?.UlAgent ? x?.UlAgent : x?.UlAgentFlSubject

                  const title = x?.UlAgentFlSubject
                    ? getUlAgentName(x?.UlAgentFlSubject?.UlSubjectId, x?.UlAgentFlSubject?.UlAgentId)
                    : `${subject?.Lastname} ${subject?.Firstname} ${subject?.Middlename}`;

                  return (
                    <SmallCard
                      title={title}
                      onClick={() => setSelectedParticipant(!selectedParticipant ? x : null)}
                      subtitle={x?.UlAgentFlSubject ? ulSubjects.find(subject => subject.Id === x?.UlAgentFlSubject?.UlSubjectId)?.Name : null}
                      avatar={x.Id === selectedParticipant?.Id ? check : checkEmpty}
                      type={x.Id === selectedParticipant?.Id ? "primary" : "default"}
                    />
                  )}
                )}
                <label>Документы на подпись</label>
                {request?.Documents?.map((document) =>
                  document?.Category?.code !== '3' ? (
                    document?.Signatures?.length === 0 || !document?.Signatures.some(signature => signature?.Id) ? (
                      <SmallCard
                        title={document?.FileName}
                        key={document.FileName}
                        onClick={() => toggleDocumentSelection(document)}
                        avatar={selectedDocuments?.some(selected => selected.FileName === document.FileName) ? check : checkEmpty}
                        type={selectedDocuments?.some(selected => selected.FileName === document.FileName) ? "primary" : "default"}
                        subtitle={document?.Type?.name}
                      />
                    ) : (
                      <SmallCard
                        title={document?.FileName}
                        key={document.FileName}
                        badge={<Badge margin text={'• Подписано'} type={'success'}/>}
                        avatar={pdf}
                        subtitle={document?.Type?.name}
                      />
                    )
                  ) : null
                )}
                {request?.Statements?.map((document) =>
                  document?.Category?.code !== '3' ? (
                    document?.Signatures?.length === 0 || !document?.Signatures.some(signature => signature?.Id) ? (
                      <SmallCard
                        title={document?.FileName}
                        key={document.FileName}
                        onClick={() => toggleDocumentSelection(document)}
                        avatar={selectedDocuments?.some(selected => selected.FileName === document.FileName) ? check : checkEmpty}
                        type={selectedDocuments?.some(selected => selected.FileName === document.FileName) ? "primary" : "default"}
                        subtitle={document?.Type?.name}
                      />
                    ) : (
                      <SmallCard
                        title={document?.FileName}
                        key={document.FileName}
                        badge={<Badge margin text={'• Подписано'} type={'success'}/>}
                        avatar={pdf}
                        subtitle={document?.Type?.name}
                      />
                    )
                  ) : null
                )}
                <label>Выберите сертификат</label>
                {renderCerts}
                {certs.length ? (
                  <Check
                    onClick={() => setSaveCertificate(!saveCertificate)}
                    value={saveCertificate}
                    selected={saveCertificate}
                    label='Запомнить выбранный сертификат'
                  />
                ) : null}
                <div className={styles.sigButtons}>
                  <Button
                    isDisabled={selectedDocuments?.length === 0}
                    onClick={handleSign}
                    text='Подписать'
                  />
                  <Button type="gray" onClick={() => setSignatureType(null)} text="Отмена" />
                </div>
              </div>
            ) : null}
            {signatureType === 'signme' ? (
              <div className={styles.signature}>
                <h2>Подписание документов</h2>
                <div className={styles.status}>
                  <label>Статус сертификата:</label>
                  <Badge type={status?.type} text={status?.name} />
                </div>
                <label>Подписант</label>
                <SmallCard
                  title={participant?.name}
                  subtitle={`Должность: ${participant?.position}; Тип документа: ${participant?.document}`}
                  avatar={avatar}
                />
                <label>Документы на подпись</label>
                {request?.Documents?.map((document) =>
                  <SmallCard avatar={pdf} title={document?.FileName} />
                )}
                <div className={styles.sigButtons}>
                  <Button
                    isDisabled={status?.code !== 2}
                    text='Подписать'
                  />
                  <Button type="gray" onClick={() => setSignatureType(null)} text="Отмена" />
                </div>
              </div>
            ) : null}
          </div>
        ) : (
          <div className={styles.signature}>
            <h2>Выберите сервис для подписания</h2>
            <div className={styles.delete}>
              <Button
                large
                text="Токен"
                type="primary"
                icon={usb}
                onClick={() => {
                  setSignatureType('token');
                }}
              />
              <Button
                large
                text="SignMe"
                type="primary"
                icon={usb}
                onClick={() => {
                  setSignatureType('signme');
                }}
              />
            </div>
            <Button type="gray" onClick={() => onClose(false)} large text="Отмена" />
          </div>
        )}
      </div>
    </ModalCenter>
  );
};

export default React.memo(SignatureModalCenter);
