import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import * as yup from 'yup';
import moment from 'moment';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';

import CardContainer from '../../../Components/Card/CardContainer';
import CardHeader from '../../../Components/Card/CardHeader';
import CardBody from '../../../Components/Card/CardBody';
import Input from '../../../Components/Input/Input';
import Button from '../../../Components/Button/Button';
import HomesBoard from './Board/HomesBoard';
import FileInput from '../../../Components/FileInput/FileInput';
import { mimeTypes } from '../../../constants/constants';
import Check from '../../../Components/Check/Check';
import Menu from '../../../Components/Menu/Menu';
import SmallCard from '../../../Components/SmallCard/SmallCard';
import { useResidentialComplexService } from '../../../Services/ResidentialComplexService';
import AddressInput from '../../../Components/Input/AddressInput';
import { ChevronDown } from '../../../Components/Icon/Icon';
import useFileService from '../../../Services/FileService';
import Signature from './Modal/Signature/Signature';
import { error } from '../../../constants/constants';
import { Datepicker } from '../../../Components/Datepicker/Datepicker';


import chevron from '../../../icons/chevron.svg';
import styles from './homes.module.css';
import pdf from '../../../icons/PdfIcon.svg';
import FileButton from '../../../Components/Button/FileButton';

const Homes = ({ home, build, complexId, setComplex, setShowTemplate }) => {
  const {
    createResidentialBuilding,
    updateResidentialBuilding,
    getResidentialComplex,
    uploadResidentialComplexFile,
    getBuildingStatistics,
    deleteResidentialBuilding,
    getResidentialBuilding,
    uploadZip
  } = useResidentialComplexService();
  const {downloadStorageFile, uploadFile, uploadSignature} = useFileService();

  const [isOpen, setIsOpen] = useState(true);
  const [isError, setIsError] = useState(true);
  const [isSave, setIsSave] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [zipLoading, setZipLoading] = useState(false);
  const [notice, setNotice] = useState(false);
  const [sendNotice, setSendNotice] = useState(false);
  const [sendTime, setSendTime] = useState(null);
  const [signature, setSignature] = useState(false);
  const [buildingStatistics, setBuildingStatistics] = useState(null);
  const [openSignatureModal, setOpenSignatureModal] = useState(false);
  const [complexIsLoading, setComplexIsLoading] = useState(false);
  const [excelFileError, setExcelFileError] = useState(false);
  const [buildingId, setBuildingId] = useState(null);

  const [building, setBuilding] = useState({
    Number: home,
    CadNumber: null,
    Address: null,
    Area: null,
    PermitNumber: null,
    PermitDate: null,
    CadNumberDate: null
  });

  const [buildingErrors, setBuildingErrors] = useState({
    CadNumber: '',
    Address: '',
    Area: '',
    PermitNumber: '',
    PermitDate: '',
    CadNumberDate: ''
  })

  const schema = yup.object({
    CadNumber: yup.string()
      .required(error.Required)
      .matches(/^[0-9:]+$/, error.CadNumber),
    Address: yup.string()
      .required(error.Required),
    Area: yup.string()
      .required(error.Required)
      .matches(/^\d+([.,]\d+)?$/, error.Area),
    PermitNumber: yup.string()
      .required(error.Required),
    PermitDate: yup.date()
      .typeError(error.PermitDate)
      .required(error.Required)
      .min(new Date(1970, 0, 1), error.PermitDate)
      .max(new Date(), error.PermitDate),
    CadNumberDate: yup.date()
      .typeError(error.CadNumberDate)
      .required(error.Required)
      .min(new Date(1970, 0, 1), error.CadNumberDate)
      .max(new Date(), error.CadNumberDate)
  });

  const validateField = async (fieldName, value) => {
    try {
      await schema.fields[fieldName].validate(value);
      setBuildingErrors({
        ...buildingErrors,
        [fieldName]: ''
      })
    } catch (error) {
      setBuildingErrors({
        ...buildingErrors,
        [fieldName]: error.message
      })
    }
  };

  const handleSubmit = async () => {
    const validationPromises = Object.keys(buildingErrors).map(async (field) => {
      try {
        await schema.fields[field].validate(building[field]);
        setBuildingErrors((prevErrors) => ({
          ...prevErrors,
          [field]: ''
        }));
      } catch (validationError) {
        setBuildingErrors((prevErrors) => ({
          ...prevErrors,
          [field]: validationError.message
        }));
        throw validationError;
      }
    });

    return Promise.all(validationPromises);
  };

  const handleChange = (value, name) => {
    validateField(name, name === 'Address' ? value.Note : value);
    setBuilding({
      ...building,
      [name]: name === 'Address' ? value.Note : value,
    });
  };

  useEffect(() => {
    if (Object.values(buildingErrors).every((error) => error === '')) {
      setIsError(false)
    } else {
      setIsError(true)
    }
  }, [buildingErrors])

  const formattedDate = (date) => {
    if (!date) return '';
    const [day, month, year] = date.split('.');

    return `${year}-${month}-${day}`
  };

  const deleteHandler = () => {
    deleteResidentialBuilding(build.Id).then(() => {
      getResidentialComplex(complexId).then(res => {
        setComplex(res.data)
      })
    })
  };

  const buildButtonHandler = (number) => {
    handleSubmit().then(() => {
      if (build) {
        setIsLoading(true)
        updateResidentialBuilding({ ...building, Number: number ? number : building?.Number, PermitDate: new Date(building?.PermitDate).toISOString(), CadNumberDate: new Date(building?.CadNumberDate).toISOString(),  Apartments: [] })
          .then((updateBuilding) => {
            getResidentialComplex(complexId).then((complex) => {
              setComplex(complex.data)
              if (setShowTemplate) {
                setShowTemplate(false)
              }
            }).then(() => setBuilding({ ...updateBuilding, PermitDate: formattedDate(updateBuilding.PermitDate), CadNumberDate: updateBuilding.CadNumberDate }))
          }).then(() => {
          setIsSave(!isSave);
          setIsLoading(false);
        })
      } else {
        setIsLoading(true);
        createResidentialBuilding({ ...building, ResidentialComplexId: complexId, PermitDate: new Date(building?.PermitDate).toISOString(), CadNumberDate: new Date(building?.CadNumberDate) })
          .then((createBuilding) => {
            getResidentialComplex(complexId).then((complex) => {
              setComplex(complex.data)
              if (setShowTemplate) {
                setShowTemplate(false)
              }
            }).then(() => setBuilding({ ...createBuilding, PermitDate: formattedDate(createBuilding.PermitDate), CadNumberDate: createBuilding.CadNumberDate }))
          }).then(() => {
          setIsSave(!isSave);
          setIsLoading(false);
        })
      }
    }).catch(() => {
      setIsSave(false);
      setIsLoading(false);
    })
  }

  useEffect(() => {
    if (build) {
      setIsLoading(true);

      getResidentialBuilding(build?.Id).then(res => {
        const statusCounts = res?.Apartments?.reduce((acc, apartment) => {
          const status = apartment?.ApartmentStatus;
          if (status !== undefined) {
            acc[status] = (acc[status] || 0) + 1;
          }
          return acc;
        }, {});

        setBuilding({
          ...res,
          PermitDate: formattedDate(res.PermitDate),
          CadNumberDate: res.CadNumberDate
        });

        getBuildingStatistics(build?.Id).then(statistics => {
          setBuildingStatistics({
            ...statistics,
            StatusCounts: statusCounts
          });
        });

      }).finally(() => setIsLoading(false));

      setIsSave(true);
    }
  }, [build]);


  const success = buildingStatistics?.UDSObjectCount
  const handleSendNotice = () => {
    setSendTime(new Date());
    setSendNotice(!sendNotice);
  };

  useEffect(() => {
    if (building?.PermitFileName && building?.PermitStorageSig?.SignName) {
      setSignature(true)
    }
  }, [building?.PermitFileName])

  const changeSignature = (_, sig) => {
    const formData = new FormData();
    formData.append('File', sig);
    formData.append('SourceFileId', building?.PermitFileId);
    uploadSignature(formData).then(id => {
      setBuilding({
        ...building,
        PermitStorageSigId: id,
        PermitStorageSig: {
          SignName: `${building?.PermitFileName}.sig`
        }
      })
      setSignature(true)
    }).then(() => {
      setOpenSignatureModal(false);
    })
  }

  useEffect(() => {
    if (building?.Id) {
      setBuildingId(building?.Id)
    } else {
      setBuildingId(null)
    }
  }, [building?.Id])

  return (
    <div>
      <CardContainer>
        <CardHeader
          menu={
            <Menu>
              <Button
                large
                isDisabled={!isSave}
                onClick={() => setIsSave(!isSave)}
                type='default'
                text='Редактировать'
              />
              <Button
                large
                type='error'
                text='Удалить'
                onClick={deleteHandler}
              />
            </Menu>
          }
          chevron={
            <div className={styles.chevron}>
              <ChevronDown className={cn(styles.up, { [styles.down]: isOpen })} src={chevron}/>
              <ChevronDown className={cn(styles.up, { [styles.down]: !isOpen })} src={chevron}/>
            </div>
          }
          title={building?.Number}
          onChangeTitle={build ? (value) => {
            setBuilding({
              ...building,
              Number: value
            })
            buildButtonHandler(value)
          } : null}
        />
        <div className={cn(styles.close, {[styles.open]: isOpen})}>
          <CardBody>
            <div className={styles.wrapper}>
              <div className={styles.item}>
                <Input
                  label="Кадастровый номер дома"
                  disabled={isSave}
                  onChange={(value) => handleChange(value, 'CadNumber')}
                  placeholder='Введите кадастровый номер дома'
                  value={building?.CadNumber}
                  mask="00:00:000000:000000000"
                  isError={buildingErrors.CadNumber}
                  error={buildingErrors.CadNumber}
                  skeleton={isLoading}
                />
              </div>
              <div className={styles.item}>
                <Datepicker
                  disabled={isSave}
                  label='Дата присвоения'
                  onChange={(value) => {
                    const parsedDate = moment(value).format('YYYY-MM-DD');
                    handleChange(parsedDate, 'CadNumberDate');
                  }}
                  max={moment()}
                  value={building?.CadNumberDate ? building?.CadNumberDate : null}
                  isError={buildingErrors.CadNumberDate}
                  error={buildingErrors.CadNumberDate}
                  skeleton={isLoading}
                />
              </div>
              <div className={styles.item}>
                <AddressInput
                  disabled={isSave}
                  value={building?.Address}
                  setValue={(value) => handleChange(value, 'Address')}
                  error={buildingErrors.Address}
                  skeleton={isLoading}
                />
              </div>
              <div className={styles.item}>
                <Input
                  label="Площадь (кв. м)"
                  disabled={isSave}
                  onChange={(value) => handleChange(value, 'Area')}
                  placeholder='Введите площадь объекта'
                  value={building?.Area}
                  isError={buildingErrors.Area}
                  error={buildingErrors.Area}
                  skeleton={isLoading}
                />
              </div>
            </div>
            <div>
              <div className={styles.wrapper}>
                <div className={styles.item}>
                  <Input
                    disabled={isSave}
                    label='Номер разрешения на ввод в эксплуатацию'
                    onChange={(value) => handleChange(value, 'PermitNumber')}
                    placeholder='Введите номер разрешения'
                    value={building?.PermitNumber}
                    isError={buildingErrors.PermitNumber}
                    error={buildingErrors.PermitNumber}
                    skeleton={isLoading}
                  />
                </div>
                <div className={styles.item}>
                  <Datepicker
                    disabled={isSave}
                    label='Дата разрешения на ввод в эксплуатацию'
                    onChange={(value) => {
                      const parsedDate = moment(value).format('YYYY-MM-DD');
                      handleChange(parsedDate, 'PermitDate');
                    }}
                    max={moment()}
                    value={building?.PermitDate ? building?.PermitDate : null}
                    isError={buildingErrors.PermitDate}
                    error={buildingErrors.PermitDate}
                    skeleton={isLoading}
                  />
                </div>
              </div>
              <label className={styles.title}>Загрузите разрешение на ввод в эксплуатацию</label>
              <div className={styles.permit}>
                {building?.PermitFileName
                  ?
                  <div className={styles.document}>
                    <SmallCard
                      avatar={pdf}
                      title={building?.PermitFileName}
                      onRemove={!isSave ? () => {
                        setBuilding({
                          ...building,
                          PermitFileName: null,
                          PermitStorageSigId: null,
                          PermitStorageSig: {
                            SignName: null
                          }
                        });
                        setSignature(false)
                      } : null}
                      onClick={() => downloadStorageFile(building?.PermitFileId, building?.PermitFileName)}
                      skeleton={isLoading}
                    />
                  </div>
                  :
                  <div>
                    <FileInput
                      accept={[mimeTypes.Pdf]}
                      onChange={(value) => {
                        const formData = new FormData();
                        formData.append('File', value);

                        uploadFile(formData).then((id) => {
                          setBuilding({
                            ...building,
                            PermitFileId: id,
                            PermitFileName: value.name
                          })
                        })
                      }}
                    />
                  </div>
                }
                {building?.PermitFileName && !building?.PermitStorageSig?.SignName &&
                  <div className={styles.sigButtons}>
                    <Button isDisabled={signature} onClick={() => setSignature(!signature)} text='Загрузить подпись'/>
                    <span>или</span>
                    <Button onClick={() => {
                      setSignature(false)
                      setOpenSignatureModal(true);
                    }} text='Подписать'/>
                  </div>
                }
                {building?.PermitFileName && signature &&
                  <div>
                    {building?.PermitStorageSig?.SignName
                      ?
                      <div className={styles.document}>
                        <SmallCard
                          avatar={pdf}
                          title={building?.PermitStorageSig?.SignName}
                          onRemove={!isSave ? () => {
                            setBuilding({
                              ...building,
                              PermitStorageSigId: null,
                              PermitStorageSig: {
                                SignName: null
                              }
                            });
                            setSignature(false)
                          } : null}
                          onClick={() => downloadStorageFile(building?.PermitStorageSigId, building?.PermitStorageSig?.SignName)}
                          skeleton={isLoading}
                        />
                      </div>
                      :
                      <div>
                        <FileInput
                          label='Загрузите подпись (sig-файл) к документу'
                          accept={[mimeTypes.Sig]}
                          onChange={(value) => {
                            const formData = new FormData();
                            formData.append('File', value);
                            formData.append('SourceFileId', building?.PermitFileId);

                            uploadSignature(formData).then((id) => {
                              setBuilding({
                                ...building,
                                PermitStorageSigId: id,
                                PermitStorageSig: {
                                  SignName: value.name
                                }
                              })
                            })
                          }}
                        />
                      </div>
                    }
                  </div>
                }
              </div>
              {isSave
                ?
                <div className={styles.container}>
                  <div>
                    <div className={styles.titleWrapper}>
                      <label className={cn(styles.title, styles.template)}>Создайте шахматку объектов в доме</label>
                    </div>
                    <label className={cn(styles.title, styles.list)}>1. Загрузите zip-архив с выписками или скачайте пустой шаблон для заполнения данных</label>
                    <div>
                      {zipLoading ?
                        <FileButton
                          label='Заполняем данные'
                          isDisabled={zipLoading}
                          isLoading={zipLoading}
                        />
                        :
                        <div className={styles.sigButtons}>
                          <FileButton onUpload={(file) => {
                            setZipLoading(true);

                            const formData = new FormData();
                            formData.append('zipFile', file);
                            uploadZip(formData).then((excelFile) => {
                              const fileUrl = URL.createObjectURL(excelFile);

                              const downloadLink = document.createElement('a');
                              downloadLink.href = fileUrl;
                              document.body.appendChild(downloadLink);
                              downloadLink.click();
                              document.body.removeChild(downloadLink);
                              URL.revokeObjectURL(fileUrl);
                            }).finally(() => setZipLoading(false))
                          }}/>
                          <span>или</span>
                          <Button href='https://spd-3.ru/files/chess_template.xlsx' text='Скачать шаблон'/>
                        </div>
                      }
                    </div>
                    <label className={cn(styles.title, styles.list)}>{complexIsLoading ? '2. Загрузка шаблона' : '2. Загрузите заполненный шаблон' }</label>
                    {building?.TemplateFileName
                      ?
                      <div className={styles.document}>
                        <SmallCard
                          avatar={pdf}
                          title={building?.TemplateFileName}
                          onRemove={() => {
                            setBuilding({
                              ...building,
                              TemplateFileName: null,
                            });
                          }}
                          onClick={() => downloadStorageFile(building?.TemplateFileId, building?.TemplateFileName)}
                        />
                      </div>
                      :
                      complexIsLoading ?
                        <Box sx={{ width: '100%', margin: '15px 0' }}>
                          <LinearProgress
                            sx={{
                              height: 10,
                              borderRadius: 5,
                              '& .MuiLinearProgress-bar': {
                                backgroundColor: '#6941C6',
                              },
                              backgroundColor: '#D6BBFB',
                            }}
                          />
                        </Box>
                        :
                        <div>
                          <FileInput
                            accept={[mimeTypes.Xls, mimeTypes.Xlsx]}
                            onChange={(file) => {
                              const formData = new FormData();
                              formData.append('ExcelFile', file);
                              formData.append('BuildingId', build?.Id);
                              setComplexIsLoading(true);
                              setExcelFileError(false)

                              uploadResidentialComplexFile(formData)
                                .then(() => {
                                  return getResidentialComplex(complexId);
                                })
                                .then((complex) => {
                                  setComplex(complex.data);
                                  return getResidentialBuilding(build?.Id);
                                })
                                .then((res) => {
                                  setBuilding({ ...res, PermitDate: formattedDate(res.PermitDate), CadNumberDate: res.CadNumberDate });
                                })
                                .catch((error) => {
                                  setExcelFileError(true)
                                  console.error("Error uploading file or fetching data: ", error);
                                })
                                .finally(() => {
                                  setComplexIsLoading(false);
                                });
                            }}
                          />

                        </div>
                    }
                    {excelFileError &&
                      <label className={styles.error}>Произошла ошибка при загрузке шаблона</label>
                    }
                  </div>
                </div>
                :
                <div className={styles.save}>
                  {build &&
                    <Button
                      onClick={() => {
                        getResidentialComplex(complexId).then((complex) => {
                          setComplex(complex.data)
                        })
                        setIsSave(!isSave);
                      }}
                      type='default'
                      text='Отменить'
                    />
                  }
                  <Button
                    isDisabled={!building?.PermitFileName || !building?.PermitStorageSig?.SignName || isError}
                    onClick={() => {
                      buildButtonHandler()
                    }}
                    text='Сохранить'/>
                </div>
              }
            </div>
          </CardBody>
          {isSave &&
            <div className={cn(styles.close, {[styles.open]: building?.TemplateFileName})}>
              <CardBody>
                <HomesBoard
                  complexId={complexId}
                  buildingId={buildingId}
                  buildingStatistics={buildingStatistics}
                  setComplex={setComplex}
                  isLoading={complexIsLoading}
                />
                {isSave && building?.Apartments?.length > 0 && success > 0 &&
                  <div className={styles.notice}>
                    <Check onClick={() => setNotice(!notice)} label='Направить участникам долевого строительства уведомление об окончании строительства на электронную почту (На основании постановления Правительства РФ от 29 декабря 2023 г. N 2380)'/>
                    {notice && sendNotice &&
                      <label onClick={handleSendNotice}>Уведомления отправлены в {sendTime.toLocaleString()}, количество отправленных уведомлений: {success}</label>
                    }
                    {notice && !sendNotice &&
                      <Button
                        onClick={handleSendNotice}
                        type='info'
                        text={`Направить уведомления (${success})`}
                      />
                    }
                  </div>
                }
              </CardBody>
            </div>
          }
        </div>
        <Signature
          isOpen={openSignatureModal}
          onClose={setOpenSignatureModal}
          fileName={building?.PermitFileName}
          onChange={changeSignature}
          fileId={building?.PermitFileId}
        />
      </CardContainer>
    </div>
  );
};

export default Homes;
