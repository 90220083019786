import building from "../../icons/building.svg"
import usersLogo from "../../icons/UsersThree.svg"
import requestIcon from "../../icons/Phosphor-icon.svg"

const links = [
    {
        "title": "Заявки",
        "path": "/request/list",
        "iconUrl": requestIcon,
        "page": 'request'
    },
    {
        "title": "Мои ЖК",
        "path": "/pages/buildings",
        "iconUrl": building,
        "page": 'building'
    },
    {
        "title": "Клиенты",
        "path": "/pages/customers",
        "iconUrl": usersLogo,
        "page": 'customer'
    },
    {
        "title": "Объекты",
        "path": "/pages/objects",
        "iconUrl": building,
        "page": 'object'
    },

];
export default links;