import React, { useState, useRef, useEffect } from 'react';
import { useDocumentService } from '../../Services/Document/DocumentService';

import styles from "./input.module.css";

export default function InputDocuments({ value, setValue }) {
  const { documentsType } = useDocumentService();
  const [documents, setDocuments] = useState(documentsType);
  const [isOptions, setIsOptions] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [isSearch, setIsSearch] = useState(false);
  const inputRef = useRef(null);

  const handleClickOutside = (e) => {
    if (inputRef.current && !inputRef.current.contains(e.target)) {
      setIsOptions(false);
      setSearchValue('');
      setIsSearch(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className={styles.container} style={{ display: "flex" }} ref={inputRef}>
      <label>Тип документа</label>
      <input
        className={styles.input}
        value={isSearch ? searchValue : value?.name}
        type='text'
        onChange={(e) => {
          setSearchValue(e.target.value);
          const filteredValues = documentsType.filter((doc) => doc.name.toLowerCase().includes(e.target.value));
          setDocuments(filteredValues);
        }}
        onClick={() => {
          setDocuments(documentsType);
          setIsOptions(true);
          setIsSearch(true);
          setSearchValue('')
        }}
        placeholder='Выберите тип документа'
      />
      {(isOptions &&
        <div className={styles.options} style={{ flexDirection: 'column' }}>
          {documents.map((x) =>
            <span
              key={x.code}
              onClick={(e) => {
                setValue(x);
                setIsOptions(false);
                e.preventDefault();
                setSearchValue('');
                setIsSearch(false);
              }}
            >
              {x.name}
            </span>
          )}
          {documents.length === 0 &&
            <span>Не найдено</span>
          }
        </div>
      )}
    </div>
  );
}
