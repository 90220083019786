export const mimeTypes = {
  Pdf: {
    title: '.pdf',
    type: 'application/pdf'
  },
  Docx: {
    title: '.docx',
    type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
  },
  Txt: {
    title: '.txt',
    type: 'text/plain'
  },
  Sig: {
    title: '.sig',
    type: '.sig'
  },
  Xls: {
    title: '.xls',
    type: 'application/vnd.ms-excel'
  },
  Xlsx: {
    title: '.xlsx',
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  },
  Xml: {
    title: '.xml',
    type: 'application/xml'
  }
};

export const error = {
  CadNumber: 'Введите корректный кадастровый номер',
  Address: 'Введите корректный адрес',
  Area: 'Введите площадь',
  PermitNumber: 'Введите корректный номер разрешения на ввод в эксплуатацию',
  PermitDate: 'Введите корректную дату разрешения на ввод в эксплуатацию',
  CadNumberDate: 'Введите корректную дату',
  Required: 'Обязательное поле'
}

export const statisticTypes = [{
  type: 1,
  text: 'Все помещения в доме',
},{
  type: 2,
  text: 'Уведомления о приемке направлены',
},{
  type: 3,
  text: 'Записано на приемку',
},{
  type: 4,
  text: 'Объектов принято',
},{
  type: 5,
  text: 'Объекты с замечаниями',
},{
  type: 6,
  text: 'Акты приема-передачи загружены',
},{
  type: 7,
  text: 'Находятся на регистрации',
},{
  type: 8,
  text: 'Приостановлено',
},{
  type: 9,
  text: 'Возвращено без рассмотрения',
},{
  type: 10,
  text: 'Права зарегистрированы',
}]
