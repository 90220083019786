const authToken = localStorage.getItem('token');

export const initialState = {
  token: authToken,
  getToken: null,
  builder: '',
  participant: {
    name: '',
    position: '',
    document: ''
  },
  complexList: null,
  complexData: null,
  building: null,
  apartment: null,
  owner: null,
  flSubjects: [],
  ulSubjects: [],
}
