export const actions = {
  token: "token",
  getToken: "getToken",
  builder: "builder",
  participant: "participant",
  complexList: "complexList",
  complexData: "complexData",
  building: "building",
  apartment: "apartment",
  owner: "owner",
  flSubjects: "flSubjects",
  ulSubjects: "ulSubjects",
};
