import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import Button from '../../../../Components/Button/Button';
import Modal from '../../../../Components/Modal/Modal';
import Badge from '../../../../Components/Badge/Badge';
import FileInput from '../../../../Components/FileInput/FileInput';
import SmallCard from '../../../../Components/SmallCard/SmallCard';
import EmptyBlock from '../../../../Components/EmptyBlock/EmptyBlock';
import { useSignmeService } from '../../../../Services/SignMe/SignmeService';
import { useDocumentService } from '../../../../Services/Document/DocumentService';
import { mimeTypes } from '../../../../constants/constants';

import pdf from '../../../../icons/PdfIcon.svg';
import styles from './signme.module.css';

export default function SignMe() {
  const { id } = useParams();
  const { activateCertificate, issueCertificate, uploadDocument, updateDocument } = useSignmeService(id);
  const { saveDocument } = useDocumentService();
  const [isOpen, setIsOpen] = useState(false);
  const [documents, setDocuments] = useState([]);
  const { certificate, status, setCertificate, setStatus } = useSignmeService(id);
  const type = status?.code;
  const activeModal = type === 2 || type === 3 || type === 5;
  const issue = type === 0 || type === 1;
  const activate = type === 4;

  function handleClick() {
    if (activeModal) {
      setIsOpen(true)
    } else if (issue) {
      issueCertificate().then(result => setCertificate(result))
    } else if (activate) {
      activateCertificate().then(promise => {
        if(promise.status === 200) {
          setStatus(2)
        }
      })
    }
  }

  function downloadDocument(documents, typeId) {
    saveDocument(
      documents.find(doc => doc.TypeId === typeId)?.Uuid,
      documents.find(doc => doc.TypeId === typeId)?.Name
    )
  }

  useEffect(() => {
    if (certificate.Documents) {
      setDocuments(certificate.Documents);
    }
  }, [certificate?.Documents])

  return (
    <div className={styles.container}>
      <span className={styles.title}>
        Sign.Me
      </span>
      <Badge text={status?.name} type={status?.type}/>
      <div className={styles.button} >
        <Button text={ status?.buttonText } isDisabled={status?.disabled} type='primary' onClick={handleClick}/>
      </div>

      {activeModal &&
        <Modal isOpen={isOpen} setOpen={setIsOpen} title="Cертификат Sign.Me">
          <h3>Информация о сертификате</h3>
          <div className={styles.status}>
            <span>Статус сертификата:</span>
            <Badge text={status?.name} type={status?.type}/>
          </div>
          <div>
            <h4>Файл сертификата (PDF)</h4>
            { certificate?.PdfName
              ?
              <SmallCard
                avatar={pdf}
                title={certificate.PdfName}
                onClick={() => saveDocument(certificate.PdfUuid, certificate.PdfName)}
              />
              :
              <EmptyBlock title="Сертификат не выпускался" />
            }
          </div>
          {certificate?.IsMine
            ?
            <div>
              <div>
                <h4>Паспорт</h4>
                { documents.length === 0 || !documents.some(doc => doc.TypeId === 1) ? (
                  <FileInput
                    accept={[mimeTypes.Pdf]}
                    onChange={(file) =>
                      uploadDocument({
                        File: file,
                        UserInfoId: id,
                        TypeId: 1
                      }).then(result =>
                        setDocuments([...documents, result])
                      )
                    }
                  />
                ) : (
                  <SmallCard
                    title={documents.find(doc => doc.TypeId === 1)?.Name}
                    onClick={() => downloadDocument(documents, 1)}
                    avatar={pdf}
                  />
                )
                }
              </div>
              <div>
                <h4>Прописка</h4>
                { documents.length === 0 || !documents.some(doc => doc.TypeId === 2) ? (
                  <FileInput
                    accept={[mimeTypes.Pdf]}
                    onChange={(file) =>
                      uploadDocument({
                        File: file,
                        UserInfoId: id,
                        TypeId: 2
                      }).then(result =>
                        setDocuments([...documents, result])
                      )
                    }
                  />
                ) : (
                  <SmallCard
                    title={documents.find(doc => doc.TypeId === 2)?.Name}
                    onClick={() => downloadDocument(documents, 2)}
                    avatar={pdf}
                  />
                )
                }
              </div>
              <div>
                <h4>СНИЛС</h4>
                { documents.length === 0 || !documents.some(doc => doc.TypeId === 3) ? (
                  <FileInput
                    accept={[mimeTypes.Pdf]}
                    onChange={(file) =>
                      uploadDocument({
                        File: file,
                        UserInfoId: id,
                        TypeId: 3
                      }).then(result =>
                        setDocuments([...documents, result])
                      )
                    }
                  />
                ) : (
                  <SmallCard
                    title={documents.find(doc => doc.TypeId === 3)?.Name}
                    onClick={() => downloadDocument(documents, 3)}
                    avatar={pdf}
                  />
                )
                }
              </div>
              <div>
                <h4>Сертификат</h4>
                { documents.length === 0 || !documents.some(doc => doc.TypeId === 4) ? (
                  <FileInput
                    accept={[mimeTypes.Pdf]}
                    onChange={(file) =>
                      uploadDocument({
                        File: file,
                        UserInfoId: id,
                        TypeId: 4
                      }).then(result =>
                        setDocuments([...documents, result])
                      )
                    }
                  />
                ) : (
                  <SmallCard
                    title={documents.find(doc => doc.TypeId === 4)?.Name}
                    onClick={() => downloadDocument(documents, 4)}
                    avatar={pdf}
                  />
                )
                }
              </div>
            </div>
            :
            <EmptyBlock title="Сертификат был выпущен в другой системе" />
          }
        </Modal>
      }
    </div>
  )
}
