import { useEffect, useState } from "react";
import { Outlet, useParams, useNavigate, useLocation } from 'react-router-dom';

import titles, { step1, step2, step3, step4 } from "./requestTitles";
import styles from "./request.layout.module.css";
import { useRequestService } from "../../../Services/Request/RequestService";
import Badge from "../../../Components/Badge/Badge";

export default function RequestLayout() {
  const { id } = useParams();
  const navigate = useNavigate();
  const {
    request,
    regTypes,
    rightTypes,
    setRequest,
    getRequests,
    saveDocument,
    documentTypes,
    requestStatuses,
    deleteDocument,
    status,
    deleteParticipant,
    objectTypes,
    addFlSubject,
    addFlSubjectWithAgent,
    generateStatement,
    createRequest,
    addUlAgent,
    addSignature,
    addUlRepresentingFl,
    sendStatement,
    addDocumentParticipant,
    deleteDocumentParticipant,
    getRequest,
    updateRequest,
    updateFlSubject,
    updateFlSubjectWithAgent,
    updateUlAgent,
    updateUlRepresentingFl,
  } = useRequestService(id);

  const [title, setTitle] = useState(titles[0]);
  const [isDisabled, setIsDisabled] = useState(false);
  const [stepImage, setStepImage] = useState(step3);
  const [hasNavigated, setHasNavigated] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const matchedTitle = titles.find(x => location.pathname.includes(x.path));
    if (matchedTitle) {
      setTitle(matchedTitle);
    }
  }, [location.pathname]);

  useEffect(() => {
    if (request && request?.Status?.code !== undefined && !hasNavigated) {
      setHasNavigated(true);

      switch (request.Status.code) {
        case 1:
          navigate(`/request/edit/${id}/property-registration`);
          break;
        case 2:
          navigate(`/request/signature/${id}`);
          break;
        case 5:
        default:
          navigate(`/request/rosreestr/${id}`);
          break;
      }
    }
  }, [request, id, navigate, hasNavigated]);


  useEffect(() => {
    setStepImage(
      request?.Status?.code === 1 ? step1 :
        request?.Status?.code === 2 ? step2 :
          request?.Status?.code === 5 ? step4 : step3
    );

    if (request?.Status?.code === 5) {
      setTitle(prevTitle => ({
        ...prevTitle,
        subtitle: "Просмотрите результат заявки",
      }));
    }
  }, [request?.Status?.code]);


  return (
    <div className={styles.container}>
      {request?.Number != null && (
        <h1 className={styles.title}>
          {`${request?.RegAction?.name ? request?.RegAction?.name : 'Заявка'} #${request.Number}`}
          <Badge type={status.style} text={status.name}/>
        </h1>
      )}
      <div className={styles.subtitleBlock}>
        {title.subtitle != null && <span className={styles.subtitle}>{title.subtitle}</span>}
        {title.option && <span className={styles.subtitle2}>{title.option}</span>}
      </div>
      <img src={stepImage} alt="Шаг заявки" />
      <Outlet
        context={[
          request,
          regTypes,
          rightTypes,
          setRequest,
          getRequests,
          saveDocument,
          documentTypes,
          requestStatuses,
          deleteDocument,
          status,
          deleteParticipant,
          objectTypes,
          addFlSubject,
          addFlSubjectWithAgent,
          generateStatement,
          createRequest,
          addUlAgent,
          id,
          isDisabled,
          addSignature,
          addUlRepresentingFl,
          sendStatement,
          addDocumentParticipant,
          deleteDocumentParticipant,
          getRequest,
          updateRequest,
          updateFlSubject,
          updateFlSubjectWithAgent,
          updateUlAgent,
          updateUlRepresentingFl,
        ]}
      />
    </div>
  );
}
