import { useCallback, useEffect } from 'react';
import cn from 'classnames';

import Button from "../Button/Button";

import styles from "./modal.module.css";
import close from "../../icons/XCircle.svg";
import { Skeleton } from '@mui/material';

export default function Modal({isOpen, title, setOpen, onSave, children, button, notClose, skeleton, badge}) {
  const closeModal = () => {
    setOpen(false)
  }

  const handleEscKeyPress = useCallback(
    (event) => event.key === 'Escape' && closeModal(),
    [closeModal]
  );

  useEffect(() => {
    document.body.style.overflow = isOpen ? 'hidden' : 'auto';
    isOpen && document.addEventListener('keydown', handleEscKeyPress);

    return () => isOpen && document.removeEventListener('keydown', handleEscKeyPress);
  }, [isOpen, handleEscKeyPress]);

  return (
    <div
      className={cn(styles.container, {[styles.container_hidden]: !isOpen })}

    >
      <div className={cn(styles.modal, {[styles.modal_hidden]: !isOpen})}>
        <div className={styles.header}>
          <img className={styles.closeIcon} onClick={closeModal} src={close}/>
          {skeleton ? <Skeleton width='200px' height='28px'/> : <h2 className={styles.title}>{title}</h2>}
          {badge && badge}
        </div>
        <div className={styles.body}>
          {children}
        </div>
        <div className={styles.footer}>
          <Button onClick={() => {
            if (onSave) {
              onSave()
            }
            if (!notClose) {
              closeModal();
            }
          }} text='Сохранить'/>
          {button && button}
        </div>
      </div>
    </div>
  )
}