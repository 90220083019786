import styles from "./modal.module.css";

export default function ModalTab({ onClick, isActive, text }) {
    return (
      <span
        onClick={onClick}
        style={{
            color: isActive ? "#6941C6" : "",
            borderBottom: isActive ? "1px solid #6941C6" : ""
        }}
      >
        {text}
      </span>
    )
}