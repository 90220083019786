import cn from 'classnames';
import PropTypes from 'prop-types';

import styles from "./badge.module.css";
import color from "../../styles/color.module.css"

export default function Badge({ text, type, icon, onClick, right,margin }){
  return(
    <span className={cn(color[type], styles.badge, {[styles.right]: right},{[styles.margin]:margin})} onClick={onClick && onClick}>
      {icon && <img width={18} height={18} src={icon}/>}
      {text}
    </span>
  )
}

Badge.propTypes = {
  text: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['default', 'success', 'error', 'info', 'warning', 'primary']).isRequired,
  icon: PropTypes.string,
  onClick: PropTypes.func,
  right: PropTypes.bool
};
