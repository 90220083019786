import styles from "./text.area.module.css";

export default function TextArea({ label, placeholder, value, onChange, onBlur }) {
  return (
    <div className={styles.container}>
      <label className={styles.label}>
        {label}
      </label>
      <textarea
        onBlur={(e) => onBlur && onBlur(e.target.value)}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        className={styles.textArea}
        placeholder={placeholder}
      />
    </div>
  )
}