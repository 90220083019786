import React, { useEffect, useState, useTransition, useContext } from "react";
import { Outlet, useNavigate, useOutletContext } from "react-router-dom";

import Button from "../../../Components/Button/Button";
import CardHeader from "../../../Components/Card/CardHeader";
import Input from "../../../Components/Input/Input";
import RequestFooter from "../../../Components/RequestFooter/RequestFooter";
import Select from "../../../Components/Select/Select";
import TextArea from "../../../Components/TextArea/TextArea";
import Modal from "../../../Components/Modal/Modal";
import SmallCard from "../../../Components/SmallCard/SmallCard";
import Check from "../../../Components/Check/Check";
import EmptyBlock from "../../../Components/EmptyBlock/EmptyBlock";
import CardContainer from "../../../Components/Card/CardContainer";
import CardBody from "../../../Components/Card/CardBody";
import { HttpContext } from "../../../HttpContext";

import plus from "../../../icons/plus-violet.svg";
import avatar from "../../../images/Avatar_2.svg";
import avatarBuilding from "../../../images/Avatar_building.svg";
import checkEmpty from "../../../images/Check_Empty.svg";
import checkFilled from "../../../images/Check.svg";
import styles from "./request.create.module.css";

export default function SalesContract(props) {
  const [request, setRequest] = useOutletContext();
  const { httpClient } = useContext(HttpContext);
  const [flSubjects, setFlSubjects] = useState([]);
  const [isSellersModal, setSellersModal] = useState(false);
  const [isBuyersModal, setBuyersModal] = useState(false);
  const [flSubject, setFlSubject] = useState();

  const [sellers, setSellers] = useState([
    {
      id: 1,
      fio: "Писцов Кирилл Валентинович",
      city: "jdoga@mail.ru"
    },
    {
      id: 2,
      fio: "Александров Иван Георгиевич",
      city: "dadwwd@mail.ru"
    },
  ])

  const [buyers, setBuyers] = useState([
    {
      id: 1,
      fio: "ООО Компания",
      city: "otaad@mail.ru"
    },
    {
      id: 2,
      fio: "Александров Иван Георгиевич",
      city: "akde@mail.ru, Представитель (подписант): Алексей Иванович Пастухов "
    },
  ])

  useEffect(() => {
    getFlSubjects()
  }, [])

  const getFlSubjects = async () => {
    await httpClient.get("/flsubject/all").then(res => setFlSubjects(res.data.Data));
  }

  const addParticipant = async (typeId) => {
    const body = {
      "FlSubjectId": flSubject.Id,
      "Privileged": false,
      "RequestId": request.Id,
      "TypeId": typeId,
      "Numerator": 0,
      "Denominator": 1,
      "Note": "string"
    }
    await httpClient.post("/request/participant/add/flsubject", body);
  }

  const selectFlSubject = (id) => {
    const fl = flSubjects.find(x => x.Id == id)
    setFlSubject(fl)
  }

  return (
    <React.Fragment>
      <CardContainer>
        <CardHeader title="Участники сделки (ДКП)"/>
        <CardBody>
          <div className={styles.membersBlock}>
            {request?.Participants?.filter(x => x.Type.code == 2).length > 0 &&
              <div className={styles.activeMembers}>
                {request?.Participants.filter(x => x.Type.code == 2).map((x) =>
                  <SmallCard
                    isRemove
                    onRemove={() => setSellers(sellers.filter(t => t.id !== x.id))}
                    bgColor="#F9FAFB"
                    border="1px solid #D0D5DD"
                    key={x.id}
                    title={x.FlSubject.name}
                    subtitle="Продавец"
                    avatar={avatar}
                  />
                )}
              </div>
            }
            {request?.Participants?.filter(x => x.Type.code == 2).length === 0 &&
              <EmptyBlock title="Текущие продавцы" />
            }
            {request?.Participants?.filter(x => x.Type.code == 1).length > 0 &&
              <div className={styles.activeMembers}>
                {request?.Participants?.filter(x => x.Type.code == 1).map((x) =>
                  <SmallCard
                    onRemove={() => setBuyers(buyers.filter(t => t.id !== x.id))}
                    bgColor="#F9FAFB"
                    border="1px solid #D0D5DD"
                    key={x.Id}
                    title={x.FlSubject.name}
                    subtitle="Покупатель"
                    avatar={avatar}
                  />
                )}
              </div>
            }
            {request?.Participants?.filter(x => x.Type.code == 1).length === 0 &&
              <EmptyBlock title="Текущие покупатели" />
            }
          </div>
          <div className={styles.add_buttons}>
            <Button
              width="100%"
              text="Добавить продавца"
              onClick={() => setSellersModal(true)}
              icon={plus}
              color="#6941C6"
              bgColor="#F9F5FF"
            />
            <Button
              width="100%"
              text="Добавить покупателя"
              onClick={() => setBuyersModal(true)}
              icon={plus}
              color="#6941C6"
              bgColor="#F9F5FF"
            />
          </div>
        </CardBody>
      </CardContainer>
      {/*<Modal title="Добавить продавца" isOpen={isSellersModal} setOpen={setSellersModal}>*/}
      {/*  {flSubjects.map((x) =>*/}
      {/*    <SmallCard */}
      {/*      key={x.Id} */}
      {/*      title={`${x.Lastname} ${x.Firstname} ${x.Middlename}`} */}
      {/*      subtitle={x.Phone} */}
      {/*      avatar={avatar}*/}
      {/*    />*/}
      {/*  )}*/}
      {/*</Modal>*/}
      {/*<Modal title="Добавить покупателя" isOpen={isBuyersModal} setOpen={setBuyersModal}>*/}
      {/*  {flSubjects.map((x) =>*/}
      {/*    <SmallCard */}
      {/*      key={x.Id} */}
      {/*      title={`${x.Lastname} ${x.Firstname} ${x.Middlename}`} */}
      {/*      subtitle={x.Phone} */}
      {/*      avatar={avatar}*/}
      {/*    />*/}
      {/*  )}  */}
      {/*</Modal>*/}
      <Modal
        title="Добавление продавца"
        onSave={() => addParticipant(2)}
        isOpen={isSellersModal}
        setOpen={setSellersModal}
      >
        <Select label="ФЛ" value={flSubject?.Id} onChange={(id) => selectFlSubject(id)}>
          {flSubjects.map((x) =>
            <option value={x.Id}>{`${x.Lastname} ${x.Firstname} ${x.Middlename}`}</option>
          )}
        </Select>
      </Modal>
      <Modal
        title="Добавление покупателя"
        onSave={() => addParticipant(1)}
        isOpen={isBuyersModal}
        setOpen={setBuyersModal}
      >
        <Select label="ФЛ" value={flSubject?.Id} onChange={(id) => selectFlSubject(id)}>
          {flSubjects.map((x) =>
            <option value={x.Id}>{`${x.Lastname} ${x.Firstname} ${x.Middlename}`}</option>
          )}
        </Select>
      </Modal>
    </React.Fragment>
  )
}