import FileInput from "../../../Components/FileInput/FileInput";
import Input from "../../../Components/Input/Input";
import Modal from "../../../Components/Modal/Modal";
import Select from "../../../Components/Select/Select";
import SmallCard from "../../../Components/SmallCard/SmallCard";
import EmptyBlock from "../../../Components/EmptyBlock/EmptyBlock";
import { useUlService } from '../../../Services/UlSubjectService';
import { useFlService } from '../../../Services/FlSubjectService';
import { mimeTypes } from '../../../constants/constants';

import pdfIcon from "../../../icons/PdfIcon.svg";

export default function AgentModal({ customer, agent, setAgent, isOpen, setOpen, setCustomer }) {
  const {
    positions,
    documentTypes,
    updateUlSubject,
    deleteUlDocument,
    updateUlAgent,
    createUlAgent,
    createUlDocument,
    createUlSignature
  } = useUlService();
  const { updateFlSubject, getFlSubject } = useFlService();

  async function updateCustomer() {
    let requestObject = customer;
    requestObject.Address = customer.Address.Note;
    requestObject.GenderCode = customer.Gender.code
    requestObject.DocTypeCode = customer.DocType.code
    requestObject.GenderCode = customer.Gender.code
    const response = await updateFlSubject(requestObject)
  }

  // useEffect(() => {
  //     const flId = agent?.Id;
  //     if (flId != null) {
  //         getCustomer(flId)
  //     }
  // }, [isOpen])

  async function getCustomer(flId) {
    const response = await getFlSubject(flId)
    setCustomer(response)
  }

  function getDigits(str) {
    let result = str.match(/\d/g);
    result = result.join("");
    return result
  }

  async function updateCustomer() {
    let requestObject = customer;
    requestObject.Phone = getDigits(customer.Phone);
    requestObject.Inn = getDigits(customer.Inn)
    requestObject.CountryCode = customer.Country.code
    requestObject.GenderCode = customer.Gender.code
    requestObject.DocTypeCode = customer.DocType.code
    requestObject.GenderCode = customer.Gender.code
    requestObject.Snils = getDigits(customer.Snils)
    const response = await updateUlSubject(requestObject)
  }

  async function deleteDocument() {
    const body = {
      UlAgentId: agent.Id,
      DocumentTypeId: 1
    }
    const response = await deleteUlDocument({ data: body })
    setAgent({ ...agent, Document: { ...agent.Document, FileName: null } })
  }

  async function updateAgent() {
    const body = {
      Id: agent.Id,
      FioGenitive: agent.FioGenitive,
      PositionId: agent.Position.code,
      TypeCode: agent.Document.Type.code,
      IsMain: agent.IsMain,
    };
    const response = await updateUlAgent(body);
  }

  async function createAgent() {
    let body = agent;
    body.PositionId = agent.Position.code;
    body.TypeCode = agent.Document.Type.code
    const response = await createUlAgent(body);
  }

  async function deleteSignature() {
    const body = {
      UlAgentId: customer.Id,
      DocumentTypeId: 2
    }
    const response = await deleteUlDocument({ data: body })
    setAgent({ ...agent, Document: { ...agent.Document, SigFileName: null } })
  }

  async function addDocument(file) {
    const data = new FormData()
    data.append("file", file)
    data.append("ulAgentId", agent.Id)
    const response = await createUlDocument(data)
    setAgent({ ...agent, Document: { ...agent.Document, FileName: file.name } })
  }

  async function addSignature(file) {
    const data = new FormData()
    data.append("file", file)
    data.append("ulAgentId", agent.Id)
    const response = await createUlSignature(data)
    setAgent({ ...agent, Document: { ...agent.Document, SigFileName: file.name } })

  }
  const update = async () => {
    if (agent.Id != null) {
      await updateAgent()
    }
    else {
      await createAgent()
    }
    window.location.reload()
  }

  return (
    <Modal
      onSave={() => update()}
      title="Добавление/Редактирование представителя"
      isOpen={isOpen}
      setOpen={setOpen}
    >
      <h3>Представитель</h3>
      <Select
        value={agent?.Position?.code}
        onChange={(value) => setAgent({
          ...agent,
          Position: { ...agent.Position, code: value }
        })}
        label="Должность"
        placeholder="Выберите из списка"
      >
        {positions.map((x) =>
          <option value={x.Id}>{x.Genitive}</option>
        )
        }
      </Select>
      <Input
        value={agent?.FioGenitive}
        onChange={(value) => setAgent({
          ...agent,
          FioGenitive: value
        })}
        label="ФИО в родительном падеже"
        placeholder="Соловьёва Валерия Анатольевна"
      />
      <h3>Документ</h3>
      <Select
        value={agent?.Document?.Type?.code}
        onChange={(value) => setAgent({
          ...agent,
          Document: {
            ...agent.Document,
            Type: { ...agent.Document?.Type, code: value }
          }
        })}
        label="Документ, подтверждающий полномочия"
        placeholder="Выберите из списка"
      >
        {documentTypes.map((x) =>
          <option value={x.code}>{x.name}</option>
        )}
      </Select>
      {(agent?.Document?.FileName == null && agent?.Id != null) &&
        <FileInput accept={[mimeTypes.Pdf, mimeTypes.Xml]} onChange={(e) => addDocument(e)}/>
      }
      {
        agent?.Id == null &&
        <EmptyBlock title="Сохраните представителя и после загрузите документ"/>
      }
      {agent?.Document?.FileName != null &&
        <SmallCard
          title={agent.Document.FileName}
          subtitle={agent.Document.Type.name}
          avatar={pdfIcon}
          onRemove={() => deleteDocument()}
        />
      }
      <h3>Подпись</h3>
      {(agent?.Document?.SigFileName == null && agent?.Id != null) &&
        <FileInput accept={[mimeTypes.Sig]} onChange={(e) => addSignature(e)}/>
      }
      {
        agent?.Id == null &&
        <EmptyBlock title="Сохраните представителя и после загрузите подпись"/>
      }
      {agent?.Document?.SigFileName != null &&
        <SmallCard
          title={agent.Document.SigFileName}
          subtitle="Подпись"
          avatar={pdfIcon}
          onRemove={() => deleteSignature()}
        />
      }
    </Modal>
  )
}