import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import TableCell from "../../../Components/Table/TableCell";
import TableContainer from "../../../Components/Table/TableContainer";
import TableHeader from "../../../Components/Table/TableHeader";
import TableSkeleton from '../../../Components/Table/TableSkeleton';
import { useUlService } from '../../../Services/UlSubjectService';
import { useResidentialComplexService } from '../../../Services/ResidentialComplexService';
import Button from '../../../Components/Button/Button';
import TableFooter from '../../../Components/Table/TableFooter';
import EmptyBlock from '../../../Components/EmptyBlock/EmptyBlock';
import { HttpContext } from '../../../HttpContext';

import styles from "./building.list.module.css";
import { useFlService } from '../../../Services/FlSubjectService';

export default function BuildingList() {
  const { state } = useContext(HttpContext);
  const {complexList, ulSubjects} = state;
  const navigate = useNavigate();
  const {getResidentialComplexes} = useResidentialComplexService();
  const {getUlSubjects} = useUlService();
  const {getFlSubjects} = useFlService();

  const handleChange = (page) => {
    getResidentialComplexes(page);
  };

  useEffect(() => {
    getResidentialComplexes(1)
    getUlSubjects()
    getFlSubjects()
  }, [])

  const StatusBar = ({ successPercent, errorPercent, notRegisteredPercent }) => {
    return (
      <div className={styles.bar}>
        <div className={styles.success} style={{ width: `${successPercent}%` }}/>
        <div className={styles.default} style={{ width: `${errorPercent}%` }}/>
        <div className={styles.error} style={{ width: `${notRegisteredPercent}%` }}/>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      {complexList?.Items?.length !== 0 &&
        <TableContainer>
          <TableHeader>
            <TableCell th="Название ЖК"/>
            <TableCell th="Адрес"/>
            <TableCell th="Кол. домов/пом."/>
            <TableCell th="Статус"/>
          </TableHeader>
          <tbody>
          {complexList?.Items?.map((x) =>
            <tr key={x?.Id} onClick={() => navigate(`/building/edit/${x.Id}`)}>
              <TableCell title={`ЖК «${x.Name}»`} subtitle={ulSubjects.length !== 0 ? ulSubjects?.find(obj => obj.Id === x.UlSubjectId).Name : ''}/>
              <TableCell title={x.Address}/>
              <TableCell title={x.BuildingsCount}/>
              <TableCell th={<StatusBar errorPercent={10} notRegisteredPercent={20} successPercent={70}/>}/>
            </tr>
          )}
          {!complexList && <TableSkeleton row={4} col={10}/>}
          </tbody>
          <TableFooter/>
        </TableContainer>
      }
      {complexList?.Items?.length === 0 &&
        <EmptyBlock title='Нет жилых комплексов'/>
      }
      {complexList?.TotalCount >= 10 &&
        <div className={styles.footer}>
        <Button isDisabled={!complexList?.HasPreviousPage} onClick={() => handleChange(complexList?.PageNumber - 1)} text="Предыдущая"/>
        <span className={styles.paginationTitle}>Страница {complexList?.PageNumber} из {complexList?.TotalPages}</span>
        <Button isDisabled={!complexList?.HasNextPage} onClick={() => handleChange(complexList?.PageNumber + 1)}  text="Следующая"/>
        </div>
      }
    </div>
  )
}
