import cn from 'classnames';

import closeIcon from "../../icons/XCircle (1).svg";
import editIcon from "../../icons/NotePencil.svg";
import infoIcon from "../../icons/infoIcon.svg";
import styles from "./small.card.module.css";
import color from "../../styles/color.module.css";
import { CircularProgress, Skeleton } from '@mui/material';

export default function SmallCard({ onClick, onSelect, avatar, title, subtitle, badge, onEdit, onInfo, onRemove, children, type = 'default', signature,archive, skeleton, isLoad, loadText }) {
  return (
    !skeleton ?
      isLoad ?
        <div className={cn(styles.container, color.default)}>
          <CircularProgress size={30} color='inherit'/>
          <div className={styles.title_block_skeleton}>
            <span className={styles.title}>
              Идет загрузка...
            </span>
            <span className={styles.subtitle}>
              {loadText}
            </span>
          </div>
        </div>
        :
        <div className={cn(styles.container, color[type], {
          [styles.pointer]: onClick,
        })}
          onClick={onClick}
        >
          <img onClick={onSelect && onSelect} className={styles.avatar} src={avatar} />
          <div className={styles.title_block}>
            <span className={styles.title}>
              {title}
            </span>
            <span className={styles.subtitle}>
              {subtitle}
            </span>
            {children}
          </div>
          {badge && badge}
          {onEdit && <img src={editIcon} onClick={onEdit} className={styles.editIcon} />}
          {onInfo && <img src={infoIcon} onClick={onInfo} className={styles.infoIcon} />}
          {onRemove && <img src={closeIcon} onClick={(e) => {
            e.stopPropagation();
            onRemove();
          }} className={styles.closeIcon} />}
          {signature && signature}
          {archive && archive}
        </div>
      :
      <div className={cn(styles.container, color.default)}>
        <Skeleton variant='circular' height='32px' width='32px'/>
        <div className={styles.title_block_skeleton}>
        <span className={styles.title}>
          <Skeleton variant='rounded' width='250px'/>
        </span>
          <span className={styles.subtitle}>
          <Skeleton variant='rounded' width='150px' height='11px'/>
        </span>
        </div>
      </div>
  )
}