import Button from "../../Components/Button/Button";
import Input from "../../Components/Input/Input";
import plus from "../../icons/UserCirclePlus.svg"
import styles from "./login.module.css"
import arrow from "../../icons/arrow-right-circle.svg"
import { useContext, useState } from "react";
import { HttpContext } from "../../HttpContext";

export default function Login() {
    const [login, setLogin] = useState("")
    const [password, setPassword] = useState("")
    const { httpClient, state } = useContext(HttpContext)

    return (<div className={styles.container}>
        <h1>Авторизация</h1>
        <Input name="login" placeholder="Логин" value={login} onChange={(value) => setLogin(value)} label="Логин"/>
        <Input name="password" type="password" placeholder="Пароль" value={password} onChange={(value) => setPassword(value)} label="Пароль"/>

        <div className={styles.buttons}>
            <Button text="Регистрация" isDisabled href="/register" icon={plus} type='default'/>
            <Button text="Войти" icon={arrow} onClick={() => state?.getToken(login, password, httpClient)} isRevert />
        </div>

    </div>)
}