import styles from "./table.module.css";

export default function InnerCell({ children }) {
    return (
      <td className={styles.td}>
        <div className={styles.cell}>
          {children}
        </div>
      </td>
    );
}