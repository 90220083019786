import React, { useEffect, useState } from 'react';
import styles from './cadastral.module.css';
import cn from 'classnames';

const Cadastral = ({data}) => {
  const [object, setObject] = useState()

  function getStatus(status) {
    switch (status) {
      case '0':
        return {
          text: 'Не актуально',
        };
      case '1':
        return {
          text: 'Актуально',
        };
      default:
        return {
          text: 'Неизвестно',
        };
    }
  }

  const getObjectTypeDescription = (code) => {
    switch (code) {
      case '204001000000':
        return 'Нежилое здание';
      case '204002000000':
        return 'Жилой дом';
      case '204003000000':
        return 'Многоквартирный дом';
      case '206001000000':
        return 'Нежилое помещение';
      case '206002000000':
        return 'Жилое помещение';
      default:
        return '-';
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();

    return `${day}.${month}.${year}`;
  }

  useEffect(() => {
    if (data?.length) {
      setObject(data[0])
    }
  }, [data])

  return (
    <div className={styles.container}>
      <div className={styles.title_wrapper}>
        <p className={styles.title}>{object?.objectTypeDesctiption}</p>
        <p className={styles.date_update}>{`Последнее обновление: ${formatDate(object?.infoUpdateDate)}`}</p>
      </div>
      <div className={cn(styles.item, styles.item_bold)}>
        <span>Кадастровый номер:</span>
        <span>{object?.cadNumber}</span>
      </div>
      <div className={styles.item}>
        <span>Адрес:</span>
        <span>{object?.address?.readableAddress}</span>
      </div>
      <div className={styles.item}>
        <span>Статус объекта:</span>
        <span>{getStatus(object?.status).text}</span>
      </div>
      <div className={styles.item}>
        <span>Площадь:</span>
        <span>{object?.area}</span>
      </div>
      <div className={styles.item}>
        <span>Дата регистрации объекта:</span>
        <span>{formatDate(object?.registrationDate)}</span>
      </div>
      <div className={styles.item}>
        <span>Назначение:</span>
        <span>{getObjectTypeDescription(object?.purpose)}</span>
      </div>
      {object?.levelFloor &&
        <div className={styles.item}>
          <span>Этаж:</span>
          <span>{object?.levelFloor}</span>
        </div>
      }
      {object?.floor &&
        <div className={styles.item}>
          <span>Этажность:</span>
          <span>{object?.floor}</span>
        </div>
      }
      {object?.undergroundFloor &&
        <div className={styles.item}>
          <span>Подземных этажей:</span>
          <span>{object?.undergroundFloor}</span>
        </div>
      }
      {object?.oksWallMaterial &&
        <div className={styles.item}>
          <span>Материал стен:</span>
          <span>{object?.oksWallMaterial}</span>
        </div>
      }
      {object?.oksYearBuild &&
        <div className={styles.item}>
          <span>Год постройки:</span>
          <span>{object?.oksYearBuild}</span>
        </div>
      }
      {object?.rights?.length > 0 &&
        <p className={styles.title}>Права собственности</p>
      }
      {object?.rights?.length > 0 &&
        <div className={styles.wrapper}>
          {object?.rights?.map((item) =>
            <div className={styles.item_wrapper}>
              <div className={styles.item}>
                <span>Тип права:</span>
                <span>{item?.rightTypeDescription}</span>
              </div>
              <div className={styles.item}>
                <span>Номер регистрации права:</span>
                <span>{item?.rightNumber}</span>
              </div>
              <div className={styles.item}>
                <span>Дата регистрации права:</span>
                <span>{formatDate(item?.rightRegDate)}</span>
              </div>
              {item?.part &&
                <div className={styles.item}>
                  <span>Доля:</span>
                  <span>{item?.part}</span>
                </div>
              }
            </div>
          )}
        </div>
      }
      {object?.encumbrances?.length > 0 &&
        <p className={styles.title}>Обременения</p>
      }
      {object?.encumbrances?.length > 0 &&
        <div className={styles.wrapper}>
          {object?.encumbrances?.map((item) =>
            <div className={styles.item_wrapper}>
              <div className={styles.item}>
                <span>Тип обременения:</span>
                <span>{item?.encumbranceTypeDescription}</span>
              </div>
              <div className={styles.item}>
                <span>Дата начала обременения:</span>
                <span>{formatDate(item?.startDate)}</span>
              </div>
              <div className={styles.item}>
                <span>Номер регистрации обременения:</span>
                <span>{item?.encumbranceNumber}</span>
              </div>
            </div>
          )}
        </div>
      }
      <p className={styles.title}>Кадастровая стоимость</p>
      <div className={styles.item}>
        <span>Кадастровая стоимость:</span>
        {object?.cadCost &&
          <span>{object?.cadCost}</span>
        }
      </div>
      <div className={styles.item}>
        <span>Дата оценки кадастровой стоимости:</span>
        {object?.cadCostDeterminationDate &&
          <span>{formatDate(object?.cadCostDeterminationDate)}</span>
        }
      </div>
      <div className={styles.item}>
        <span>Дата внесения кад. стоимости:</span>
        {object?.cadCostRegistrationDate &&
          <span>{formatDate(object?.cadCostRegistrationDate)}</span>
        }
      </div>
      <p className={styles.title}>Кадастровый инженер</p>
      <div className={styles.item}>
        <span>Кадастровый инженер:</span>
        {object?.cadEngFIO &&
          <span>{object?.cadEngFIO}</span>
        }
      </div>
      <div className={styles.item}>
        <span>Контакты кадастрового инженера:</span>
        {object?.cadEngPhone &&
          <span>{object?.cadEngPhone}</span>
        }
      </div>
      <div className={styles.item}>
        <span>Сертификат кадастрового инженера:</span>
        {object?.cadEngCertNumber &&
          <span>{object?.cadEngCertNumber}</span>
        }
      </div>
    </div>
  );
};

export default Cadastral;
