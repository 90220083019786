import { actions } from './actions';

export const reducer = (state, action) => {
  switch (action.type) {

    case actions.getToken:
      return { ...state, getToken: action.payload };

    case actions.token:
      return { ...state, token: action.payload };

    case actions.builder:
      return { ...state, builder: action.payload };

    case actions.participant:
      return { ...state, participant: action.payload };

    case actions.complexList:
      return { ...state, complexList: action.payload };

    case actions.complexData:
      return { ...state, complexData: action.payload };

    case actions.building:
      return { ...state, building: action.payload };

    case actions.apartment:
      return { ...state, apartment: action.payload };

    case actions.owner:
      return { ...state, owner: action.payload };

    case actions.flSubjects:
      return { ...state, flSubjects: action.payload };

    case actions.ulSubjects:
      return { ...state, ulSubjects: action.payload };

    default:
      return state;
  }
};
