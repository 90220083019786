import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import CardHeader from "../../../Components/Card/CardHeader";
import CardBody from "../../../Components/Card/CardBody";
import Button from "../../../Components/Button/Button";
import CardContainer from "../../../Components/Card/CardContainer";
import Input from "../../../Components/Input/Input";
import Select from "../../../Components/Select/Select";
import Homes from "../Homes/Homes";
import Menu from "../../../Components/Menu/Menu";
import { useUlService } from "../../../Services/UlSubjectService";
import { useParams } from "react-router-dom";
import { useAddressService } from "../../../Services/AddressService";
import AddressInput from "../../../Components/Input/AddressInput";
import { useResidentialComplexService } from "../../../Services/ResidentialComplexService";
import ModalCenter from "../../../Components/ModalCenter/ModalCenter";
import { HttpContext } from '../../../HttpContext';
import { getBuilding } from '../../../Services/reducer/Building/building';
import { Skeleton } from '@mui/material';

import plus from "../../../icons/plus.svg";
import styles from "./building.create.module.css";
import { useFlService } from '../../../Services/FlSubjectService';

export default function BuildingCreate() {
  const { state, dispatch } = useContext(HttpContext);
  const {updateBuilder, updateParticipant} = getBuilding(dispatch);
  const navigate = useNavigate();
  const { id } = useParams();
  const { getUlSubjects, getUlAgent } = useUlService();
  const { getFlSubjects } = useFlService();
  const { getAddress } = useAddressService();
  const {
    createResidentialComplex,
    getResidentialComplex,
    updateResidentialComplex,
    deleteResidentialComplex,
  } = useResidentialComplexService();

  const [complex, setComplex] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const [isCreate, setIsCreate] = useState(true);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [showTemplate, setShowTemplate] = useState(false);
  const [participants, setParticipants] = useState(null);
  const [isLoading, setIsLoading] = useState(false)

  const fetchComplexData = async (id) => {
    setIsLoading(true)
    try {
      const complexResponse = await getResidentialComplex(id);
      const complexData = complexResponse.data;
      const addressResponse = await getAddress(complexData.Address);
      const address = addressResponse.find(
        (obj) => obj.Note === complexData.Address,
      );

      setComplex({
        ...complexData,
        Address: address,
      });
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      console.error("Error fetching complex data:", error);
    }
  };

  useEffect(() => {
    getUlSubjects()
    getFlSubjects()
    if (id) {
      fetchComplexData(id).then(() => {
        setIsEdit(true);
        setIsCreate(false);
      })
    }
  }, []);

  useEffect(() => {
    if (complex?.UlSubjectId) {
      const selectedSubject = state?.ulSubjects?.find((item) => item.Id === complex?.UlSubjectId);
      const participant = selectedSubject?.Agents?.find(agent => agent.Id === complex?.ParticipantId);

      setParticipants(selectedSubject?.Agents);
      if (selectedSubject) {
        updateBuilder(selectedSubject.Name)
      }
      if (participant) {
        updateParticipant({
          name: participant?.FlSubjectFIO,
          position: participant?.Position.name,
          document: participant?.Document.Type.name,
          id: participant?.Id
        })
      }
    }
  }, [complex?.UlSubjectId])

  return (
    <div className={styles.container}>
      <div className={styles.titles}>
        {id && complex ? (
          <div>
            {!isLoading ?
              <h1>{complex?.Name && `ЖК «${complex?.Name}»`}</h1>
              :
              <Skeleton variant='rounded' height='38.5px' width='200px'/>
            }
          </div>
        ) : (
          <h1>Введите информацию о новом жилом комплексе</h1>
        )}
        {id && complex &&
        <h3>Информация о жилом комплексе</h3>}
      </div>
      <CardContainer>
        <CardHeader
          menu={
            <Menu>
              <Button
                isDisabled={!isEdit}
                onClick={() => {
                  setIsEdit(!isEdit);
                  setIsCreate(!isCreate);
                }}
                large
                type="default"
                text="Редактировать"
              />
              <Button
                isDisabled={!id}
                large
                type="error"
                text="Удалить"
                onClick={() => setIsOpenDeleteModal(true)}
              />
            </Menu>
          }
          label="В работе"
          labelType='warning'
          title="Жилой комплекс"
        />
        <CardBody>
          <div className={styles.wrapper}>
            <Input
              disabled={!isCreate}
              name="complex"
              label="Название ЖК"
              placeholder="Например, ЖК «Династия»"
              onChange={(value) =>
                setComplex({
                  ...complex,
                  Name: value,
                })
              }
              value={complex?.Name}
              skeleton={isLoading}
            />
            <AddressInput
              disabled={!isCreate}
              value={complex?.Address}
              setValue={(value) => {
                setComplex({
                  ...complex,
                  Address: value,
                });
              }}
              skeleton={isLoading}
            />
          </div>
          <div className={styles.wrapper}>
            <Select
              disabled={!isCreate}
              label="Застройщик"
              placeholder="Выберите застройщика"
              onChange={(value) => {
                setComplex({
                  ...complex,
                  UlSubjectId: value,
                  ParticipantId: null
                })
                updateBuilder(value.Name)
              }}
              value={complex?.UlSubjectId}
              skeleton={isLoading}
            >
              {state?.ulSubjects?.map((item, index) => {
                return <option key={index} value={item.Id}>{item.Name}</option>;
              })}
            </Select>
          </div>
          {complex?.UlSubjectId && participants &&
            <div className={styles.wrapper}>
              <Select
                disabled={!isCreate}
                label="Представитель"
                placeholder="Выберите представителя"
                onChange={(value) => {
                  setComplex({
                    ...complex,
                    ParticipantId: value
                  })
                  const participant = participants.find((x) => x.Id === value)

                  getUlAgent(value).then((agent) => {
                    updateParticipant({
                      name: agent?.FlSubject?.name,
                      position: participant.Position.name,
                      document: participant.Document.Type.name,
                      id: agent?.FlSubject?.code
                    })
                  })
                }}
                value={complex?.ParticipantId}
                skeleton={isLoading}
              >
                {participants?.map((item, index) => {
                  return <option key={index} value={item.Id}>{ `${ item.FioGenitive }, ${item.Position.name} (${item.Document.Type.name})` }</option>
                })}
              </Select>
            </div>
          }
        </CardBody>
        <div className={styles.footer}>
          {!isEdit && !isLoading && (
            <div className={styles.buttons}>
              {id &&
                <Button
                  onClick={() => {
                    fetchComplexData(id).then(() => {
                      setIsEdit(!isEdit);
                      setIsCreate(!isCreate);
                    })
                  }}
                  type="default"
                  text="Отменить"
                />
              }
              <Button
                isDisabled={!complex?.UlSubjectId || !complex?.Address || !complex?.Name || !complex?.ParticipantId}
                onClick={() => {
                  if (id) {
                    updateResidentialComplex({
                      ...complex,
                      Address: complex.Address.Note,
                      UlSubjectId: Number(complex?.UlSubjectId),
                    }).then(() => {
                      getResidentialComplex(complex?.Id).then((res) => {
                        setComplex(res.data);
                      })
                    });
                  } else {
                    createResidentialComplex({
                      ...complex,
                      Address: complex.Address.Note,
                      UlSubjectId: Number(complex?.UlSubjectId),
                    }).then((res) => {
                      setComplex(res);
                      navigate(`/building/edit/${res.Id}`);
                    });
                  }
                  setIsEdit(!isEdit);
                  setIsCreate(!isCreate);
                }}
                text="Сохранить"
              />
            </div>
          )}
        </div>
      </CardContainer>
      {!isCreate && (
        <div className={styles.corpuses}>
          <div>
            <h3>Дома в жилом комплексе</h3>
            {!complex?.Buildings?.length &&
            <span>Введите информацию о жилых домах</span>
            }
          </div>
          {complex?.Buildings?.length ? (
            complex?.Buildings.map((building, index) => (
              <Homes
                key={index}
                home={building?.Number}
                build={building}
                complexId={complex.Id}
                setComplex={setComplex}
              />
            ))
          ) : (
            <Homes
              home='Дом 1'
              complexId={complex.Id}
              setComplex={setComplex}
            />
          )}
          {complex?.Buildings?.length > 0 &&
            (showTemplate ? (
              <Homes
                home={`Дом ${complex?.Buildings?.length + 1}`}
                complexId={complex.Id}
                setComplex={setComplex}
                setShowTemplate={setShowTemplate}
              />
            ) : (
              <Button
                icon={plus}
                large
                text="Добавить дом"
                onClick={() => setShowTemplate(true)}
              />
            ))}
        </div>
      )}
      <ModalCenter isOpen={isOpenDeleteModal}>
        <div className={styles.delete}>
          <h4>Удалить жилой комплекс?</h4>
          <Button
            type="error"
            onClick={() =>
              deleteResidentialComplex(complex.Id).then(() =>
                navigate("/pages/buildings"),
              )
            }
            text="Удалить"
          />
          <Button
            type="gray"
            onClick={() => setIsOpenDeleteModal(false)}
            text="Отмена"
          />
        </div>
      </ModalCenter>
    </div>
  );
}
