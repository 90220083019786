import { useContext } from 'react';
import { HttpContext } from '../../HttpContext';

export function useEstateObjectService() {
  const { httpClient } = useContext(HttpContext);

  const getEstateObject = async (id) => {
    return httpClient.get(`/estate-object/${id}`).then(promise => promise.data)
  }

  const deleteEstateObject = async (id) => {
    return httpClient.delete(`/estate-object/${id}`).then(promise => promise.data)
  }

  const getEstateObjects = async (page, searchValue) => {
    return httpClient.get(`/estate-object/?PageNumber=${page}&PageSize=10&All=${searchValue}`).then(promise => promise.data)
  }

  const updateEstateObject = async (body) => {
    return httpClient.put("/estate-object", body).then(promise => promise.data)
  }

  const createEstateObject = async (body) => {
    return httpClient.post("/estate-object", body).then(promise => promise.data)
  }

  return {
    getEstateObject,
    deleteEstateObject,
    getEstateObjects,
    updateEstateObject,
    createEstateObject
  }
}