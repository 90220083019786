import Button from "../../Components/Button/Button";
import Input from "../../Components/Input/Input";

import person from "../../icons/PersonSimpleWalk.svg";
import arrow from "../../icons/arrow-right-circle.svg";
import styles from "./register.module.css";

export default function Register(){
  return(
    <div className={styles.container}>
      <h1>Регистрация</h1>
      <Input placeholder="Алексеев Андрей Васильевич" label="ФИО"/>
      <Input placeholder="Пароль" label="Пароль"/>
      <Input placeholder="mail@mail.ru" label="Электронная почта"/>
      <div className={styles.buttons}>
        <Button text="Войти" icon={person} href="/login" type='default' />
        <Button text="Зарегистрироваться" href="/" icon={arrow} isRevert/>
      </div>
    </div>
  )
}