import documentsType from './documentTypes.json'
import { HttpContext } from '../../HttpContext';
import { useContext } from 'react';

export function useDocumentService() {
  const { httpClient } = useContext(HttpContext);

  const saveDocument = async (guid, name) => {
    const response = await httpClient.get(`/file/download/${guid}`, {
      responseType: 'arraybuffer',
    })
    const data = response.data;

    const blob = new Blob([data], { type: 'application/pdf' });
    const url = window.URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.href = url;
    a.download = name;

    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

    window.URL.revokeObjectURL(url);
  }

  return { saveDocument ,documentsType }
}
