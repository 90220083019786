import { useContext, useEffect, useState } from 'react';
import { HttpContext } from "../HttpContext";
import { actions } from './reducer/actions';

export function useUlService(subjectId, type) {
  const { dispatch, httpClient } = useContext(HttpContext);

  const [documentTypes, setDocumentTypes] = useState([]);
  const [positions, setPositions] = useState([]);
  const [ulSubject, setUlSubject] = useState({});

  useEffect(() => {
    if (!isNaN(parseInt(subjectId)) && type === "ul") {
      getUlSubject(subjectId).then(data => setUlSubject(data))
    }

    getUlDocumentTypes().then(data => setDocumentTypes(data))
    getUlDocumentPositions().then(data => setPositions(data))
  }, [subjectId, type])

  const getUlSubjects = async () => {
    const result = await httpClient.get("/ulsubject/all");
    dispatch({
      type: actions.ulSubjects,
      payload: result.data.Data
    });
    return result.data.Data;
  }


  const getUlSubject = async (id) => {
    return httpClient.get(`/ulsubject/${id}`).then(promise => promise.data)
  }

  const getUlSubjectsPage = async (page, pageSize) => {
    return httpClient.get(`/ulsubject/all?page=${page}&pageSize=${pageSize}`).then(promise => promise.data)
  }

  const updateUlSubject = async (body) => {
    return httpClient.put("/ulsubject", body).then(promise => promise.data)
  }

  const createUlSubject = async (body) => {
    return httpClient.post("/ulsubject", body).then(promise => promise.data)
  }

  const getUlAgent = async (id) => {
    return httpClient.get(`/ulsubject/agent/${id}`).then(promise => promise.data)
  }

  const updateUlAgent = async (body) => {
    return await httpClient.put("/ulsubject/agent", body).then(promise => promise.data)
  }

  const createUlAgent = async (body) => {
    return await httpClient.post("/ulsubject/agent", body).then(promise => promise.data)
  }

  const deleteUlAgent = async (id) => {
    return await httpClient.delete(`/ulsubject/agent/${id}`).then(promise => promise)
  }

  const createUlDocument = async (body) => {
    return httpClient.post("/ulsubject/agent/document", body).then(promise => promise.data)
  }

  const deleteUlDocument = async (body) => {
    return httpClient.delete("/ulsubject/agent/document", body)
  }

  const createUlSignature = async (body) => {
    return httpClient.post("/ulsubject/agent/signature", body).then(promise => promise.data)
  }

  const assignMainUl = async (id) => {
    return httpClient.put(`/ulsubject/agent/is-main/${id}`).then(promise => promise.data)
  }

  const getUlDocumentTypes = async () => {
    return httpClient.get("/ulsubject/agent/document/types").then(promise => promise.data)
  }

  const getUlDocumentPositions = async () => {
    return httpClient.get("/ulsubject/agent/positions").then(promise => promise.data)
  }

  return {
    getUlSubjects,
    getUlSubject,
    getUlSubjectsPage,
    updateUlSubject,
    createUlSubject,
    getUlAgent,
    updateUlAgent,
    createUlAgent,
    deleteUlAgent,
    createUlDocument,
    deleteUlDocument,
    createUlSignature,
    assignMainUl,
    positions,
    documentTypes,
    ulSubject,
    setUlSubject
  }
}