import React from "react";

import Input from "../Input/Input";

import styles from "./modal.module.css";

export default function ModalNav({ children }) {
    return (
        <React.Fragment>
            <div className={styles.tabs}>
                {children}
            </div>
        </React.Fragment>
    )
}