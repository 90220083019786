import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import TableContainer from "../../../Components/Table/TableContainer";
import TableHeader from "../../../Components/Table/TableHeader";
import TableCell from "../../../Components/Table/TableCell";
import TableSkeleton from '../../../Components/Table/TableSkeleton';
import { useRequestService } from "../../../Services/Request/RequestService";
import Badge from "../../../Components/Badge/Badge";
import { Pagination, Snackbar } from '@mui/material';
import TableFooter from '../../../Components/Table/TableFooter';
import InnerCell from '../../../Components/Table/InnerCell';

import styles from "./request.list.module.css";
import Check from '../../../Components/Check/Check';
import Button from '../../../Components/Button/Button';
import ModalCenter from '../../../Components/ModalCenter/ModalCenter';

export default function RequestList() {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const { getRequests, requestStatuses, deleteRequests } = useRequestService();
  const [requests, setRequests] = useState([]);
  const [deleteArrayRequests, setDeleteArrayRequests] = useState([]);
  const [isAllRequestDelete, setIsAllRequestsDelete] = useState(false);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('')

  const toggleIdInArray = (id) => {
    setDeleteArrayRequests(prevState => {
      if (prevState.includes(id)) {
        return prevState.filter(item => item !== id);
      } else {
        return [...prevState, id];
      }
    });
  };

  useEffect(() => {
    setDeleteArrayRequests(() => {
      return isAllRequestDelete ? requests?.Items?.map((item) => item.Id) || [] : [];
    });
  }, [isAllRequestDelete])

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const handleChange = (e, number) => {
    setPage(number);
  };

  useEffect(() => {
    getRequests(page).then(result => setRequests(result))
  }, [page])

  return (
    <div className={styles.container}>
      <div className={styles.delete}>
        <Button
          type='error'
          isDisabled={deleteArrayRequests?.length === 0}
          text={deleteArrayRequests?.length === 0 ? 'Удалить заявки' : `Удалить заявки (${deleteArrayRequests?.length})`}
          onClick={() => setIsOpenDeleteModal(!isOpenDeleteModal)}
        />
      </div>
      <TableContainer>
        <TableHeader>
          <TableCell th="Тип заявления/ID/Участники" />
          <TableCell th="Дата создания" />
          <TableCell th="Номер КУВД/РР" />
          <TableCell th="Описание" />
          <TableCell th="Статус" />
          <TableCell th="Обновилась" />
          <TableCell th={<Check onClick={() => setIsAllRequestsDelete(!isAllRequestDelete)}/>} />
        </TableHeader>

        <tbody className={styles.tbody}>
          {requests?.Items?.map((x) =>
            <tr
              onClick={() => {
                if (x?.StatusId === 2) {
                  navigate(`/request/edit/${x?.Id}/property-registration`);
                } else if (x?.StatusId === 5) {
                  navigate(`/request/rosreestr/${x?.Id}`);
                } else {
                  navigate(`/request/edit/${x?.Id}`);
                }
              }}
              className={styles.tr}
              key={x.Id}
            >
              <TableCell title={x.RegActionName} subtitle={`#${x.RequestNumber}`} />
              <TableCell title={x.DateCreate.split(" ")[0]} subtitle={x.DateCreate.split(" ")[1]}/>
              <InnerCell>
                <div className={styles.kuvd_wrapper}>
                  <span className={styles.kuvd}>{x.Kuvd}</span>
                  <span className={styles.kuvd_subtitle}>{x.RegistryNumber}</span>
                </div>
              </InnerCell>
              <TableCell subtitle={x?.Description} />
              <TableCell badge={<Badge type={requestStatuses.find(t => t.code == x.StatusId)?.style} text={requestStatuses.find(t => t.code == x.StatusId)?.name}/>} />
              <TableCell subtitle={x?.DateUpdate}/>
              <TableCell
                th={
                  <Check selected={deleteArrayRequests?.includes(x?.Id)} onClick={(e) => {
                    e.stopPropagation()
                    toggleIdInArray(x?.Id)
                  }}
                  />
                }
              />
            </tr>
          )}
          {requests?.Items?.length === 0 &&
            <TableSkeleton col={10} row={5} />
          }
        </tbody>
        <tfoot className={styles.tfoot}>
        </tfoot>
      </TableContainer>
      <TableFooter>
        <Pagination
          count={Math.ceil(requests?.TotalPages)}
          page={page}
          onChange={handleChange}
          variant="outlined"
          color="secondary"
        />
      </TableFooter>
      <ModalCenter isOpen={isOpenDeleteModal} setIsOpen={setIsOpenDeleteModal}>
        <div className={styles.deleteModal}>
          <h4>{ `Удалить заявки (${deleteArrayRequests?.length})?` }</h4>
          <div className={styles.deleteButtons}>
            <Button
              type="error"
              text='Удалить'
              onClick={() =>
                deleteRequests(deleteArrayRequests).then(() =>
                  getRequests(page)
                    .then(result => setRequests(result))
                    .then(() => {
                      setSnackbarMessage(`Заявки успешно удалены`);
                      setOpen(true);
                      setIsOpenDeleteModal(false);
                      setDeleteArrayRequests([]);
                    })
                ).catch(() => {
                  setSnackbarMessage('Произошла ошибка');
                  setOpen(true);
                  setIsOpenDeleteModal(false);
                  setDeleteArrayRequests([]);
                })
              }
            />
            <Button
              type="gray"
              onClick={() => setIsOpenDeleteModal(false)}
              text="Отмена"
            />
          </div>
        </div>
      </ModalCenter>
      <Snackbar
        open={open}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        onClose={handleClose}
        autoHideDuration={3000}
        message={snackbarMessage}
      />
    </div >
  )
}