import { useContext, useRef } from 'react';
import { HttpContext } from "../HttpContext";

export function useAddressService() {
  const { httpClient } = useContext(HttpContext);
  const abortControllerRef = useRef(null);

  const getAddress = async (value) => {
    value = value.trim()
    if (value.length > 1) {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }

      const abortController = new AbortController();
      abortControllerRef.current = abortController;

      try {
        const response = await httpClient.get(`/address?text=${value}`, {
          signal: abortController.signal
        });
        return response.data;
      } catch (error) {
      }
    }
  }

  return {getAddress}
}
