import { useContext } from 'react';
import { HttpContext } from '../../HttpContext';

export function useGrazhdotekaService() {
  const { httpClient } = useContext(HttpContext);

  const getAuthLink = async () => {
    return httpClient.get(`/Grazhdoteka/getAuthLink`).then(promise => promise.data)
  }

  return {
    getAuthLink
  }
}
