import React, { useEffect, useState } from 'react';

import CardContainer from '../../Components/Card/CardContainer';
import CardHeader from '../../Components/Card/CardHeader';
import CardBody from '../../Components/Card/CardBody';
import { useBalanceService } from '../../Services/Balance/BalanceService';
import Button from '../../Components/Button/Button';
import TableHeader from '../../Components/Table/TableHeader';
import TableCell from '../../Components/Table/TableCell';
import TableFooter from '../../Components/Table/TableFooter';
import TableContainer from '../../Components/Table/TableContainer';
import { Pagination, Snackbar } from '@mui/material';
import ModalCenter from '../../Components/ModalCenter/ModalCenter';
import { useCustomerService } from '../../Services/Cusomer/Customer';
import Select from '../../Components/Select/Select';
import Input from '../../Components/Input/Input';

import styles from './balance.module.css';

const Balance = () => {
  const [allBalance, setAllBalance] = useState();
  const [transactions, setTransactions] = useState();
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [customers, setCustomers] = useState();
  const [description, setDescription] = useState('Для отправки пакетов');
  const [value, setValue] = useState(null);
  const [open, setOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('')
  const [selectedCustomer, setSelectedCustomer] = useState(null);

  const isSuperadmin = localStorage.getItem("isSuperadmin") === "true";

  const {getAllBalance, getTransactions, addBalance} = useBalanceService();
  const {getCustomerList} = useCustomerService();

  const handleChange = (e, number) => {
    setPage(number);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);

    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();

    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    return `${day}.${month}.${year} ${hours}:${minutes}`;
  };


  useEffect(() => {
    getAllBalance().then((x) => setAllBalance(x))
  }, [])

  useEffect(() => {
    getTransactions(page).then((x) => {
      setTransactions(x.Items);
      setTotal(x.TotalPages);
    })
  }, [page])

  useEffect(() => {
    if (isOpenModal) {
      getCustomerList().then((customers) => {
        setCustomers(customers.Value);
      })
    }
  }, [isOpenModal])

  return (
    <div className={styles.container}>
      <h1>Все операции</h1>
      {isSuperadmin &&
        <div>
          {allBalance?.length > 0 &&
            <CardContainer>
              <CardHeader title='Баланс пакетов' button={<Button onClick={() => setIsOpenModal(!isOpenModal)} text='Пополнить'/>}/>
              <CardBody>
                <div className={styles.wrapper}>
                  {allBalance?.map((item) =>
                    <div className={styles.card}>
                      <span className={styles.title}>{item.CustomerName}</span>
                      <span>{ `Баланс ${item.Value} пакетов` }</span>
                    </div>
                  )}
                </div>
              </CardBody>
            </CardContainer>
          }
        </div>
      }
      <div>
        {transactions?.length > 0 &&
          <TableContainer>
            <TableHeader>
              <TableCell th="Контрагент"/>
              <TableCell th="Описание"/>
              <TableCell th="Тип"/>
              <TableCell th="Количество"/>
              <TableCell th="Дата регистрации заявки"/>
            </TableHeader>
            <tbody>
            {transactions?.map((transaction) =>
              <tr key={transaction?.Id}>
                <TableCell th={transaction?.CustomerName}/>
                <TableCell th={transaction?.Description}/>
                <TableCell th={transaction?.TypeName}/>
                <TableCell th={transaction?.Value}/>
                <TableCell th={formatDate(transaction?.RegistrationDate)}/>
              </tr>
            )}
            </tbody>
          </TableContainer>
        }
        <TableFooter>
          <Pagination
            count={total}
            page={page}
            onChange={handleChange}
            variant="outlined"
            color="secondary"
          />
        </TableFooter>
      </div>
      <ModalCenter fullHeight isOpen={isOpenModal} setIsOpen={setIsOpenModal} closeButton>
        <h2>Пополнение баланса</h2>
        <div className={styles.balance}>
          {customers?.length > 0 &&
            <Select
              value={selectedCustomer}
              label="Контрагент"
              placeholder="Выберите контрагента"
              onChange={(value) => setSelectedCustomer(value)}
            >
              {customers?.map((item, index) => (
                <option key={index} value={item.code}>
                  {item.name}
                </option>
              ))}
            </Select>
          }
          <Input label="Описание" value={description} onChange={(value) => setDescription(value)} placeholder='Для отправки пакетов'/>
          <Input mask={Number} value={value} onChange={(value) => setValue(value)} label="Количество"/>
        </div>
        <div className={styles.buttons}>
          <Button
            isDisabled={!selectedCustomer || !value || !description}
            text='Пополнить'
            onClick={() => {
              addBalance({
                CustomerId: selectedCustomer,
                Value: value,
                Description: description
              }).then(() => {
                setSnackbarMessage(`Баланс пополнен на ${value} пакетов`)
                setOpen(true);
                setIsOpenModal(false);
                getAllBalance().then((x) => setAllBalance(x))
                getTransactions(page).then((x) => {
                  setTransactions(x.Items);
                  setTotal(x.TotalPages);
                })
              }).catch(() => {
                setSnackbarMessage('Произошла ошибка')
                setOpen(true);
                setIsOpenModal(false);
              })
            }}
          />
        </div>
      </ModalCenter>

      <Snackbar
        open={open}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        onClose={handleClose}
        autoHideDuration={5000}
        message={snackbarMessage}
      />
    </div>
  );
};

export default Balance;
