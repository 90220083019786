import { useContext } from 'react';
import { HttpContext } from '../../HttpContext';

export function useBalanceService() {
  const { httpClient } = useContext(HttpContext);

  const getBalance = async () => {
    return httpClient.get("/balance").then(promise => promise.data)
  }

  const getBalanceInfo = async () => {
    return httpClient.get("/balance/info").then(promise => promise.data)
  }

  const getTransactions = async (page) => {
    return httpClient.get(`/balance/all-balance-transaction?number=${page}&size=30`).then(promise => promise.data)
  }

  const addBalance = async (body) => {
    return httpClient.post("/balance/add-balance", body).then(promise => promise.data)
  }

  const getAllBalance = async () => {
    return httpClient.get("/balance/all-balance").then(promise => promise.data)
  }

  return {getBalance, getBalanceInfo, getTransactions, addBalance, getAllBalance}
}
