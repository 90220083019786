import React from 'react';
import style from './tooltipText.module.css';

const TooltipIcon = ({ icon, tooltipText }) => {
  return (
    <div className={style.container}>
      <img className={style.icon} src={icon}/>
      <div className={ style.fullText }>{tooltipText && tooltipText}</div>
    </div>
  );
};

export default TooltipIcon;
