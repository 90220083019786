import React from 'react';
import cn from 'classnames';

import styles from './homes.card.module.css'

const HomesCard = ({title, total, amount, type = 'default', isMain, onClick}) => {
  return (
    <div
      onClick={() => {
        onClick && amount !== 0 && onClick()
      }}
      className={cn(styles.container, {
        [styles.disabled]: !isMain && amount === 0
      })}
    >
      {isMain ?
        <div className={styles.main}>
          <span className={styles.main_label}>
            Объекты с УДС:
            <span className={styles.main_span}>{ amount }</span>
          </span>
          <span className={styles.main_label}>
            Объекты застройщика:
            <span className={styles.main_span}>
              {isNaN(total - amount) ? 0 : total - amount}
            </span>
          </span>
          <span className={styles.main_label}>
            Всего объектов:
            <span className={styles.main_span}>{total}</span>
          </span>
        </div>
        :
        <div className={styles.wrapper}>
          <span className={styles.title}>
            {title}
          </span>
          <span className={styles.amount}>{amount ? amount : 0} / {total}</span>
        </div>
      }
      <div className={styles.wrapper}>
        <div className={cn(styles.bar, {[styles.bar_main]: isMain})}>
          <div
            className={cn(styles.progress, styles[type])}
            style={{ width: `${amount ? ((amount / total) * 100) : 0}%` }}
          />
        </div>
        { isMain ? 100 : amount && total ?((amount / total) * 100).toFixed(0) : 0}%
      </div>
    </div>
  );
};

export default HomesCard;
